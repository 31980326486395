import React from 'react'

export const scrollUp = (ComposedComponent) =>
  class extends React.PureComponent {
    componentDidMount() {
      window.scrollTo(0, 0)
    }

    componentDidUpdate() {
      window.scrollTo(0, 0)
    }

    render() {
      return <ComposedComponent {...this.props} />
    }
  }
