/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { UIBlock, UILayout } from 'bora-material-ui'

import {
  RoutesTabs,
  RouteInfo,
  TicketsInfo,
  ReservationOptions,
  ReservationPayment,
  ReservationHeader,
} from '../../components'

import UIElements from '../../../../consts/UIElements'

import { selectors, actions } from '../../provider'

const ROUTES_TABS = [
  { translateKey: 'initialRoute', key: 'initial' },
  { translateKey: 'returnRoute', key: 'return' },
]

class Reservation extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'initial',
    }

    this.tabClickHandler = this.tabClickHandler.bind(this)
  }

  tabClickHandler(activeTab) {
    this.setState({ activeTab })
  }

  render() {
    const {
      location: { state = { reservation: {} } },
    } = this.props
    const { reservation = {} } = state

    return (
      <UILayout
        column
        j-flex-start
        padding="0"
        margin={`${UIElements.HEADER ? '-97px' : '40px'} 0 0`}
        width={UIElements.CONTENT_WIDTH}
        xs={{ margin: '0', width: 'auto' }}
      >
        <UIBlock bgColor="#fafafa">
          <ReservationHeader reservation={reservation} />
          <UILayout column bgColor="#f0f0f1" align="right">
            <UILayout
              height="142px"
              bgColor="#627c88"
              style={{ backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}
            >
              {' '}
            </UILayout>
            <UILayout column padding="0 60px 18px 30px" margin="-24px 0 0 0" xs={{ padding: '0 30px 18px 30px' }}>
              <RoutesTabs tabs={ROUTES_TABS} activeTab={this.state.activeTab} onClick={this.tabClickHandler} />
              <RouteInfo />
              <TicketsInfo />
              <ReservationOptions />
              <ReservationPayment />
            </UILayout>
          </UILayout>
        </UIBlock>
      </UILayout>
    )
  }
}

export default connect(selectors, (dispatch) => bindActionCreators(actions, dispatch))(Reservation)
