import { createSelector } from 'reselect'
import { filterReservations, getUserFiltersByType } from '../../../services/user/selectors'
import * as R from 'ramda'

function getCompanyId(state) {
  return state.companyMembers.companyId
}

function getCompanyName(state) {
  return state.companyMembers.companyName
}

function getCompanyMembers(state) {
  return state.companyMembers.companyMembers
}

function getSelectedCompanyMember(state) {
  return state.companyMembers.selectedCompanyMember
}

function getCompanyMemberReservations(state) {
  return R.pathOr([], ['companyMembers', 'reservations', 'items'])(state)
}

function getCompanyMemberReservationsLength(state) {
  return R.pathOr(0, ['companyMembers', 'reservations', 'items', 'length'])(state)
}

const getFilteredCompanyMemberReservations = createSelector(
  [getUserFiltersByType('usersTickets'), getCompanyMemberReservations],
  filterReservations
)

function getCompanyMemberReservationsLoading(state) {
  return state.companyMembers.reservations.loading
}

export {
  getCompanyId,
  getCompanyName,
  getCompanyMembers,
  getSelectedCompanyMember,
  getCompanyMemberReservations,
  getCompanyMemberReservationsLength,
  getFilteredCompanyMemberReservations,
  getCompanyMemberReservationsLoading,
}
