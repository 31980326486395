import { createAction } from 'redux-actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getCompanyId, getSelectedCompanyMember } from '../CompanyMembers'
import { activateCompanyMember as activateCompanyMemberService } from '../../../services/user/api/company'
import { goToPage } from '../../../actions'

const activateCompanyMember = createAction('ACTIVATE_COMPANY_MEMBER:ACTIVATE_COMPANY_MEMBER')
const activateCompanyMemberSuccess = createAction('ACTIVATE_COMPANY_MEMBER:ACTIVATE_COMPANY_MEMBER_SUCCESS')

function* activateCompanyMemberHandler() {
  const companyId = yield select(getCompanyId)
  const companyMember = yield select(getSelectedCompanyMember)

  try {
    yield call(activateCompanyMemberService, companyId, companyMember.customerId)
    yield put(activateCompanyMemberSuccess())
    yield put(goToPage('/profile/users'))
  } catch (error) {
    console.error('Cannot activate company member', error)
  }
}

function* watchActivateCompanyMember() {
  yield takeEvery(activateCompanyMember, activateCompanyMemberHandler)
}

export { activateCompanyMember, activateCompanyMemberSuccess, watchActivateCompanyMember }
