import Loadable from 'react-loadable'

const CompanySignup = Loadable({
  loader: () => import('./containers/CompanySignup'),
  loading: () => null,
})

const Signup = Loadable({
  loader: () => import('./containers/Signup'),
  loading: () => null,
})

const Login = Loadable({
  loader: () => import('./containers/Login'),
  loading: () => null,
})

const Join = Loadable({
  loader: () => import('./containers/Join'),
  loading: () => null,
})

const Recovery = Loadable({
  loader: () => import('./containers/Recovery'),
  loading: () => null,
})

export { CompanySignup, Signup, Login, Join, Recovery }
