import React from 'react'
import { UIFormattedNumber } from 'bora-material-ui'
import s from './TicketPromotionLine.module.scss'

export const TicketPromotionLine = ({ passenger }) => {
  return (
    <div className={s.wrap}>
      <span>
        {passenger.priceCategoryTranslation}
        <span className={s.price}>
          <UIFormattedNumber style="currency" currency="eur" value={passenger.price} />
        </span>
      </span>
    </div>
  )
}
