import React from 'react'
import PropTypes from 'prop-types'

import cn from 'classnames'
import style from './MessageBox.module.scss'

import { prop, assoc } from 'ramda'
import { UILayout } from 'bora-material-ui'
import { scrollUp } from '../scrollUp'
import { translate } from '../Translator'
import messages from '../../../consts/messages'

const baseStyles = {
  borderRadius: '3px',
  padding: '10px 10px 0px 10px',
}

const xsStyles = {
  container: { overflow: 'hidden', width: '100%' },
}

const styles = {
  info: {
    ...baseStyles,
    backgroundColor: 'rgba(209, 255, 209, 1)',
    border: 'solid 1px rgba(0, 181, 172, 1)',
  },
  error: {
    ...baseStyles,
    backgroundColor:
      window.brandProps.muiTheme && window.brandProps.muiTheme.palette && window.brandProps.muiTheme.palette.error
        ? window.brandProps.muiTheme.palette.error
        : '#b42a2c',
    color: 'white',
    border: 'solid 1px #b42a2a',
  },
}

export const MessageBox = (props) => {
  const { text, type } = props
  const marginOverwrite = assoc('margin', prop('margin', props), {})
  return (
    <UILayout
      column
      flex-center
      margin="0 0 20px 0"
      width="auto"
      xs={xsStyles.container}
      style={styles[type] || styles.info}
      {...marginOverwrite}
      display-if={text}
    >
      <div data-test="message-box-info-text" className={cn(style.infoText)}>
        {text.includes('424') ? translate(messages.signUpUserExists) : text}
      </div>
    </UILayout>
  )
}

MessageBox.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
}

MessageBox.defaultProps = {
  type: 'info',
}

export default scrollUp(MessageBox)
