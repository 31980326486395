import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { UICheckbox } from 'bora-material-ui'

import messages from '../../../../consts/messages'
import { translate } from '../../../Common/Translator'

const Around = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 5px;
  margin-top: 1rem;
  opacity: 0.4;
  font-weight: normal;
  font-family: Roboto;

  ${(props) =>
    props.enabled &&
    `
    opacity: 1
  `}
`

const InfoContainer = styled.div`
  flex: 11;
`

const checkBoxStyle = {
  flex: 1,
  padding: '8px',
  maxWidth: '45px',
}

export const LocalDiscountCheckBox = ({
  enabled = false,
  handleCheck,
  initialValue,
  isResidentInCurrentReservation = false,
  editMode = false,
  description = translate(messages.residentDiscountPromotionText),
  // idCode,
}) => {
  const [checked, check] = useState(initialValue)
  const initiallyDisabledForEditMode = editMode && isResidentInCurrentReservation

  useEffect(() => {
    if (enabled && !initiallyDisabledForEditMode) {
      handleCheck(checked)
    }
  }, [checked, check, enabled, initiallyDisabledForEditMode])

  useEffect(() => {
    if (!enabled && checked && !editMode) {
      check(false)
    }
  }, [enabled, check, checked, editMode])

  useEffect(() => {
    check(initialValue)
  }, [initialValue, check])

  return (
    <Around enabled={enabled && !initiallyDisabledForEditMode}>
      <UICheckbox
        style={checkBoxStyle}
        checked={checked}
        onCheck={() => check(!checked)}
        disabled={!enabled || initiallyDisabledForEditMode}
      />
      <InfoContainer>{description}</InfoContainer>
    </Around>
  )
}
