import { call, put, select, takeEvery } from 'redux-saga/effects'
import { createAction } from 'redux-actions'
import { removeUserFromCompany } from '../../../services/user/api/company'
import { getManagersSelectedCompany } from '../../../services/user/selectors'
import { goToPage } from '../../../actions'
import { getSelectedCompanyMember } from '../CompanyMembers'

export const removeCompanyMember = createAction('MODULE:COMPANY_MEMBERS:REMOVE_COMPANY_MEMBER')
export const removeCompanyMemberSuccess = createAction('MODULE:COMPANY_MEMBERS:REMOVE_COMPANY_MEMBER_SUCCESS')
export const removeCompanyMemberFail = createAction('MODULE:COMPANY_MEMBERS:REMOVE_COMPANY_MEMBER_FAIL')

function* removeCompanyMemberHandler() {
  const selectedCompany = yield select(getManagersSelectedCompany)
  const { customerId } = yield select(getSelectedCompanyMember)
  const { companyId } = selectedCompany

  try {
    yield call(removeUserFromCompany, { companyId, customerId })
    yield put(removeCompanyMemberSuccess())
    yield put(goToPage('/profile/users'))
  } catch (error) {
    yield put(removeCompanyMemberFail(error))
  }
}

export function* watchRemoveCompanyMember() {
  yield takeEvery(removeCompanyMember, removeCompanyMemberHandler)
}
