const setInitialValues = ({ items = [], vehicles = [] }, type, sailRefId) => {
  const trailer = items.find((item) => item.priceCategorySubType === type && sailRefId in item.sailRefIdToPricePerSail)
  if (!trailer) {
    return null
  }
  const vehicle = vehicles.find((vehicle) => vehicle.seqN === trailer.ownerSeqNs[0])
  if (!vehicle) {
    return null
  }

  const { companyRegistrationNumber: companyRN, registryCategory } = vehicle
  const vehicleHasRegistryCategory = Boolean(registryCategory)
  const companyRegistrationNumber = companyRN ? { value: companyRN, status: 'verified' } : null
  return {
    vehicleTicket: {
      priceCategory: trailer.priceCategory,
      code: trailer.priceCategory,
      status: 'verified',
      price: trailer.price,
      coefficients: trailer.coefficients,
      descr: trailer.priceCategoryTranslation,
      category: vehicle.registryCategory,
      heightInMm: vehicle.heightInCm * 10,
      lengthInMm: vehicle.lengthInCm * 10,
      licensePlate: vehicle.licencePlate,
      weightInKg: vehicle.weightInKg,
      widthInMm: vehicle.widthInCm * 10,
    },
    selectedSavedVehicle: null,
    plateNumberVehicle: {
      priceCategory: trailer.priceCategory,
      code: trailer.priceCategory,
      status: 'verified',
      price: trailer.price,
      coefficients: trailer.coefficients,
      descr: trailer.priceCategoryTranslation,
      category: vehicle.registryCategory,
      heightInMm: vehicle.heightInCm * 10,
      lengthInMm: vehicle.lengthInCm * 10,
      licensePlate: vehicle.licencePlate,
      weightInKg: vehicle.weightInKg,
      widthInMm: vehicle.widthInCm * 10,
    },
    handicapped: vehicle.handicapped,
    vehicleSimplified: vehicle.licencePlate,
    companyRegistrationNumber,
    plateNumber: vehicle.licencePlate,
    vehicleHasRegistryCategory,
  }
}

export { setInitialValues as default, setInitialValues }
