import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'

import messages from '../../../../../../../consts/messages'

const EditModeButton = ({ display, onEdit, right, position, additionalStyles, top, lock }, { muiTheme }) => (
  <UILayout
    display-if={display}
    position={position}
    cursor="pointer"
    center
    width="auto"
    right={right}
    top={top}
    onClick={onEdit}
    {...additionalStyles}
    xs={{ position: 'static', 'j-flex-end': true }}
  >
    {lock ? (
      <UIIcon width="14px" height="18px" type="iconLock" fill="#0eb1bc" margin="0 5px 0 0" />
    ) : (
      <Fragment>
        <UIIcon
          width="14px"
          height="14px"
          type="iconPencil"
          fill="#0eb1bc"
          margin="0 5px 0 0"
          {...muiTheme.ids.iconEdit}
        />
        <UIText
          textTransform="uppercase"
          font={muiTheme.secondFontFamilyDem}
          color="#79909b"
          size="18px"
          translate={messages.edit}
        />
      </Fragment>
    )}
  </UILayout>
)

export default EditModeButton

EditModeButton.contextTypes = {
  muiTheme: PropTypes.object,
}

EditModeButton.propTypes = {
  display: PropTypes.any,
  onEdit: PropTypes.any,
  lock: PropTypes.bool,
  right: PropTypes.any,
  position: PropTypes.string,
  additionalStyles: PropTypes.any,
  top: PropTypes.any,
}

EditModeButton.defaultProps = {
  right: '0px',
  position: 'absolute',
  additionalStyles: {},
  top: '0',
  lock: false,
}
