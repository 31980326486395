import { VEHICLE_TYPE_TRAILER } from '../../../../consts/reservation'
import { selectors } from '../../provider'
import { getTrailerInputType, setTrailerInputType } from '../../../../services/user-selections'
import createFormContainer from './createFormContainer'

const trailer = {
  iconType: 'trailer',
  vehicleType: VEHICLE_TYPE_TRAILER,
  areVehiclesActive: selectors.areTrailersActive,
  getInitialFormValues: (state) => {
    const trailersCategories = selectors.getTrailerCategoriesListWithPrices(state)
    return { selectedTrailer: trailersCategories[0] }
  },
  getInputType: getTrailerInputType,
  setInputType: setTrailerInputType,
}

export default createFormContainer(trailer)
