import React from 'react'
import { ActionButton } from './ActionButton'

function CrossFaintButton(props) {
  return (
    <ActionButton
      backgroundColor="transparent"
      border="1px solid #d0021b"
      iconFill="red"
      iconType="iconClose"
      {...props}
    />
  )
}

export { CrossFaintButton }
