import { connect } from 'react-redux'
import { getManagersCompaniesSelector, getManagersSelectedCompany } from '../../../services/user/selectors'
import { selectUsersCompany } from '../../../services/user/actions'
import { CompanyMembersView } from './CompanyMembersView'
import {
  activateCompanyMember,
  deactivateCompanyMember,
  removeCompanyMember,
  editCompanyMember,
  fetchCompanyMembers,
  inviteCompanyMember,
  resendInviteCompanyMember,
  cancelInviteCompanyMember,
  viewCompanyMemberTickets,
} from './companyMembersSaga'
import { getCompanyId, getCompanyMembers } from './companyMembersSelectors'

function mapStateToProps(state) {
  return {
    companies: getManagersCompaniesSelector(state),
    companyMembers: getCompanyMembers(state),
    companyId: getCompanyId(state),
    userCompany: getManagersSelectedCompany(state),
  }
}

const mapDispatchToProps = {
  activateCompanyMember,
  deactivateCompanyMember,
  removeCompanyMember,
  editCompanyMember,
  fetchCompanyMembers,
  inviteCompanyMember,
  resendInviteCompanyMember,
  cancelInviteCompanyMember,
  viewCompanyMemberTickets,
  onCompanySelect: selectUsersCompany,
}

const CompanyMembersContainer = connect(mapStateToProps, mapDispatchToProps)(CompanyMembersView)

export { CompanyMembersContainer }
