import React from 'react'

import ScrollToChildContainer from '../../../Common/ScrollToChildContainer'
import ModalErrorText from './ModalErrorText'

const ModalScrolledErrorText = (props) => (
  <ScrollToChildContainer>
    <ModalErrorText {...props} />
  </ScrollToChildContainer>
)

export default ModalScrolledErrorText
