import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, isValid } from 'redux-form'
import { bindActionCreators } from 'redux'

import { UIText, UIButton, UILayout, UIIcon } from 'bora-material-ui'

import { InputField } from '../../../formComponents'
import { goToPage } from '../../../actions'

import { requiredNumber } from '../../../utils/validate'
import messages from '../../../consts/messages'
import UIElements from '../../../consts/UIElements'

const fieldStyles = {
  errorStyles: { margin: '6px 0 0 0' },
  labelStyles: {
    color: 'rgba(255, 255, 255, 0.62)',
    padding: '0 0 6px 0',
  },
}

class CheckIn extends React.Component {
  handleCheckIn = (values) => {
    const { goToPage } = this.props
    goToPage(`/reservation/${values.checkinReservationId}`)
  }

  render() {
    const { isCheckInAvailable, handleSubmit, submitting } = this.props

    return (
      <UILayout
        column
        j-flex-start
        padding="0"
        margin={`${UIElements.HEADER ? '-97px' : '40px'} 0 0`}
        width={UIElements.CONTENT_WIDTH}
        xs={{ margin: '0', width: 'auto' }}
      >
        <UILayout
          column
          width={UIElements.CONTENT_WIDTH}
          maxWidth="100%"
          bgColor="#4a90e2"
          boxShadow="none"
          padding="21px"
          align="left"
          borderRadius="2px"
          margin="0 0 20px 0"
        >
          <UIText align="left" size="14px" translate={messages.checkInTitleWeNeed} />
          <UIText align="left" size="34px" margin="0 0 40px 0" translate={messages.checkInTitleInformation} />

          <UILayout
            column
            width="100%"
            boxShadow="none"
            align="left"
            borderRadius="0px"
            size="18px"
            margin="0 0 31px 0"
          >
            <InputField required type="text" name="checkinName" {...fieldStyles} />
          </UILayout>

          <UILayout column boxShadow="none" align="left" borderRadius="0px" size="18px" margin="0 0 31px 0">
            <InputField required type="text" name="checkinReservationId" validate={[requiredNumber]} {...fieldStyles} />
          </UILayout>

          <UILayout column j-flex-start padding="60px 30px 30px 0px">
            <UIButton
              background={UIElements.BUTTONS_COLOR}
              click={handleSubmit(this.handleCheckIn)}
              disabled={submitting || !isCheckInAvailable}
              xs={{ width: '100%' }}
            >
              <UILayout column j-flex-center margin="0 27px" lineHeight="30px" height="100%" style={{ flexBasis: '0' }}>
                <UIText align="left" size="18px" opacity="0.5" translate={messages.checkInAlrightLabel} />
                <UIText align="left" size="24px">
                  <UILayout row center>
                    <UIText align="left" size="24px" lineHeight="1" translate={messages.checkInGetTicketsLabel} />
                    &nbsp;
                    <UIIcon fill="white" type="selectTicketArrow" margin="0 0 0 20px" />
                  </UILayout>
                </UIText>
              </UILayout>
            </UIButton>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

CheckIn.propTypes = {
  isCheckInAvailable: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  goToPage: PropTypes.func.isRequired,
}

const isCheckInFormValid = isValid('checkInForm')

export default connect(
  (state) => ({
    isCheckInAvailable: isCheckInFormValid(state),
  }),
  (dispatch) =>
    bindActionCreators(
      {
        goToPage,
      },
      dispatch
    )
)(reduxForm({ form: 'checkInForm' })(CheckIn))
