let instance = null

class AccessToHistory {
  constructor() {
    if (!instance) {
      instance = this // eslint-disable-line
    }

    return instance
  }

  updateHistoryInstance(history) {
    this.history = history
  }
}

export default AccessToHistory
