/* eslint react/jsx-closing-tag-location: off */
import { email, phoneCode } from '../../../utils/validate'
import { getPhoneCode } from '../../../services/utils'

export const contactFields = [
  {
    name: 'firstName',
    value: '',
    type: 'text',
    icon: 'iconPerson',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'lastName',
    value: '',
    type: 'text',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0 0 0 20px' },
    className: 'owner-last-name',
  },
  {
    name: 'phoneCode',
    value: getPhoneCode(window.brandProps.theme),
    type: 'number',
    icon: 'iconPhone',
    validate: [phoneCode],
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'phoneNumber',
    value: '',
    type: 'number',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0 0 0 20px' },
  },
  {
    name: 'email',
    value: '',
    type: 'email',
    icon: 'iconEmail',
    validate: [email],
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'legalName',
    value: '',
    type: 'text',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'registrationNumber',
    value: '',
    type: 'text',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'addressLine',
    value: '',
    type: 'text',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'city',
    value: '',
    type: 'text',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'country',
    value: '',
    type: 'text',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
  {
    name: 'zip',
    value: '',
    type: 'text',
    hintStyle: { paddingLeft: '10px' },
    inputStyle: { paddingLeft: '10px' },
    style: { margin: '0' },
  },
]
