import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import 'regenerator-runtime/runtime'
import 'react-dates/initialize'
import { connect } from 'react-redux'

import { UIAppBar, UIButton, UIIcon, UIImage, UILayout } from 'bora-material-ui'

import { changeLocale, getLocale, getMobileMenuStatus, toggleMobileMenu } from '../../../services/user-selections'

import '../../../styles/main.css'
import UIElements from '../../../consts/UIElements'
import NavigationBar from './NavigationBar'
import s from './AppBar.module.scss'
import { THEME } from 'bora-material-ui/themes'
import { LanguagePicker } from '../../Language/components/Selects'
import { goToPage } from '../../../actions'
import { signout } from '../../../services/auth/actions'
import { LLNavigation } from './LLNavigation'

export const { matches: isMobile } = window.matchMedia('(max-width: 600px)')

const xsStyles = {
  width100: { width: '100%' },
  logoWrap: { left: '20px', top: '6px' },
  appBarRightIcon: {
    width: '100%',
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  appBarLeftIcon: { marginTop: 0, display: 'flex', alignItems: 'center' },
  appBarTitle: { flex: '1 0 auto' },
  appBar: { style: { width: '100%', margin: '10px 0' } },
  backgroundSizeCover: { backgroundSize: 'cover' },
  appBarLogo: { padding: 0, textTransform: 'uppercase', borderRadius: '0px' },
}

const getStyles = (id, mobileMenuOpened) => ({
  appBar: {
    backgroundColor: UIElements.BACKGROUND_COLOR,
    boxShadow: '',
    padding: '0',
    maxWidth: '100%',
    ...id,
  },
  appBarOriental: {
    style: {
      display: mobileMenuOpened ? 'none' : 'flex',
      ...(window.brandProps.theme !== THEME.LIINILAEVAD && { justifyContent: 'space-between' }),
    },
  },
})

const reloadWithLang =
  (locale = 'en') =>
  () => {
    const { origin } = window.location

    if (typeof locale !== 'string') {
      return window.location.assign(origin)
    }

    const url = `${origin}/#/${locale}/`
    return window.location.assign(url)
  }

const AppBar = ({ mobileMenuOpened, locale, changeLocale }, context) => {
  const { muiTheme } = context
  const appBarStyles = getStyles(muiTheme.ids.appBar, mobileMenuOpened)

  return (
    <Fragment>
      <UILayout flex j-flex-center width="100%" bgColor={UIElements.BACKGROUND_COLOR} zIndex="1">
        <UIAppBar
          titleStyle={xsStyles.appBarTitle}
          iconStyleLeft={xsStyles.appBarLeftIcon}
          iconStyleRight={xsStyles.appBarRightIcon}
          style={appBarStyles.appBar}
          xs={xsStyles.appBar}
          showMenuIconButton={false}
          position="static"
          elevation={0}
        >
          <UILayout id="appBarOriental" align="center">
            <UILayout align="center" data-testid="upper-bar" justify="space-between" ms={appBarStyles.appBarOriental}>
              <UILayout
                display-if={UIElements.LOGO_WRAPPER}
                height="100px"
                width="auto"
                position="absolute"
                top="0px"
                left="0px"
                xs={xsStyles.logoWrap}
              >
                <UIImage src={UIElements.LOGO_WRAPPER} style={xsStyles.backgroundSizeCover} />
              </UILayout>
              <UILayout padding="0px 10px" align="center" {...muiTheme.ids.logoWrapper}>
                <UIButton
                  type="icon"
                  width="auto"
                  height="auto"
                  disableRipple
                  style={xsStyles.appBarLogo}
                  click={reloadWithLang(locale)}
                >
                  <UIIcon
                    width={UIElements.LOGO_WIDTH}
                    height={UIElements.LOGO_HEIGHT || '53px'}
                    type={`logo_${UIElements.LOGO}`}
                    fill={muiTheme.appBar.textColor}
                    {...muiTheme.ids.appLogo}
                  />
                </UIButton>
              </UILayout>
              {!isMobile && window.brandProps.theme === THEME.LIINILAEVAD && (
                <Fragment>
                  <div className={s.upperBarItem}>
                    <UIIcon type="iconPhoneLL" margin="0 10px 0 0" />
                    <span>
                      <a href="tel:+37255595550">+37255595550</a>
                    </span>
                  </div>
                  <div className={s.upperBarItem}>
                    <UIIcon type="iconMailLL" margin="0 10px 0 0" />
                    <span>
                      <a href="mailto:info@liinilaevad.ee">info@liinilaevad.ee</a>
                    </span>
                  </div>
                  <div className={s.upperBarItem}>
                    <UIIcon type="iconFB" fill="#0000a0" margin="0 10px 0 0" />
                    <span>
                      <a href="https://www.facebook.com/liinilaevad" target="_blank">
                        Liinilaevad
                      </a>
                    </span>
                  </div>
                  <LanguagePicker locale={locale} onChange={changeLocale} />
                </Fragment>
              )}
            </UILayout>
            <NavigationBar display-if={window.brandProps.theme !== THEME.LIINILAEVAD} />
          </UILayout>
        </UIAppBar>
      </UILayout>
      <LLNavigation display-if={muiTheme.menuRows === 2} />
    </Fragment>
  )
}

AppBar.contextTypes = {
  muiTheme: PropTypes.object,
}

AppBar.propTypes = {
  locale: PropTypes.string,
  mobileMenuOpened: PropTypes.bool,
  promotions: PropTypes.array.isRequired,
}

AppBar.defaultProps = {
  locale: 'en',
  mobileMenuOpened: false,
}

export default connect(
  (state) => ({
    mobileMenuOpened: getMobileMenuStatus(state),
    locale: getLocale(state),
  }),
  { changeLocale, toggleMobileMenu, goToPage, signout }
)(AppBar)
