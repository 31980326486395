import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIDropDownMenu, UIIcon } from 'bora-material-ui'

import { FlagItemTemplate } from '../../../../formComponents'
import { THEME } from 'bora-material-ui/themes'

const LANGUAGES = [
  { title: 'estonian', key: 'et' },
  { title: 'english', key: 'en' },
  { title: 'german', key: 'de' },
  { title: 'polish', key: 'pl' },
  { title: 'russian', key: 'ru' },
  { title: 'greek', key: 'el' },
  { title: 'swedish', key: 'sv' },
  { title: 'philippines', key: 'fil' },
]

const styles = {
  input: {
    width: 'auto',
    height: 'auto',
    minHeight: 'auto',
    margin: '0 10px',
    background: 'transparent',
    boxShadow: 'unset',
  },
  iconStyle: { display: 'none' },
  menuItemStyle: { padding: '0', lineHeight: 'unset' },
  menuStyle: { width: 'min-content' },
  menuItemPickedStyle: { display: 'none' },
  xs: {
    languagePicker: {
      margin: window.brandProps.theme === THEME.LIINILAEVAD ? '1rem 0' : '30px 0 0 24px',
      zIndex: '22',
    },
    icon: { width: '30px', height: '20px' },
    iconChev: {
      width: '16px',
      height: '8px',
      stroke: window.brandProps.theme === THEME.LIINILAEVAD ? '#0000A0FF' : '#ffffff',
      margin: '0 0 6px 8px',
    },
  },
}

const LanguagePicker = ({ locale, onChange }) => (
  <UILayout
    data-testid="lang-picker"
    j-flex-center
    xs={styles.xs.languagePicker}
    width={window.brandProps.theme === THEME.LIINILAEVAD ? 'auto' : '100%'}
  >
    <UIDropDownMenu
      autoWidth
      shevronRenderer={() => null}
      select={(value) => onChange(value.key)}
      inputStyles={styles.input}
      itemTemplate={FlagItemTemplate}
      iconStyle={styles.iconStyle}
      menuItemStyle={styles.menuItemStyle}
      menuStyle={styles.menuStyle}
      menuItemPickedStyle={styles.menuItemPickedStyle}
      inputName="language-dropdown"
      itemsArr={LANGUAGES.filter(
        (language) => language.key !== locale && (!language.key || window.brandProps.languages.includes(language.key))
      )}
      selectionRenderer={() => (
        <Fragment>
          <UIIcon type={LANGUAGES.find((language) => language.key === locale).title} xs={styles.xs.icon} />
          <UIIcon
            width="10px"
            height="5px"
            margin="0 0 3px 8px"
            type="iconChevronDownLang"
            stroke="#4266bd"
            xs={styles.xs.iconChev}
          />
        </Fragment>
      )}
    />
  </UILayout>
)

LanguagePicker.propTypes = {
  locale: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default LanguagePicker
