import React, { PureComponent } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  align-items: flex-start;
`

class ActionsPanel extends PureComponent {
  render() {
    const { children } = this.props

    return <Wrapper>{children}</Wrapper>
  }
}

export { ActionsPanel }
