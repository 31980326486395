import React, { Fragment } from 'react'
import { pathOr } from 'ramda'

class ScrollToChildContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.scrollRef = React.createRef()
    this.scrolled = false
  }

  scrollIntoView() {
    const isRefInitialized = Boolean(pathOr(false, ['scrollRef', 'current', 'scrollIntoView'], this))

    if (isRefInitialized && !this.scrolled) {
      this.scrollRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
      this.scrolled = true
    }
  }

  componentDidMount() {
    this.scrollIntoView()
  }

  componentDidUpdate() {
    this.scrollIntoView()
  }

  // Can be used in different code places and wrap up some parts of Components' places, so Wrapper, not HOC
  render() {
    return (
      <Fragment>
        <div ref={this.scrollRef} />
        {this.props.children}
      </Fragment>
    )
  }
}

export default ScrollToChildContainer
