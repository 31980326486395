import React from 'react'
import PropTypes from 'prop-types'
import { pathOr } from 'ramda'

import PassengerData from './PassengerData'
import VehicleData from './VehicleData'

import { EditModeContext } from '../../../../../../MyTicket/containers/MyTicketEditMode'

import RowContainer from '../../../../../../Common/components/Layouts/RowContainer'

const styles = {
  rowAdStyles: { margin: '0' },
}

class CustomerInfo extends React.PureComponent {
  static propTypes = {
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phones: PropTypes.any.isRequired,
    vehicleNumber: PropTypes.string,
    carWidth: PropTypes.any,
    carHeight: PropTypes.any,
    carLength: PropTypes.any,
    carWeight: PropTypes.any,
    onEdit: PropTypes.func,
    showVehicle: PropTypes.bool.isRequired,
    userNeedToPayAfterEdit: PropTypes.any,
    vehicleFromMnt: PropTypes.bool,
    trailer: PropTypes.object,
  }

  static defaultProps = {
    vehicleNumber: '',
    carWidth: '',
    carHeight: '',
    carLength: '',
    carWeight: '',
    onEdit: null,
    userNeedToPayAfterEdit: false,
    vehicleFromMnt: false,
    trailer: {},
  }

  render() {
    const {
      email = '',
      firstName = '',
      lastName = '',
      phones,
      vehicleNumber,
      carWidth,
      carHeight,
      carLength,
      carWeight,
      onEdit,
      showVehicle,
      userNeedToPayAfterEdit,
      vehicleFromMnt,
      trailer,
    } = this.props

    const phone = pathOr({ intlCode: '', phoneNumber: '' }, [0], phones)
    const phoneNumber = `+${phone.intlCode} ${phone.phoneNumber}`

    return (
      <EditModeContext.Consumer>
        {(editMode) => {
          const lockEdit = editMode === 'datetime'

          return (
            <RowContainer rowAdStyles={styles.rowAdStyles} coef={2} indent={8}>
              <PassengerData
                onEdit={() => onEdit('customerForm')}
                editMode={editMode}
                lockEdit={lockEdit}
                phoneNumber={phoneNumber}
                email={email}
                name={`${firstName} ${lastName}`}
                userNeedToPayAfterEdit={userNeedToPayAfterEdit}
              />

              <VehicleData
                display-if={vehicleNumber && showVehicle}
                lock={lockEdit}
                editMode={editMode}
                onEditCar={() => onEdit('car')}
                onEditTrailer={() => onEdit('trailer')}
                vehicleNumber={vehicleNumber}
                carWidth={carWidth}
                carHeight={carHeight}
                carWeight={carWeight}
                carLength={carLength}
                vehicleFromMnt={vehicleFromMnt}
              />

              <VehicleData
                display-if={trailer && trailer.trailerNumber && trailer.showTrailer}
                vehicleType="trailer"
                lock={lockEdit}
                editMode={editMode}
                onEditCar={() => onEdit('car')}
                onEditTrailer={() => onEdit('trailer')}
                vehicleNumber={trailer.trailerNumber}
                carWidth={trailer.trailerWidth}
                carHeight={trailer.trailerHeight}
                carWeight={trailer.trailerWeight}
                carLength={trailer.trailerLength}
                vehicleFromMnt={trailer.trailerFromMnt}
              />
            </RowContainer>
          )
        }}
      </EditModeContext.Consumer>
    )
  }
}

CustomerInfo.contextTypes = {
  intl: PropTypes.any,
}

export default CustomerInfo
