import {
  watchCreateCorporateAccount,
  watchCreateCorporateAccountSuccess,
  watchCreateCorporateAccountFailed,
} from './CreateCompanyMember/createCorporateAccountSaga'
import { watchActivateCompanyMember } from './ActivateCompanyMember/activateCompanyMemberSaga'
import { watchDeactivateCompanyMember } from './DeactivateCompanyMember/deactivateCompanyMemberSaga'
import { watchRemoveCompanyMember } from './RemoveCompanyMember/RemoveCompanyMemberSaga'
import * as fromCompanyMemberSaga from './CompanyMembers/companyMembersSaga'

export function* companyMembersSaga() {
  yield [
    fromCompanyMemberSaga.watchActivateCompanyMember(),
    fromCompanyMemberSaga.watchDeactivateCompanyMember(),
    fromCompanyMemberSaga.watchEditCompanyMember(),
    fromCompanyMemberSaga.watchFetchCompanyMembers(),
    fromCompanyMemberSaga.watchFetchCompanyMemberReservations(),
    fromCompanyMemberSaga.watchInviteCompanyMember(),
    fromCompanyMemberSaga.watchInviteCompanyMemberSuccess(),
    fromCompanyMemberSaga.watchResendInviteCompanyMember(),
    fromCompanyMemberSaga.watchCancelInviteCompanyMember(),
    fromCompanyMemberSaga.watchCancelInviteCompanyMemberSuccess(),
    fromCompanyMemberSaga.watchActivateCompanyMemberSuccess(),
    fromCompanyMemberSaga.watchDeactivateCompanyMemberSuccess(),
    fromCompanyMemberSaga.watchRemoveCompanyMember(),
    fromCompanyMemberSaga.watchRemoveCompanyMemberSuccess(),
    fromCompanyMemberSaga.watchViewCompanyMemberTickets(),
    watchActivateCompanyMember(),
    watchCreateCorporateAccount(),
    watchCreateCorporateAccountFailed(),
    watchCreateCorporateAccountSuccess(),
    watchDeactivateCompanyMember(),
    watchRemoveCompanyMember(),
  ]
}
