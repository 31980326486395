import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout } from 'bora-material-ui'

import ReservationRow from '../ReservationRow'

const ListOfReservations = (props) => (
  <UILayout column align="flex-start" margin="30px 66px 6px 0px">
    <UIText color="#1fbcd2" fontSize="16px" weight="bold" textTransform="uppercase">
      {props.title}
    </UIText>
    {props.reservations &&
      props.reservations.map((reservation) => (
        <ReservationRow onClick={props.onClickReservation} reservation={reservation} />
      ))}
  </UILayout>
)

ListOfReservations.propTypes = {
  title: PropTypes.string.isRequired,
  onClickReservation: PropTypes.func.isRequired,
  reservations: PropTypes.array,
}

ListOfReservations.defaultProps = {
  reservations: [
    {
      from: 'Rohukula',
      to: 'Helterma',
      date: '29.11.2018',
      tickets: [{}, {}],
      status: 'Paid',
    },
    {
      from: 'Tallinn',
      to: 'Stockholm',
      date: '31.12.2017',
      tickets: [{}, {}],
      status: 'Unpaid',
    },
    {
      from: 'Rohukula',
      to: 'Helterma',
      date: '29.11.2018',
      tickets: [{}, {}],
      status: 'Rest',
      restCost: 27,
    },
  ],
}

export default ListOfReservations
