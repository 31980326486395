import React from 'react'
import { connect } from 'react-redux'

import { actions } from '../provider'
import messages from '../../../consts/messages'

import ModalReservation from './containers/ModalReservation'

const ModalHotelIsNotAdded = (props) => {
  const { showModal } = props

  return (
    <ModalReservation
      onClick={() => showModal('')}
      contextText={messages.hotelIsNotAdded}
      buttonText={messages.ok}
      buttonWidth="100px"
    />
  )
}

export default connect(() => ({}), actions)(ModalHotelIsNotAdded)
