import React, { PureComponent } from 'react'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import messages from '../../../../consts/messages'

class CompanyMemberCardInvite extends PureComponent {
  render() {
    const { inviteCompanyMember } = this.props

    return (
      <UILayout
        column
        padding="10px 15px"
        borderRadius="4px"
        justify="space-around"
        bgColor="#e5e5e5"
        border="none"
        cursor="pointer"
        display-if={Boolean(inviteCompanyMember)}
        hoverStyles={{ 'background-color': '#f1f1f1' }}
        onClick={inviteCompanyMember}
      >
        <UILayout center column j-flex-center height="100%" position="relative">
          <UIButton type="circle" width="40px" height="40px">
            <UIIcon fill="white" type="add" />
          </UIButton>
          <UIText
            margin="15px 0 0 0"
            color="#9b9b9b"
            size="18px"
            textTransform="uppercase"
            translate={messages.inviteUser}
          />
        </UILayout>
      </UILayout>
    )
  }
}

export { CompanyMemberCardInvite }
