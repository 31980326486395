import React from 'react'
import PropTypes from 'prop-types'

import { UIButton, UIIcon, UILayout } from 'bora-material-ui'

const xsStyles = {
  margin: '0 10px',
}

const PlusMinusToggle = ({ active, onToggle, buttonStyle, testPrefix }, { muiTheme }) => (
  <UILayout width="auto" display-if={!active} data-test={`${testPrefix}-toggle`} sml={xsStyles}>
    <UIButton
      click={onToggle}
      type="circle"
      id={active && 'removeButton'}
      width="36px"
      height="36px"
      {...buttonStyle}
      {...muiTheme.ids.TicketsButtonSizes}
    >
      <UIIcon type={active ? 'iconClose' : 'add'} {...muiTheme.ids.TicketsButtonIconSizes} />
    </UIButton>
  </UILayout>
)

PlusMinusToggle.contextTypes = {
  muiTheme: PropTypes.object,
}

PlusMinusToggle.propTypes = {
  testPrefix: PropTypes.string,
  active: PropTypes.bool.isRequired,
  buttonStyle: PropTypes.any,
  onToggle: PropTypes.bool.isRequired,
}

PlusMinusToggle.defaultProps = {
  buttonStyle: '',
  testPrefix: '',
}

export default PlusMinusToggle
