import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { actions } from '../provider'
import { getCurrentReservationId } from '../../../services/reservation/selectors'
import messages from '../../../consts/messages'

import ModalReservation from './containers/ModalReservation'

const ModalReservationWannaExpired = (props) => {
  const { extendReservation, showModal, reservationID } = props

  return (
    <ModalReservation
      onClick={() => {
        extendReservation(reservationID)
        showModal('')
      }}
      contextText={messages.sessionWillExpired}
      buttonText={messages.extendMySession}
      buttonWidth="275px"
    />
  )
}

ModalReservationWannaExpired.propTypes = {
  extendReservation: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  reservationID: PropTypes.string.isRequired,
}

export default connect(
  (state) => ({ reservationID: getCurrentReservationId(state) }),
  actions
)(ModalReservationWannaExpired)
