import Loadable from 'react-loadable'
import RefundEdit from './RefundEdit'

const RefundResults = Loadable({
  loader: () => import('./RefundResults'),
  loading: () => null,
})

const RefundScreen = Loadable({
  loader: () => import('./RefundScreen'),
  loading: () => null,
})

const CancelledReservation = Loadable({
  loader: () => import('./CancelledReservation'),
  loading: () => null,
})

export { RefundResults, RefundScreen, CancelledReservation, RefundEdit }
