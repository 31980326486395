import { UILayout } from 'bora-material-ui'
import React from 'react'
import { styles, xsStyles } from './Payment'
import useSWR from 'swr'
import { getTerms } from '../../../../../../../services/sales-static/api/confirmationTerms'
import { pathOr } from 'ramda'
import purify from 'dompurify'

const getTermsBody = pathOr('', [0, 'body'])

export const PaymentTerms = ({ locale = 'en', sailPackageCode }) => {
  const { data, error } = useSWR(`confirmation-terms-${sailPackageCode}-${locale}`, () =>
    getTerms(locale, sailPackageCode)
  )

  const routeTerms = error ? '' : getTermsBody(data)
  const html = { __html: purify.sanitize(routeTerms) }

  return (
    <UILayout
      data-testid="payment-terms"
      column
      margin="10px 0 0 0"
      color="black"
      childrenStyle={styles.termsChildren}
      sms={xsStyles.paymentTerms}
    >
      <section dangerouslySetInnerHTML={html}></section>
    </UILayout>
  )
}
