import React from 'react'
import PropTypes from 'prop-types'

import { UIIcon, UILayout, UITextField } from 'bora-material-ui'

import { fieldStyles } from './styles'

import messages from '../../../../consts/messages'

const ReservationsSearchField = (props, { intl }) => (
  <UILayout
    row
    center
    height="78px"
    xs={{ height: '64px', padding: '27px 30px 23px 30px' }}
    padding="27px 30px 23px 23px"
    bgColor="#627c88"
  >
    <UIIcon type="search" fill="white" width="27px" />
    <UILayout height="48px" width="1px" bgColor="#a9bfc9" margin="0px 21px">
      {' '}
    </UILayout>
    <UITextField type="text" hint={intl.formatMessage(messages.searchReservations)} {...fieldStyles} />
  </UILayout>
)

ReservationsSearchField.contextTypes = {
  intl: PropTypes.any,
}

export default ReservationsSearchField
