import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UILayout, UIText, UITextField, UIButton } from 'bora-material-ui'
import messages from '../../../../../consts/messages'
import { translate } from '../../../../Common/Translator'

const ButtonTextAround = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const getError = (code) => {
  const errors = {
    COMPANY_NOT_FOUND_IN_EBUSINESS_REGISTER: translate(messages.companyRegistrationNumberErrorNotFound),
  }
  return errors[code]
}

const styles = {
  textField: { margin: '0 20px 0 0' },
  contentWrapper: {
    padding: '0 0 18px',
    margin: '0px 0px 10px 0px',
    width: '100%',
    borderBottom: '1px solid #bfc3c7',
  },
  colorFailed: { color: '#b42a2a' },
}
class VehicleCompanyRegNumberParam extends React.PureComponent {
  state = {
    value: null,
  }

  static defaultProps = {
    value: false,
    disabled: false,
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    })
  }

  render() {
    const { onClick, disabled, errorCode } = this.props
    return (
      <UILayout data-testid="company-vehicle-form" column style={styles.contentWrapper}>
        <UILayout column margin="10px 0">
          <UIText color="#79909b" align={'left'} translate={messages.companyRegistrationNumberDescr} />
        </UILayout>
        <UILayout margin="10px 0">
          <UILayout width="50%">
            <UITextField
              readOnly={disabled}
              change={this.handleChange}
              id={'VehicleCompanyRegNumberParam'}
              value={this.state.value}
              type={'number'}
              hintText={translate(messages.companyRegistrationNumberHint)}
              style={styles.textField}
              inputStyle={errorCode && styles.colorFailed}
            />
          </UILayout>
          <UILayout width="50%">
            <UIButton
              data-testid="company-vehicle-form-button"
              disabled={disabled || !this.state.value}
              width="100%"
              height="auto"
              click={() => {
                onClick(this.state.value)
              }}
            >
              <ButtonTextAround>
                <span>{translate(messages.companyRegistrationNumberBtn)}</span>
              </ButtonTextAround>
            </UIButton>
          </UILayout>
        </UILayout>
        <UILayout>
          <UIText display-if={true} align="left" size="14px" color="#b42a2a" formattedMessage={getError(errorCode)} />
        </UILayout>
      </UILayout>
    )
  }
}
VehicleCompanyRegNumberParam.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  inputStyle: PropTypes.string,
}

export default VehicleCompanyRegNumberParam
