import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout, UIButton, UIIcon, UIDialog } from 'bora-material-ui'

import messages from '../../../../consts/messages'

class ReservationOptions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      refundModalIsOpen: false,
    }

    this.toggleRefundModal = () => this.setState({ refundModalIsOpen: !this.state.refundModalIsOpen })
  }

  render() {
    const { intl } = this.context

    return (
      <UILayout column style={{ borderTop: '2px solid #e7e7e7' }} padding="25px 0 30px 0">
        <UIText margin="20px 0 10px 0" size="14px" color="#000" align="left" translate={messages.options} />
        <UILayout row center>
          <UIButton
            click={() => window.print()}
            background="#63cdac"
            height="48px"
            borderRadius="2px"
            width="125px"
            xs={0}
          >
            <UILayout row height="100%" j-flex-center bgColor="transparent" center>
              <UIIcon type="iconPrinter" margin="0px 35px 0px 0px" />
              <UIText
                size="16px"
                color="white"
                align="right"
                textTransform="uppercase"
                translate={messages.printButton}
              />
            </UILayout>
          </UIButton>
          <UIButton
            click={this.toggleRefundModal}
            background="white"
            height="48px"
            borderRadius="2px"
            width="210px"
            margin="0px 0px 0px 10px"
            xs={{ margin: '0px' }}
          >
            <UILayout onClick={this.toggleRefundModal} row height="100%" width="100%" bgColor="transparent" center>
              <UIIcon type="iconRefund" margin="0px 35px 0px 15px" />
              <UIText
                size="16px"
                color="black"
                align="right"
                textTransform="uppercase"
                translate={messages.requestRefundButton}
              />
            </UILayout>
          </UIButton>
          <UIDialog
            modal={false}
            open={this.state.refundModalIsOpen}
            onRequestClose={this.toggleRefundModal}
            title={intl.formatMessage(messages.refundTitle)}
            actions={[
              <UIButton
                background="transparent"
                margin="10px"
                width="50px"
                height="40px"
                onClick={this.toggleRefundModal}
              >
                <UIText color="#5bb0ff" cursor="pointer" translate={messages.refundTitle} />
              </UIButton>,
              <UIButton
                background="transparent"
                margin="10px"
                width="50px"
                height="40px"
                onClick={this.toggleRefundModal}
              >
                <UIText color="black" cursor="pointer" translate={messages.no} />
              </UIButton>,
            ]}
          >
            <UIText color="rgba(0, 0, 0, 0.54)" translate={messages.refundDescr} />
            <UIText color="rgba(0, 0, 0, 0.54)" translate={messages.refundConfirmQuestion} />
          </UIDialog>
        </UILayout>
      </UILayout>
    )
  }
}

ReservationOptions.propTypes = {}

ReservationOptions.contextTypes = {
  intl: PropTypes.any,
}

export default ReservationOptions
