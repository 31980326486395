import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIIcon, UIText } from 'bora-material-ui'
import { getStyle, isLiinilaevad } from '../../../utils/liinilaevadThemeUtils'

const ReservationMessage = ({ icon, message, onAction }, { muiTheme }) => (
  <UILayout
    row
    height="auto"
    bgColor="#fff6d8"
    borderRadius={getStyle('0px', '3px')}
    padding="17px 20px"
    border={getStyle('none', 'solid 1px #f38211')}
    center
    j-flex-center={isLiinilaevad}
  >
    <UIIcon minWidth="29px" margin="0 14px 0 0" type={icon} />
    <UIText
      font={muiTheme.fontFamily}
      align="center"
      size="14px"
      color="#b42a2a"
      formattedMessage={message}
      onAction={(index) => onAction(index)}
    />
  </UILayout>
)

ReservationMessage.propTypes = {
  icon: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
}

ReservationMessage.contextTypes = {
  muiTheme: PropTypes.object.isRequired,
}

export default ReservationMessage
