import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { keys, prop } from 'ramda'
import { change, Field, getFormValues, reduxForm } from 'redux-form'
import moment from 'moment/moment'
import { UILayout } from 'bora-material-ui'

import getDepartureFields from '../components/DepartureFormFields'

import { DATE_FORMAT } from '../../../consts/stuff'

import { actions, selectors } from '../provider'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const xsStyles = {
  column: { column: true, row: false, margin: getStyle('20px 0 0 0') },
}

const DepartureForm = (props) => {
  const { fields, sailDate, select, name, locale } = props

  const mergedFields = fields.map((fld) => ({
    ...fld,
    name,
    value: sailDate,
    select: (date) => select(moment(date).locale(locale).format(DATE_FORMAT)),
  }))

  return (
    <UILayout data-testid="departureForm" column width="auto" xs={xsStyles.column}>
      {mergedFields.map((field) => (
        <Field key={field.name} {...field} showIcon={field.id === 'calendar'} />
      ))}
    </UILayout>
  )
}

DepartureForm.propTypes = {
  fields: PropTypes.array.isRequired,
  sailDate: PropTypes.string,
  select: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onEditMode: PropTypes.bool.isRequired,
}

export default connect(
  (state) => ({
    locale: state.locale,
    mediaName: state.mediaName,
    availableSailsDates: selectors.getSailsAvailableDates(state),
    availableSailsDatesForEdit: selectors.selectScheduleState(state).availableDates,
    departureValues: getFormValues('departure')(state),
  }),
  (dispatch) => bindActionCreators({ ...actions, change }, dispatch),
  (stateProps, dispatchProps, ownProps) => {
    const allProps = { ...stateProps, ...dispatchProps, ...ownProps }
    const { onEditMode = false } = ownProps

    if (onEditMode) {
      const [sailCodeThere, sailCodeBack] = keys(allProps.availableSailsDatesForEdit)

      allProps.availableSailsDatesForEdit = {
        [sailCodeThere]: prop(sailCodeThere)(allProps.availableSailsDatesForEdit),
        [sailCodeBack]: prop(sailCodeBack)(allProps.availableSailsDatesForEdit),
      }
    }

    const fields = getDepartureFields(allProps)

    const initialValues = allProps.departureValues || {}

    if (allProps.name && allProps.sailDate) {
      initialValues[allProps.name] = moment(allProps.sailDate)
    }

    return { ...allProps, fields, initialValues }
  }
)(
  reduxForm({
    form: 'departure',
    destroyOnUnmount: false,
  })(DepartureForm)
)
