import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { UITicket, UILayout } from 'bora-material-ui'

import UIElements from '../../../consts/UIElements'

import { actions } from '../provider'

class Reservation extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    getReservation: PropTypes.func.isRequired,
    reservation: PropTypes.any.isRequired,
  }

  componentWillMount() {
    const { reservationId } = this.props.match.params
    if (reservationId) this.props.getReservation({ reservationId })
  }

  render() {
    return (
      <UILayout
        column
        j-flex-start
        padding="40px 0 0px 0"
        margin="0"
        width={UIElements.CONTENT_WIDTH}
        xs={{ margin: '0', width: 'auto' }}
      >
        <UILayout xs={{ padding: '14px 14px 36px 14px' }}>
          <UITicket reservation={this.props.reservation} />
        </UILayout>
      </UILayout>
    )
  }
}

export default connect(
  (state) => ({
    reservation: {
      ...(state.reservation.exist || {}),
      vessel: { title: 'USS Enterprise' },
    },
  }),
  actions
)(Reservation)
