import React from 'react'
import { connect } from 'react-redux'

import PropTypes from 'prop-types'

import { UIDialog, UIIcon, UILayout, UIText } from 'bora-material-ui'
import { componentFromProp } from 'recompose'
import { contains, pathOr } from 'ramda'

import messages from '../../../consts/messages'
import { actions, selectors } from '../provider'
import {
  Confirmation,
  CreditCardForm,
  CustomVehicleWeight,
  DifferentLicensePlatesOnRoundTrip,
  DriverForReserveOnly,
  ModalHotelIsNotAdded,
  ModalReservationExpired,
  ModalReservationWannaExpired,
  ModalVehicleIsNotConfirmed,
} from '../components'

import {
  ModalEditCarInfo,
  ModalEditCustomerInfo,
  ModalEditDateTime,
  ModalEditPassenger,
  ModalEditTicketType,
  ModalEditTrailer,
} from '../../MyTicket/components/Modals'
import { ThemeProvider } from 'styled-components'
import { setFirstSailDate, setSecondSailDate } from '../../../services/user-selections'
import { showModal } from '../../../actions'
import { bindActionCreators } from 'redux'
import { getStyle, isLiinilaevad } from '../../../utils/liinilaevadThemeUtils'

const modals = {
  customerForm: ModalEditCustomerInfo,
  car: ModalEditCarInfo,
  trailer: ModalEditTrailer,
  datetime: ModalEditDateTime,
  tickets: ModalEditTicketType,
  passenger: ModalEditPassenger,
  reservationExpired: ModalReservationExpired,
  reservationWannaExpired: ModalReservationWannaExpired,
  addCustomVehicleWeight: CustomVehicleWeight,
  payWithCreditCard: CreditCardForm,
  confirmation: Confirmation,
  differentLicensePlateOnRoundTrip: DifferentLicensePlatesOnRoundTrip,
  driverForReserveWithLocalVehicleOnly: DriverForReserveOnly,
  modalVehicleIsNotConfirmed: ModalVehicleIsNotConfirmed,
  hotelIsNotAdded: ModalHotelIsNotAdded,
}

const getStyles = (isAttentionMode) => ({
  titleStyle: !isAttentionMode
    ? {
        padding: '12px 12px 5px',
        justifyContent: 'flex-end',
      }
    : {
        padding: '12px 12px 5px',
        justifyContent: 'flex-end',
        color: '#b42a2a',
      },
  actionsContainerStyle: { border: '0' },
})

const ModalContent = componentFromProp('component')

export const EditReservationContext = React.createContext({})

export const getContextByType = (type) => {
  const modal = modals[type]
  return modal && <ModalContent component={modal} />
}

const Modal = (props, context) => {
  const { muiTheme } = context
  const { children } = props
  const type = props.modal
  const isOpen = type !== ''
  let modalTitle = messages[`modal${type}`]
  const notEditModal = contains(type, [
    'reservationExpired',
    'reservationWannaExpired',
    'confirmation',
    'differentLicensePlateOnRoundTrip',
    'driverForReserveWithLocalVehicleOnly',
    'hotelIsNotAdded',
  ])
  const notConfirmationModal = contains(type, ['confirmation'])
  const isAttentionMode = contains(type, ['attentionMode'])

  if (type === 'addCustomVehicleWeight') modalTitle = messages[type]
  if (type === 'payWithCreditCard') modalTitle = messages[type]

  const showChildren = Boolean(children)

  const closeCross = notEditModal
    ? null
    : [
        <UILayout j-flex-end padding="18px 18px 5px 0">
          <UILayout
            j-flex-end
            cursor="pointer"
            onClick={() => {
              if (type === 'datetime') {
                props.resetDate()
                return
              }
              props.showModal('')
            }}
            width="18px"
          >
            <UIIcon type="iconCombinedShape" />
          </UILayout>
        </UILayout>,
      ]

  const styles = getStyles(isAttentionMode)

  const ifNoChildren = notEditModal ? (
    getContextByType(type)
  ) : (
    <EditReservationContext.Provider value={props.editReservation}>
      {getContextByType(type)}
    </EditReservationContext.Provider>
  )

  return (
    <UIDialog
      modal={false}
      onRequestClose={() => {
        if (!notEditModal) {
          props.showModal('')
        }
      }}
      open={isOpen}
      title={closeCross}
      titleStyle={styles.titleStyle}
      contentStyle={
        type === 'datetime' || type === 'tickets'
          ? { maxWidth: 'none', maxHeight: '90vh', overflowY: 'auto', transform: 'none' }
          : { maxWidth: 'none' }
      }
      actionsContainerStyle={styles.actionsContainerStyle}
      autoScrollBodyContent
      bodyClassName={getStyle('edit-modal-content-liinilaevad', 'edit-modal-content')}
      style={{ padding: '0 !important' }}
      className={!isAttentionMode ? 'edit-modal-wrap' : 'edit-modal-wrap confirm-mode'}
      display-if={(isAttentionMode && children) || !isAttentionMode}
    >
      <ThemeProvider theme={{ breakpoints: muiTheme.breakpoints }}>
        <UILayout
          center
          j-flex-center
          column
          padding={getStyle(!notEditModal && '0px 15px')}
          maxWidth={getStyle('600px')}
        >
          <UIText
            display-if={(isLiinilaevad && notEditModal) || (!isLiinilaevad && !notConfirmationModal)}
            font={muiTheme.secondFontFamilyDem}
            color={getStyle('white', !isAttentionMode ? '#2c4684' : '#b42a2a')}
            size={getStyle('22px', !isAttentionMode ? '24px' : '20px')}
            weight={getStyle('700')}
            background={getStyle('#000085')}
            width={getStyle('100%')}
            height={getStyle('80px')}
            lineHeight={getStyle('80px')}
            textTransform="uppercase"
            align="center"
            translate={modalTitle}
          />
          {showChildren ? children : ifNoChildren}
        </UILayout>
      </ThemeProvider>
    </UIDialog>
  )
}

Modal.propTypes = {
  modal: PropTypes.string.isRequired,
  editReservation: PropTypes.object,
  showModal: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  isFetching: PropTypes.bool,
}

Modal.defaultProps = {
  editReservation: {},
  isFetching: false,
}

Modal.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

function mapStateToProps(state) {
  const { selectedSailPackageIndexToEdit } = state
  const reservation = selectors.getEditReservation(state)
  const {
    seqN,
    code: sailPackage,
    sailRefs = [],
  } = pathOr({}, ['sailPackages', [selectedSailPackageIndexToEdit]])(reservation)
  const sailRefIds = sailRefs.map((sailRef) => sailRef.sailRefId)
  const departureDates = sailRefs.map((sailRef) => sailRef.departureDate)
  const oldSailPackage = { sailPackage, seqN, sailRefIds, departureDates }
  return {
    modal: state.modal,
    editReservation: selectors.getEditReservation(state),
    oldSailPackage,
    selectedSailPackageIndexToEdit,
    isFetching: state.fetching,
  }
}
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      ...actions,
      setSecondSailDate,
      setFirstSailDate,
      showModal,
    },
    dispatch
  )

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { selectedSailPackageIndexToEdit, oldSailPackage, ...props } = stateProps
  const [initialDepartureDate] = oldSailPackage.departureDates
  const selectDepartureDate = (date) => {
    const { setSecondSailDate, setFirstSailDate } = dispatchProps
    const selectDate = selectedSailPackageIndexToEdit ? setSecondSailDate : setFirstSailDate
    selectDate(date)
  }
  return {
    ...props,
    ...ownProps,
    ...dispatchProps,
    resetDate() {
      selectDepartureDate(initialDepartureDate)
      dispatchProps.showModal('')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Modal)
