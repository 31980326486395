import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { UIButton, UIIcon, UILayout, UIText } from 'bora-material-ui'
import { translate } from '../../../../../../Common/Translator'
import messages from '../../../../../../../consts/messages'

const NotEnoughCreditText = styled(UIText)`
  color: #b42a2a;
  font-size: 14px;
  margin-bottom: 20px;
  text-align: left;
`

const CreditLimitLeftText = styled(UIText)`
  color: #79909b;
  font-size: 12px;
  text-align: center;
`

const CreditLimitLeftWrapper = styled(UILayout)`
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
`

const OrText = styled(UIText)`
  color: #79909b;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 14px;
  text-align: center;
  text-transform: uppercase;
`

const PaymentButton = styled(UIButton).attrs({
  xs: {
    margin: '15px 0 0 0',
  },
})`
  max-width: 100% !important;
  width: 100% !important;
`

const ButtonInner = styled(UILayout)`
  align-items: center;
  height: 100%;
  justify-content: space-between;
  line-height: 30px;
  margin-left: 27px;
  margin-right: 27px;
  max-width: 100%;
  width: auto;
`

const ButtonContent = styled(UILayout)`
  box-sizing: border-box;
  align-items: center;
`

const ButtonText = styled(UIText)`
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 1.21;
  text-align: left;
  width: 200px;
`

class CustomerAccountPayment extends PureComponent {
  render() {
    const { disabled, formattedCreditLimit, isCreditLimitEnough } = this.props

    const {
      muiTheme: {
        ids: { buttonActionStyles },
        secondFontFamily,
        thirdFontFamily,
      },
    } = this.context

    return (
      <Fragment>
        <PaymentButton
          data-test="payment-from-customer-account"
          disabled={disabled}
          display-if={isCreditLimitEnough}
          onClick={this.paymentButtonHandleClick}
          {...buttonActionStyles}
        >
          <ButtonInner>
            <ButtonContent>
              <UIIcon fill="#ffffff" height="26px" margin="0 16px 0 0" type="iconBoughtCredit" width="26px" />

              <ButtonText font={thirdFontFamily} translate={messages.paymentFromAccount} />
            </ButtonContent>

            <UIIcon fill="#ffffff" type="selectTicketArrow" />
          </ButtonInner>
        </PaymentButton>

        <NotEnoughCreditText
          display-if={!isCreditLimitEnough}
          formattedMessage={translate(messages.notEnoughCredit, { formattedCreditLimit })}
        />

        <CreditLimitLeftWrapper>
          <CreditLimitLeftText
            display-if={isCreditLimitEnough}
            formattedMessage={translate(messages.creditLimitLeft, { formattedCreditLimit })}
          />
        </CreditLimitLeftWrapper>

        <OrText font={secondFontFamily} translate={messages.or} />
      </Fragment>
    )
  }

  paymentButtonHandleClick = () => {
    const { onPaymentClick } = this.props

    onPaymentClick('CREDIT')
  }

  static contextTypes = {
    muiTheme: PropTypes.any,
  }
}

export { CustomerAccountPayment }
