import React from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIDivider, UIIcon } from 'bora-material-ui'

const styles = {
  grayscale: { '-webkit-filter': 'grayscale(100%)', opacity: '0.8' },
}

const VehicleDivider = ({ iconType }) => (
  <UILayout column center j-flex-center position="relative" height="15px" margin="0 0 15px 0">
    <UIDivider height="3px" width="100%" />
    <UILayout width="auto" bgColor="inherit" transform="translateX(-50%)" position="absolute" top="0" left="50%">
      <UIIcon type={iconType} margin="0 20px" style={styles.grayscale} width="36px" height="15px" />
    </UILayout>
  </UILayout>
)

VehicleDivider.propTypes = {
  iconType: PropTypes.any.isRequired,
}

export default VehicleDivider
