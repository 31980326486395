import 'react-dates/initialize'
import 'regenerator-runtime/runtime'
import cn from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import messages from '../../../consts/messages'
import s from './NavigationBar.module.scss'
import { LanguagePicker } from '../../../modules/Language/components/Selects'
import { UIIcon, UILayout, UIText } from 'bora-material-ui'
import { configureScope } from '@sentry/browser'
import { connect } from 'react-redux'
import { changeLocale, getLocale, getMobileMenuStatus, toggleMobileMenu } from '../../../services/user-selections'
import { getToken } from '../../../services/auth/reducers/selectors'
import { goToPage } from '../../../actions'
import { addIndex, compose, keys, length, lt, map, path, reduce } from 'ramda'
import { menuItemsSelector } from '../../../services/sales-static/selectors'
import { olderBrowser } from '../../../utils/browserUtils'
import { signout } from '../../../services/auth/actions'
import { userEmailSelector } from '../../../services/user/selectors'
import LogRocket from 'logrocket'

const transformMenuItemsForAuthorized = (menuItems, actions) => {
  const logoutMenuItem = {
    title: '/auth/logout',
    translationObj: messages.logOut,
    clickHandler: actions.signout,
    link: '/auth/logout',
    className: 'logIn',
  }

  const profileMenuItem = {
    link: '/profile/settings',
    translationObj: messages.profile,
  }

  const getAuthLinks = (acc, linkItem) => {
    if (linkItem.link === '/auth/signup') {
      return [...acc, profileMenuItem]
    }

    if (linkItem.link === '/auth/login') {
      return [...acc, logoutMenuItem]
    }

    return [...acc, linkItem]
  }

  return reduce(getAuthLinks, [], menuItems)
}

const styles = {
  link: { color: 'inherit', textDecoration: 'none' },
  width100: { width: '100%' },
  icon: { position: 'absolute', right: '14px', top: '35px', zIndex: 1 },
  widthAuto: { width: '100%', ...(olderBrowser && { width: '995px' }) },
  heightAuto: { height: 'auto' },
  width200: { width: '200px' },
}

const NavigationBar = (props, context) => {
  const { intl, muiTheme } = context
  const { authToken, userEmail = `guest@${navigator.platform}` } = props
  let { menuItems } = props

  configureScope((scope) => {
    scope.setUser(userEmail)
    LogRocket.identify(userEmail)
  })

  if (authToken) menuItems = transformMenuItemsForAuthorized(props.menuItems, { signout: props.signout })
  const menuItemsForFF = ['Special offers', 'Infromation', 'Agencies', 'Fast Friends', 'Virtual Tour']

  const generateLabels = addIndex(map)((text, i) => (
    <UIText key={i} id="nav-bar-label" whiteSpace="nowrap" margin="0 20px" text={text} size="14px" />
  ))

  const getMenuItemsFromTheme = (menu, className) =>
    addIndex(map)((item, i) => {
      const menuItem = {}
      menuItem.title = {}
      menuItem.title.rendered = item
      menuItem.slug = path([item], menu)
      menuItem.id = i
      menuItem.className = className
      return menuItem
    }, keys(menu))

  const generateLinks = map(
    ({ subject: title, id, link, translationObj = messages.navBarBookTickets, className, clickHandler }) => {
      const authButtonsStyle = {
        '/auth/signup': 'signUp',
        '/profile/settings': 'signUp',
        '/auth/login': 'logIn',
        '/auth/logout': 'logIn',
      }[link]

      const handleClick = (e) => {
        e.preventDefault()
        props.toggleMobileMenu()

        if (clickHandler) clickHandler()
        else {
          props.goToPage(link)
        }
      }

      return (
        <div
          className={cn(className, s.link, s[authButtonsStyle], { [s.olderBrowser]: olderBrowser })}
          key={id || translationObj.id}
          data-test={`top-nav-${link}`}
        >
          <a href={link} className={s.linkElement} onClick={handleClick}>
            <div id="nav-bar-label" className={s.linkText}>
              {title || intl.formatMessage(translationObj)}
            </div>
          </a>
        </div>
      )
    }
  )

  const showLanguagePicker = compose(lt(1), length, path(['brandProps', 'languages']))(window)

  let style = {}
  let AdditionalMenuStyle = {}
  let linkClass = ''

  switch (window.brandProps.theme) {
    case 'fastferries':
      return (
        <UILayout center margin="0" style={styles.width100} xs={0}>
          <UIText
            id="nav-bar-label"
            cursor="pointer"
            whiteSpace="nowrap"
            margin="0 20px"
            width="auto"
            text="Routes - Prices"
          />
          {generateLabels(menuItemsForFF)}
        </UILayout>
      )
    case 'seajets':
      style = {
        margin: '40px 0 0 0',
      }
      AdditionalMenuStyle = {
        margin: '0',
      }
      linkClass = s.seajets
    case 'tallink': // eslint-disable-line
      return (
        <UILayout center margin="0" style={styles.widthAuto} xs={0} data-test="top-menu-wrap" {...style}>
          {menuItems ? generateLinks(menuItems) : generateLinks(getMenuItemsFromTheme(muiTheme.content.menu))}
          <UILayout
            j-flex-end
            center
            position="absolute"
            right="0"
            top="0"
            margin="0"
            style={styles.width200}
            xs={0}
            data-test="top-menu-one"
          >
            <LanguagePicker display-if={showLanguagePicker} locale={props.locale} onChange={props.changeLocale} />
            {generateLinks(getMenuItemsFromTheme(muiTheme.content.loginMenu, linkClass))}
          </UILayout>
          <UILayout
            j-flex-end
            margin="0 0 0 90px"
            style={styles.width100}
            xs={0}
            data-test="top-menu-two"
            {...AdditionalMenuStyle}
          >
            {generateLinks(getMenuItemsFromTheme(muiTheme.content.AdditionalMenu))}
          </UILayout>
        </UILayout>
      )
    default:
      return (
        <div className={cn(s.root, { [s.opened]: props.mobileMenuOpened, [s.olderBrowser]: olderBrowser })}>
          <div
            className={s.toggleIconHolder}
            data-test="mobile-menu-toggle-action"
            onClick={() => props.toggleMobileMenu()}
          >
            <UIIcon className={s.closeIcon} display-if={props.mobileMenuOpened} type="iconClose" />
            <UIIcon className={s.openIcon} display-if={!props.mobileMenuOpened} type="iconMenu" />
          </div>
          <div className={s.navItemsHolder} data-test="top-menu-mobile">
            {menuItems && generateLinks(menuItems)}
            <LanguagePicker display-if={showLanguagePicker} locale={props.locale} onChange={props.changeLocale} />
          </div>
        </div>
      )
  }
}

NavigationBar.propTypes = {
  locale: PropTypes.string.isRequired,
  changeLocale: PropTypes.func.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
  menuItems: PropTypes.array,
  mobileMenuOpened: PropTypes.bool,
}

NavigationBar.defaultProps = {
  menuItems: null,
  mobileMenuOpened: false,
}

NavigationBar.contextTypes = {
  intl: PropTypes.any,
  muiTheme: PropTypes.object,
}

export default connect(
  (state) => ({
    locale: getLocale()(state),
    authToken: getToken(state),
    menuItems: menuItemsSelector(state),
    mobileMenuOpened: getMobileMenuStatus(state),
    userEmail: userEmailSelector(state),
  }),
  { changeLocale, toggleMobileMenu, goToPage, signout }
)(NavigationBar)
