import { isEmpty, all, path, compose, filter, values, is, propEq, map, anyPass, toUpper, propOr } from 'ramda'

export const areAllFormsEmpty = all(isEmpty)

export const getVerifiedLicensePlate = path(['plateNumberVehicle', 'licensePlate'])
export const getCalculatedTicketPrice = path(['plateNumber'])

export const vehicleReadyToBeAdded = compose(
  map(toUpper),
  filter(is(String)),
  map((vehicleData) => getVerifiedLicensePlate(vehicleData) || getCalculatedTicketPrice(vehicleData))
)

const isOfTypeVehicle = propEq('subType', 'VEHICLE')
const isOfTypeTrailer = propEq('subType', 'TRAILER')

export const getVehiclesFromTicketPlateNumbers = compose(
  map(toUpper),
  map(propOr('', 'plateNumber')),
  filter(anyPass([isOfTypeVehicle, isOfTypeTrailer])),
  filter(is(Object)),
  values
)
