/* eslint-disable react/jsx-closing-tag-location */
import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { UIBlock, UILayout, UIText } from 'bora-material-ui'

import { ReservationsSearchField, ListOfReservations } from '../../components'

import messages from '../../../../consts/messages'
import UIElements from '../../../../consts/UIElements'

import { selectors, actions } from '../../provider'

class MyReservations extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    finishedReservations: PropTypes.array.isRequired,
    futureReservations: PropTypes.array.isRequired,
    goToPage: PropTypes.func.isRequired,
  }

  static contextTypes = {
    intl: PropTypes.any,
  }

  handleSelectReservation = (reservation) => {
    const { goToPage } = this.props
    goToPage({ pathname: '/my/reservation/123', state: { reservation } })
  }

  render() {
    const { intl } = this.context
    const { futureReservations, finishedReservations } = this.props
    const mainLeftPadding = '30px'

    // eslint-disable-next-line max-len
    return (
      <UILayout
        column
        j-flex-start
        padding="0"
        margin={`${UIElements.HEADER ? '-97px' : '40px'} 0 0`}
        width={UIElements.CONTENT_WIDTH}
        xs={{ margin: '0', width: 'auto' }}
      >
        <UIBlock bgColor="#fafafa">
          <UILayout
            center
            j-flex-start
            height="120px"
            padding={`29px 23px 28px ${mainLeftPadding}`}
            bgColor={UIElements.MAIN_VIEW_TRIP_HEADER_COLOR}
            xs={{ height: '84px', 'j-flex-center': true, padding: `16px 30px 16px ${mainLeftPadding}` }}
          >
            <UILayout column>
              <UIText align="left" size="14px" translate={messages.listOfAllMy} />
              <UIText align="left" size="34px" xs={{ size: '28px' }} translate={messages.reservations} />
            </UILayout>
          </UILayout>
          <ReservationsSearchField />

          <UILayout
            column
            width="auto"
            minHeight="80vh"
            padding={`24px 30px 100px ${mainLeftPadding}`}
            xs={{ padding: `16px 30px 16px ${mainLeftPadding}` }}
          >
            <ListOfReservations
              onClickReservation={this.handleSelectReservation}
              title={intl.formatMessage(messages.futureVoyages)}
              reservations={futureReservations}
            />
            <ListOfReservations
              onClickReservation={this.handleSelectReservation}
              title={intl.formatMessage(messages.finishedVoyages)}
              reservations={finishedReservations}
            />
          </UILayout>
        </UIBlock>
      </UILayout>
    )
  }
}

export default connect(selectors, (dispatch) => bindActionCreators(actions, dispatch))(MyReservations)
