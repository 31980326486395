import React, { Fragment } from 'react'
import s from './LLTicketPromotionLine.module.scss'
import { translate } from '../../../../Common/Translator'
import messages from '../../../../../consts/messages'
import { UIFormattedNumber } from 'bora-material-ui'
import cn from 'classnames'

export const LLDiscountSummary = ({ shouldShow, promoDiscountSummary, ticketView = false, editView = false }) => {
  return (
    <div
      className={cn(s.wrap, s.totalDiscount, { [s.toTheLeft]: ticketView }, { [s.editView]: editView })}
      display-if={shouldShow}
    >
      {Object.keys(promoDiscountSummary).map((promoKey) => {
        return (
          <div className={s.rowWrap}>
            {translate(messages[`promo-acc-${promoKey}`])}
            <span className={s.price}>
              <UIFormattedNumber style="currency" currency="eur" value={promoDiscountSummary[promoKey]} />
            </span>
          </div>
        )
      })}
    </div>
  )
}

export const LLTicketPromotionLine = ({ passenger, last, promoDiscountSummary }) => {
  return (
    <Fragment>
      <div className={s.wrap}>
        <span>{translate(messages[`promo-${passenger.promotion}`])}</span>
      </div>
      <LLDiscountSummary shouldShow={last} promoDiscountSummary={promoDiscountSummary} />
    </Fragment>
  )
}
