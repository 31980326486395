import moment from 'moment'
import { all, lte } from 'ramda'

const birthDatesOptions = []
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 31; i++) {
  birthDatesOptions.push({ index: i, title: `${i + 1}` })
}

const birthMonthsOptions = []
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 12; i++) {
  birthMonthsOptions.push({ index: i, title: `${i + 1}`.padStart(2, '0') })
}

export const MIN_YEAR = 1900
const birthYearsOptions = []
// eslint-disable-next-line no-plusplus
for (let i = moment().year(); i >= MIN_YEAR; i--) {
  birthYearsOptions.push({ index: i - MIN_YEAR, title: `${i}` })
}

const allGreaterOrEqZero = all(lte(0))

export const getDateISOString = ({ date = -1, month = -1, year = -1 }) => {
  if (allGreaterOrEqZero([date, month, year])) {
    const yearStr = birthYearsOptions[year].title
    const monthStr = (month + 1).toString().padStart(2, '0')
    const dateStr = birthDatesOptions[date].title.toString().padStart(2, '0')
    return `${yearStr}-${monthStr}-${dateStr}`
  }
  return ''
}

export { birthDatesOptions, birthMonthsOptions, birthYearsOptions }
