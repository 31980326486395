import React from 'react'

import { UIText, UILayout, UIFormattedNumber } from 'bora-material-ui'

const TicketRow = () => (
  <UILayout
    bgColor="#fff"
    border="solid 1px #e5e5e4"
    height="40px"
    padding="0 21px 0 19px"
    margin="0 0 10px 0"
    center
    j-flex-space-between
  >
    <UIText align="left" color="#000" size="18px">
      Lisa Ann Smith
    </UIText>
    <UIText align="left" color="#000" size="18px">
      Adult Ticket
    </UIText>
    <UIText align="left" color="#000" size="18px">
      <UIFormattedNumber value={39.99} style="currency" currency="eur" />
    </UIText>
  </UILayout>
)

TicketRow.propTypes = {}

TicketRow.contextTypes = {}

export default TicketRow
