import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout, UIFormattedNumber } from 'bora-material-ui'

import UIElements from '../../../../consts/UIElements'
import messages from '../../../../consts/messages'

const ReservationHeader = ({ reservation }) => (
  <UILayout
    center
    j-flex-space-between
    height="120px"
    padding="29px 23px 28px 30px"
    bgColor={UIElements.MAIN_VIEW_TRIP_HEADER_COLOR}
    xs={{ height: '84px', 'j-flex-center': true, padding: '16px 30px 16px 30px' }}
  >
    <UILayout column>
      <UIText align="left" size="14px" translate={messages.ticketPaid} />
      <UIText align="left" size="34px" xs={{ size: '28px' }}>
        Rohukula - Helterma
      </UIText>
    </UILayout>
    <UILayout j-flex-end width="auto">
      <UILayout
        display-if={reservation.status === 'Unpaid'}
        height="40px"
        width="70px"
        border="1px solid white"
        borderRadius="2px"
        j-flex-center
        center
      >
        <UIText color="white" translate={messages.unpaid} />
      </UILayout>
      <UILayout
        display-if={reservation.status === 'Paid'}
        height="102px"
        width="102px"
        borderRadius="2px"
        bgColor="white"
        j-flex-center
        center
        xs={{ height: '60px', width: '60px' }}
      >
        {' '}
      </UILayout>
      <UILayout
        display-if={reservation.status === 'Rest'}
        height="40px"
        width="200px"
        xs={{ width: '80px' }}
        border="1px solid white"
        borderRadius="2px"
        j-flex-center
        center
      >
        <UILayout center j-flex-space-between padding="0px 10px">
          <UIText xs={0} xl={1} color="white" translate={messages.payAdditional} />
          <UIText color="white">
            <UIFormattedNumber value={-reservation.restCost} style="currency" currency="eur" />
          </UIText>
        </UILayout>
      </UILayout>
    </UILayout>
  </UILayout>
)

ReservationHeader.propTypes = {
  reservation: PropTypes.object.isRequired,
}

ReservationHeader.contextTypes = {}

export default ReservationHeader
