/* eslint-disable max-lines-per-function */
import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'
import { find, propEq } from 'ramda'

import { contactFields as fields } from '../../components/ContactFormFields'
import { translate } from '../../../Common/Translator'

import { InputField, DropDownField } from '../../../../formComponents'
import messages from '../../../../consts/messages'

import { CustomerRole } from './CustomerRole'
import { FORM_MODE_PRIVATE } from './consts'
import { modernBrowser } from '../../../../utils/browserUtils'
import { fillContactDataForm, isReallyProduction, fillCompanyDataForm } from '../../../../utils/formFillinUtils'
import { getPhoneCode } from '../../../../services/utils'
import UIElements from '../../../../consts/UIElements'
import { isGavdos } from 'bora/src/utils/gavdosStyleUtils'
import { THEME } from 'bora-material-ui/themes'
import { getStyle } from '../../../../utils/liinilaevadThemeUtils'

const xsStyles = {
  column: { column: true },
  titleWrapper: { margin: ' 0 0 15px' },
  title: { align: 'center' },
}

const CompanyItemTemplate = (props) => {
  if (!props) return null
  return (
    <UILayout height="44px" align="center">
      <UIText size={getStyle('18px', '15px')} color="black" text={props.title || props.value.title} />
    </UILayout>
  )
}

CompanyItemTemplate.propTypes = {
  value: PropTypes.object,
  title: PropTypes.string,
}

CompanyItemTemplate.defaultProps = {
  value: {},
  title: '',
}

const defaultPhoneCode = getPhoneCode(window.brandProps.theme)

const fillButtonStyles = {
  marginRight: '20px',
  fontSize: 'x-small',
  width: '150px',
  background: 'darkorange',
  border: 0,
  color: 'white',
  cursor: 'pointer',
  padding: '5px',
}

class CustomerInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedMode: 'private',
    }

    this.fillContact = this.fillContact.bind(this)
    this.fillCompanyContact = this.fillCompanyContact.bind(this)
  }

  componentDidMount() {
    this.props.fetchCountries()
  }

  fillContact() {
    fillContactDataForm(this.props.change)
  }

  fillCompanyContact() {
    fillCompanyDataForm(this.props.change)
  }

  render() {
    const { muiTheme } = this.context
    const { editMode, userDetails = {}, allowedCountries = [], formMode } = this.props

    const { firstName, lastName, phoneCode, phoneNumber, email } = userDetails

    const firstNameField = find(propEq('name', 'firstName'))(fields)
    const lastNameField = find(propEq('name', 'lastName'))(fields)
    const phoneCodeField = find(propEq('name', 'phoneCode'))(fields)
    const phoneNumberField = find(propEq('name', 'phoneNumber'))(fields)
    const emailField = find(propEq('name', 'email'))(fields)
    const companyNameField = find(propEq('name', 'legalName'))(fields)
    const companyNrField = find(propEq('name', 'registrationNumber'))(fields)
    const addressField = find(propEq('name', 'addressLine'))(fields)
    const codeField = find(propEq('name', 'zip'))(fields)
    const cityField = find(propEq('name', 'city'))(fields)
    const countryField = find(propEq('name', 'country'))(fields)

    const errorStyles = {
      'margin-top': '6px',
      'font-size': '12px',
      'font-family': muiTheme.secondFontFamily,
      ...getStyle({ textAlign: 'center' }),
    }

    const wrapperStyles = { padding: '0', ...muiTheme.ids.InputFieldWrapper }
    const lockedInputStyles = { padding: '1px 35px 0 14px' }
    const wrapperInputStyles = { padding: '20px 0 0 0', ...muiTheme.ids.InputFieldWrapperInput }
    const phoneCodeWrapperStyles = {
      padding: getStyle('0 18px 0 0', '0'),
      maxWidth: getStyle('205px', '120px'),
      ...muiTheme.ids.InputFieldWrapper,
      background: 'salmon',
    }
    const buttonBackgroundColor = muiTheme.palette.selectedItemSecondary
    const buttonBackgroundColorInactive = muiTheme.palette.unselectedItemSecondary

    const noAuthApp = Boolean(UIElements.NO_AUTH)

    const getColor = (color, defaultColor) => (window.brandProps.theme === THEME.LIINILAEVAD ? color : defaultColor)

    const liinilaevadInputStyles = {
      background: '#EDEDED',
      border: '1px solid #D4D4D4',
      borderRadius: '4px',
      boxShadow: 'none',
      padding: '4px 0 0 0',
    }

    return (
      <UILayout column width="100%" margin="0 0 26px 0" data-test="customer-info">
        <UILayout row center width="100%" sm={xsStyles.column} display-if={!this.props.editMode}>
          <UILayout sm={xsStyles.titleWrapper}>
            <UIText
              font={window.brandProps.muiTheme.secondFontFamily}
              width="100%"
              size="20px"
              color={getColor(muiTheme.palette.primary4Color, 'black')}
              align="left"
              translate={messages.customerInformation}
              sm={xsStyles.title}
              {...(muiTheme.ids.TicketTypeRow &&
                muiTheme.ids.TicketTypeRow.titles && {
                  ...muiTheme.ids.TicketTypeRow.titles,
                })}
            />
          </UILayout>
          <button style={fillButtonStyles} display-if={!isReallyProduction()} onClick={this.fillContact}>
            Fill random
          </button>
          <CustomerRole display-if={!noAuthApp} />
        </UILayout>

        <UILayout padding={getStyle('6px 0 0 0', '0 0 0 36px')} display-if={!isGavdos}>
          <UILayout
            // display-if={!noAuthApp}
            className="trailers-switcher"
            margin="16px 0 0 0"
            borderRadius="15px"
            style={{
              ...(buttonBackgroundColorInactive && {
                'background-color': buttonBackgroundColorInactive,
              }),
              ...(muiTheme.ids.trailersSwitcher && { ...muiTheme.ids.trailersSwitcher }),
              ...getStyle({
                height: '50px',
                borderRadius: '12px',
              }),
            }}
          >
            <button
              className={
                formMode !== 'private'
                  ? 'trailers-switcher__button'
                  : 'trailers-switcher__button trailers-switcher__button_active'
              }
              onClick={() => this.props.change('formMode', 'private')}
              style={{
                ...(buttonBackgroundColor &&
                  buttonBackgroundColorInactive && {
                    'background-color': formMode === 'private' ? buttonBackgroundColor : 'rgba(0,0,0,0)',
                    color: formMode === 'private' ? muiTheme.palette.accent1Color : muiTheme.palette.selectedItem,
                  }),
                ...getStyle({
                  fontFamily: 'SourceSansPro, Sans-Serif',
                  fontSize: '18px',
                  fontWeight: '700',
                  boxShadow: 'none',
                }),
              }}
            >
              {translate(messages.contactFormPrivate)}
            </button>
            <button
              className={
                formMode !== 'company'
                  ? 'trailers-switcher__button'
                  : 'trailers-switcher__button trailers-switcher__button_active'
              }
              onClick={() => this.props.change('formMode', 'company')}
              style={{
                ...(buttonBackgroundColor &&
                  buttonBackgroundColorInactive && {
                    'background-color': formMode === 'company' ? buttonBackgroundColor : 'rgba(0,0,0,0)',
                    color: formMode === 'company' ? muiTheme.palette.accent1Color : muiTheme.palette.selectedItem,
                  }),
                ...getStyle({
                  fontFamily: 'SourceSansPro, Sans-Serif',
                  fontSize: '18px',
                  fontWeight: '700',
                  boxShadow: 'none',
                }),
              }}
            >
              {translate(messages.contactFormCompany)}
            </button>
          </UILayout>
        </UILayout>

        <UILayout column center width="100%" data-test="customer-info-form">
          <UILayout
            row={getStyle(false, true)}
            column={getStyle(true, false)}
            center
            width="100%"
            sm={xsStyles.column}
            {...muiTheme.ids.CustomerInfoDirections}
          >
            <InputField
              key={firstNameField.name}
              {...firstNameField}
              icon={getStyle('', firstNameField.icon)}
              showText
              required
              hideStar
              inputStyle={firstName ? lockedInputStyles : undefined}
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={wrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              data-test="customer-input-first-name"
              {...muiTheme.ids.InputField}
              disabled={firstName}
              locked={firstName}
            />
            <UILayout {...muiTheme.ids.CustomerInfoDirections}>
              <InputField
                key={lastNameField.name}
                {...lastNameField}
                required
                showText
                hideStar
                inputStyle={lastName ? lockedInputStyles : undefined}
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-last-name"
                {...muiTheme.ids.InputField}
                disabled={lastName}
                locked={lastName}
              />
            </UILayout>
          </UILayout>
          <UILayout row center width="100%" {...muiTheme.ids.CustomerInfoDirections} column={false}>
            <InputField
              key={phoneCodeField.name}
              {...phoneCodeField}
              icon={getStyle('', phoneCodeField.icon)}
              required
              showText
              editMode={editMode}
              hideStar
              substitutionalLabelForLiinilaevad={messages[phoneNumberField.name]}
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={phoneCodeWrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              showPlus={getStyle(false, modernBrowser)}
              data-test="customer-input-phone-code"
              {...muiTheme.ids.InputField}
              disabled={phoneCode}
              onBlur={(e, newValue) => {
                e.preventDefault()
                this.props.change('phoneCode', newValue || defaultPhoneCode)
              }}
              // locked={phoneCode}
            />
            <InputField
              key={phoneNumberField.name}
              {...phoneNumberField}
              required
              hideStar
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={wrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              data-test="customer-input-phone-number"
              {...muiTheme.ids.InputField}
              disabled={phoneNumber}
              locked={phoneNumber}
            />
          </UILayout>
          <UILayout row center width="100%" {...muiTheme.ids.CustomerInfoDirections}>
            <InputField
              display-if={!editMode}
              key={emailField.name}
              {...emailField}
              icon={getStyle('', emailField.icon)}
              required
              showText
              hideStar
              inputStyle={email ? lockedInputStyles : undefined}
              wrapperInputStyles={wrapperInputStyles}
              wrapperStyles={wrapperStyles}
              errorStyles={errorStyles}
              hideLabel
              data-test="customer-input-email"
              {...muiTheme.ids.InputField}
              disabled={email}
              locked={email}
            />
          </UILayout>
        </UILayout>

        {/* ------- */}

        <UILayout column center width="100%" data-test="company-info-form" display-if={formMode === 'company'}>
          <UILayout padding={getStyle('6px 0 0 0', '0 0 0 36px')} column>
            <button
              style={{ ...fillButtonStyles, margin: '1rem auto', fontSize: '0.9em' }}
              display-if={!isReallyProduction()}
              onClick={this.fillCompanyContact}
            >
              Fill Company Data
            </button>
            <UILayout row center width="100%" {...muiTheme.ids.CustomerInfoDirections}>
              <InputField
                key={companyNameField.name}
                {...companyNameField}
                required
                showText
                hideStar
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-company-name"
                {...muiTheme.ids.InputField}
              />
            </UILayout>

            <UILayout row center width="100%" {...muiTheme.ids.CustomerInfoDirections}>
              <InputField
                key={companyNrField.name}
                {...companyNrField}
                required
                hideStar
                showText
                wrapperInputStyles={wrapperInputStyles}
                wrapperStyles={wrapperStyles}
                errorStyles={errorStyles}
                hideLabel
                data-test="customer-input-company-regnumber"
                {...muiTheme.ids.InputField}
              />
            </UILayout>

            <UILayout column={getStyle(true, false)}>
              <UILayout
                row
                center
                width="70%"
                {...muiTheme.ids.CustomerInfoDirections}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '0 9px 0 0')}
              >
                <InputField
                  key={addressField.name}
                  {...addressField}
                  required
                  showText
                  hideStar
                  wrapperInputStyles={wrapperInputStyles}
                  wrapperStyles={wrapperStyles}
                  errorStyles={errorStyles}
                  hideLabel
                  data-test="customer-input-company-address"
                  {...muiTheme.ids.InputField}
                />
              </UILayout>

              <UILayout
                row
                center
                width="30%"
                {...muiTheme.ids.CustomerInfoDirections}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '0 9px 0 0')}
              >
                <InputField
                  key={codeField.name}
                  {...codeField}
                  required
                  hideStar
                  showText
                  wrapperInputStyles={wrapperInputStyles}
                  wrapperStyles={wrapperStyles}
                  errorStyles={errorStyles}
                  hideLabel
                  data-test="customer-input-company-postindex"
                  {...muiTheme.ids.InputField}
                />
              </UILayout>
            </UILayout>

            <UILayout column={getStyle(true, false)}>
              <UILayout
                row
                center
                width="50%"
                {...muiTheme.ids.CustomerInfoDirections}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '0 9px 0 0')}
              >
                <InputField
                  key={cityField.name}
                  {...cityField}
                  required
                  hideStar
                  showText
                  wrapperInputStyles={wrapperInputStyles}
                  wrapperStyles={wrapperStyles}
                  errorStyles={errorStyles}
                  hideLabel
                  data-test="customer-input-company-city"
                  {...muiTheme.ids.InputField}
                />
              </UILayout>

              <UILayout
                row
                center
                width="50%"
                {...muiTheme.ids.CustomerInfoDirections}
                {...getStyle({ column: false, alignItems: 'center' })}
                padding={getStyle(muiTheme.ids.CustomerInfoDirections.padding, '20px 0 0 9px')}
              >
                {window.brandProps.theme === THEME.LIINILAEVAD && (
                  <UIText
                    color="black"
                    width="145px"
                    font="SourceSansPro, Sans-Serif"
                    size="16px"
                    align="left"
                    padding="20px 0 0 0"
                    translate={messages[countryField.name]}
                  />
                )}

                <DropDownField
                  name="country"
                  required
                  styles={{ padding: getStyle('20px 0 0 0', '0'), width: '100%' }}
                  inputStyles={getStyle(liinilaevadInputStyles)}
                  labelContainerProps={{ display: 'none' }}
                  inputContainerProps={{ padding: 0 }}
                  itemsArr={allowedCountries}
                  itemTemplate={CompanyItemTemplate}
                  selectionTemplate={CompanyItemTemplate}
                />
              </UILayout>
            </UILayout>
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

CustomerInfo.propTypes = {
  fields: PropTypes.array.isRequired,
  editMode: PropTypes.bool,
  userDetails: PropTypes.object.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  allowedCountries: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired,
}

CustomerInfo.defaultProps = {
  editMode: false,
  formMode: FORM_MODE_PRIVATE,
}

CustomerInfo.contextTypes = {
  muiTheme: PropTypes.any,
}

export default CustomerInfo
