import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'

const ModalErrorText = ({ message, style }) => (
  <UILayout j-flex-center margin="10px 0" style={style}>
    <UIText color="#d0021b" align="center" size="18px">
      {message}
    </UIText>
  </UILayout>
)

ModalErrorText.propTypes = {
  message: PropTypes.string,
}

ModalErrorText.defaultProps = {
  message: '',
}

export default React.memo(ModalErrorText)
