import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { captureException } from '@sentry/browser'

import { UILayout, UIText } from 'bora-material-ui'

import { scrollUp } from './scrollUp'

import messages from '../../consts/messages'
import { translate } from './Translator'
import { isUnrecoverableError, knownErrors } from './known-errors'

const Warning = (props, { muiTheme, intl }) => {
  const { className, errorMessage, onlyError, style, errorDetails } = props
  const { code, id } = errorDetails

  let messageToShow = onlyError && id ? `(${translate(messages.warningErrorCodePrefix)} ${id})` : errorMessage

  if (errorMessage) {
    captureException(errorMessage)
    console.warn(errorMessage)
  }

  if (code && id) {
    captureException(messageToShow)
  }

  let withAction = false

  if (code in knownErrors) {
    messageToShow =
      intl.formatMessage(messages[knownErrors[code]]) +
      (id ? ` (${translate(messages.warningErrorCodePrefix)} ${id})` : '')

    if (isUnrecoverableError(code)) {
      withAction = true
    }
  } else if (errorMessage === 'INVALID_NOTIFICATION_STATUS') {
    messageToShow = intl.formatMessage(messages.paymentCCInvalidStatusError)
  } else {
    messageToShow =
      intl.formatMessage(messages.bookingProcessError) +
      (id ? ` (${translate(messages.warningErrorCodePrefix)} ${id})` : '')
  }

  const layoutStyle = {
    padding: '10px 20px',
    backgroundColor: muiTheme.warningBgColor,
    borderRadius: '3px',
    ...style,
  }

  return (
    <UILayout className={className} column flex-center width="auto" style={layoutStyle}>
      <UIText
        margin="0px 0px 10px 0px"
        textTransform="uppercase"
        size="18px"
        letterSpacing="0.5px"
        weight="bold"
        color={muiTheme.warningColor}
        translate={messages.warningTitle}
        font={muiTheme.secondFontFamilyDem}
      />
      {props.errorComponent ? (
        props.errorComponent
      ) : (
        <Fragment>
          <UIText
            margin="0px 0px 10px 0px"
            size="16px"
            color="#000000"
            translate={messages.paymentWarningInfoText}
            display-if={!onlyError}
          />
          <UIText display-if={!withAction} margin="0px 0px 10px 0px" size="16px" color="#000000">
            {messageToShow}
          </UIText>
          <UIText
            display-if={withAction}
            margin="0px auto 10px auto"
            size="16px"
            color="#000000"
            align="center"
            formattedMessage={messageToShow}
            onAction={() => (location.href = window.location.origin)}
          />
          <UIText
            margin="0px"
            size="16px"
            color="#000000"
            translate={messages.paymentWarningFinalText}
            display-if={!onlyError}
          />
        </Fragment>
      )}
    </UILayout>
  )
}

Warning.contextTypes = {
  muiTheme: PropTypes.object,
  intl: PropTypes.object,
}

Warning.defaultProps = {
  errorComponent: null,
  onlyError: false,
  style: {},
  errorDetails: {},
}

Warning.propTypes = {
  className: PropTypes.string,
  errorComponent: PropTypes.any,
  onlyError: PropTypes.bool,
  style: PropTypes.any,
  errorMessage: PropTypes.string.isRequired,
  errorDetails: PropTypes.object,
}

export default scrollUp(Warning)
