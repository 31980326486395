import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIIcon, UIText, UICheckbox } from 'bora-material-ui'
import messages from '../../../../../consts/messages'

const styles = {
  checkBoxStyle: { margin: 'auto' },
  contentWrapper: {
    padding: '8px',
    margin: '15px',
    width: '100%',
    borderBottom: '1px solid #bfc3c7',
    borderTop: '1px solid #bfc3c7',
  },
}
class VehicleHandicappedParam extends React.PureComponent {
  render() {
    const { onChange, value, disabled } = this.props
    return (
      <UILayout>
        <UILayout style={styles.contentWrapper}>
          <UILayout width="auto" padding="0px 10px 0px 0px">
            <UIIcon fill="#1fbcd2" height="34px" type="iconWheelchairAccess" />
          </UILayout>
          <UILayout width="auto">
            <UICheckbox checked={value} onCheck={onChange} style={styles.checkBoxStyle} disabled={disabled} />
          </UILayout>
          <UILayout width="auto">
            <UIText color="#79909b" translate={messages.handicappedDescription} />
          </UILayout>
        </UILayout>
      </UILayout>
    )
  }
}

VehicleHandicappedParam.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
}

VehicleHandicappedParam.defaultProps = {
  disabled: false,
  value: false,
}

export default VehicleHandicappedParam
