import React from 'react'
import R from 'ramda'
import { injectIntl } from 'react-intl'
import { UILayout, UIBottomSlider } from 'bora-material-ui'

import messages from '../../../../../consts/messages'
import ModalPassengerEditForm from './ModalPassengerEditForm'
import { editDataInReservation } from '../../../../../services/reservation/actions/reservation'
import { setReservationPassengerInfo, showModal } from '../../../../../actions'
import { connect } from 'react-redux'
import { getReservation } from '../../../../Ticket/EditTicket/editTicketSelectors'
import { modifyReservation } from '../../../../Ticket/EditTicket/editTicketSagas'
import { getFormSyncErrors } from 'redux-form'
import { birthDatesOptions, birthYearsOptions } from '../../../../Booking/forms/CustomerInfo/Passengers-Date.util'
import moment from 'moment'

const styles = {
  modalContent: { borderBottom: '1px solid #bfc3c7' },
  availableTickets: { padding: '0' },
  xs: {
    modalContent: { width: '100%' },
  },
}

export function getDateISO(birthday) {
  if (!birthday) {
    return moment().format('YYYY/MM/DD')
  }

  const date = birthday.date || 0
  const month = birthday.month || 0
  const year = birthday.year || 0

  const dateStr = birthDatesOptions[date].title ? birthDatesOptions[date].title.padStart(2, '0') : moment().format('DD')
  const monthStr = (month + 1).toString().padStart(2, 0)
  const yearStr = birthYearsOptions[year] ? birthYearsOptions[year].title : moment().format('YYYY')

  return `${yearStr}-${monthStr}-${dateStr}`
}

const ModalEditPassenger = (props) => {
  const { intl, areTicketsBeingEditingNow, modifyReservation, reservation, newData, showModal, citizenships } = props
  const saveHandler = () => {
    if (areTicketsBeingEditingNow) {
      const [newPassengerData] = newData.passengers
      const updatePassengerInfoModification = {
        __type: 'setGuestInfo',
        guestSeqN: newPassengerData.seqN,
        firstName: newPassengerData.firstName,
        lastName: newPassengerData.lastName,
        citizenship: citizenships.length ? citizenships[newPassengerData.citizenship].code : '',
        birthday: getDateISO(newPassengerData.birthday),
        gender: newPassengerData.gender,
        comments: newPassengerData.optionalInfo,
      }

      modifyReservation({
        reservationToken: reservation.token,
        modifications: [updatePassengerInfoModification],
      })

      showModal('')
    }
  }

  return (
    <div>
      <UILayout
        data-test="modal-edit-ticket-type"
        style={styles.modalContent}
        padding="0 0 20px 0"
        xs={{ width: '100%' }}
        column
      >
        <ModalPassengerEditForm />
      </UILayout>
      <UIBottomSlider
        isEditPopup={true}
        isPopup={true}
        isNextAvailable={Object.keys(props.formSyncErrors).length === 0}
        nextAction={saveHandler}
        prevAction={() => props.showModal('')}
        prevTitle={intl.formatMessage(messages.cancel)}
        nextTitle={intl.formatMessage(messages.applyChanges)}
        editMode
      />
    </div>
  )
}

function mapStateToProps(state) {
  const modal = R.pathOr(false, ['modal'])(state)
  const areTicketsBeingEditingNow = modal === 'passenger'
  return {
    citizenships: R.pathOr([], ['user', 'citizenships'])(state),
    newData: R.pathOr({}, ['form', 'editPassenger', 'values'])(state),
    reservation: getReservation(state),
    areTicketsBeingEditingNow,
    formSyncErrors: getFormSyncErrors('editPassenger')(state) || {},
  }
}

const mapDispatchToProps = {
  showModal,
  editDataInReservation,
  setReservationPassengerInfo,
  modifyReservation,
}

export default R.compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(ModalEditPassenger)
