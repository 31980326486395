import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout, UIButton, UIFormattedNumber } from 'bora-material-ui'

import messages from '../../../../consts/messages'

const STATUS_COLORS = {
  Paid: '#79909b',
  Unpaid: '#000000',
  Rest: '#f5a623',
}

const ReservationRow = ({ reservation, onClick }, { intl }) => (
  <UILayout
    onClick={() => onClick(reservation)}
    hoverStyles={{ opacity: 0.5 }}
    row
    center
    j-flex-space-between
    margin="17px 0px 0px 0px"
    padding="13px 0px"
    borderBottom="solid 1px #ececec"
  >
    <UILayout height="42px" j-flex-space-between column align="flex-start">
      <UIText fontSize="16px" color="rgba(0, 0, 0, 0.87)">
        {`${reservation.from} - ${reservation.to}`}
      </UIText>
      <UIText fontSize="14px" color="rgba(0, 0, 0, 0.54)">
        {`${reservation.date}, ${reservation.tickets.length} ${intl.formatMessage(messages.tickets)}`}
      </UIText>
    </UILayout>
    <UIButton width="60px" height="40px" background={STATUS_COLORS[reservation.status]}>
      <UILayout column j-flex-center height="100%">
        <UIText size="18px" color="white" weight="600" padding="0 5px">
          {reservation.status && !reservation.restCost ? (
            reservation.status
          ) : (
            <UIFormattedNumber value={-reservation.restCost} style="currency" currency="eur" />
          )}
        </UIText>
      </UILayout>
    </UIButton>
  </UILayout>
)

ReservationRow.propTypes = {
  reservation: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

ReservationRow.contextTypes = {
  intl: PropTypes.any,
}

export default ReservationRow
