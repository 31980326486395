import R from 'ramda'

function mergeInvitationsAndMembers(invitations = [], members = [], currentUserId) {
  const fromInvitations = invitations.map((invitation) => ({
    email: invitation.emailAddress,
    enabled: false,
    id: Number(invitation.personalIdentificationNumber),
    invited: true,
    isCurrentUser: false,
    manager: false,
    token: invitation.token,
  }))

  const fromMembers = members.map((member) => ({
    customerId: member.customer.customerId,
    email: member.customer.email,
    enabled: member.enabled,
    firstName: member.customer.firstName,
    id: member.customer.personalIdentificationNumber,
    invited: false,
    isCurrentUser: member.customer.customerId === currentUserId,
    lastName: member.customer.lastName,
    manager: member.manager,
    phone: member.customer.phone,
  }))

  const sortUsers = R.sortWith([
    R.descend(R.prop('isCurrentUser')),
    R.descend(R.prop('manager')),
    R.descend(R.prop('invited')),
    R.descend(R.prop('enabled')),
    R.ascend(R.prop('firstName')),
    R.ascend(R.prop('lastName')),
  ])

  return sortUsers([...fromInvitations, ...fromMembers])
}

export { mergeInvitationsAndMembers }
