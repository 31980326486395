import Loadable from 'react-loadable'

export const MyTicketEditMode = Loadable({
  loader: () => import('./containers/MyTicketEditMode'),
  loading: () => null,
})

export const EditedTicketPay = Loadable({
  loader: () => import('./containers/EditedTicketPay'),
  loading: () => null,
})
