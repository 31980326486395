import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { pathOr, compose, isEmpty } from 'ramda'
import { withRouter } from 'react-router-dom'

import { actions } from '../provider'
import { setCustomVehicleWeight } from '../../../services/reservation/actions/reservation'
import { modifyReservation } from '../../Ticket/EditTicket/editTicketSagas'
import messages from '../../../consts/messages'
import { Panel } from '../../../components/Panel'
import { UIBottomSlider, UILayout } from 'bora-material-ui'
import PropTypes from 'prop-types'
import { InputField } from '../../../formComponents'
import { reduxForm } from 'redux-form'
import { selectors } from '../../Booking/provider'
import ModalScrolledErrorText from '../../MyTicket/components/Modals/ModalScrolledErrorText'
import { getErrorDetails } from '../../../services/reservation/selectors'
import { variableToString } from '../../../utils/strings'

const errors = {
  FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY_BY_WEIGHT: messages.vehicleFailedToAllocateCustomWeight,
  CURB_WEIGHT_VIOLATION: messages.invalidWeightModal,
}

const minPossibleWeightToChange = 750

const inputStyles = {
  boxShadow: '0 0 20px #ccc',
  padding: '0',
}

const getModalErrorWhileEdit = pathOr(null, ['editTicket', 'modalError'])

const wrapperStyles = { padding: '0' }

const labelStyles = { display: 'none' }

const WeightInput = () => (
  <UILayout padding="20px 0" row center width="100%" column={false}>
    <InputField
      wrapperStyles={wrapperStyles}
      wrapperInputStyles={inputStyles}
      labelStyles={labelStyles}
      name="weight"
      type="number"
    />
  </UILayout>
)

const Form = reduxForm({
  form: 'custom-weight',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(WeightInput)

const CustomVehicleWeight = (props, { intl }) => {
  const {
    showModal,
    setCustomVehicleWeight,
    seqNum,
    sailRefId,
    customWeight,
    formerWeight,
    areTicketsBeingEditingNow,
    modifyReservation,
    customWeightFromForm,
    location,
    errorData = {},
    currentVehicles,
    vehiclesAfterPayedReservation,
    reservationToken,
  } = props

  const { pathname = '' } = location
  const fromConfirm = pathname.endsWith('/confirm')
  const { message = '', code } = errorData
  let validCurbWeightInKg = 0
  if (currentVehicles && currentVehicles.length) {
    validCurbWeightInKg = currentVehicles.find((item) => item.seqN === seqNum).curbWeightInKg
  } else if (vehiclesAfterPayedReservation && vehiclesAfterPayedReservation.length) {
    validCurbWeightInKg = vehiclesAfterPayedReservation.find((item) => item.seqN === seqNum).curbWeightInKg
  }
  const { CURB_WEIGHT_VIOLATION } = errors

  let errorMessage = errors[code] ? intl.formatMessage(errors[code]) : message
  if (code === variableToString({ CURB_WEIGHT_VIOLATION })) {
    errorMessage = `${errorMessage} ${validCurbWeightInKg} kg`
  }
  const weight = customWeight || formerWeight
  let isNextDisabled

  try {
    const newWeightNumber = parseFloat(customWeightFromForm)
    isNextDisabled = !customWeightFromForm || newWeightNumber < minPossibleWeightToChange
  } catch (e) {
    isNextDisabled = false
  }

  return (
    <Fragment>
      <Panel>
        <ModalScrolledErrorText display-if={message} message={errorMessage} />
        {intl.formatMessage(messages.addCustomVehicleWeightSubHeader)}
        <Form initialValues={{ weight }} />
      </Panel>
      <Panel>
        <UIBottomSlider
          isNextAvailable={!isNextDisabled}
          nextAction={() =>
            areTicketsBeingEditingNow && !fromConfirm
              ? modifyReservation({
                  reservationToken,
                  modifications: [
                    {
                      __type: 'setVehicleWeight',
                      vehicleSeqN: seqNum,
                      sailRefId,
                      weightInKg: customWeightFromForm || customWeight || formerWeight,
                    },
                  ],
                })
              : setCustomVehicleWeight({ seqNum, sailRefId })
          }
          prevAction={() => showModal('')}
          prevTitle={intl.formatMessage(messages.cancel)}
          nextTitle={intl.formatMessage(messages.applyChanges)}
          editMode
        />
      </Panel>
    </Fragment>
  )
}
CustomVehicleWeight.contextTypes = {
  intl: PropTypes.object,
}

const enhance = compose(
  connect(
    (state) => {
      const { modalData } = state
      const { modal } = state
      const customWeightFromForm = pathOr(null, ['form', 'custom-weight', 'values', 'weight'])(state)
      const currentVehicles = pathOr(null, ['reservation', 'current', 'vehicles'])(state)
      const vehiclesAfterPayedReservation = pathOr(null, ['reservation', 'edit', 'reservation', 'vehicles'])(state)
      const editReservation = selectors.getEditReservation(state)
      const reservationId = editReservation.reservationId || null
      const reservationToken = editReservation.token || null
      const areTicketsBeingEditingNow = modal === 'addCustomVehicleWeight'
      const { seqNum, sailRefId, customWeight, formerWeight } = modalData

      const errorCodeFromEdit = getModalErrorWhileEdit(state)
      const errorDataWhileEdit = errorCodeFromEdit ? { code: errorCodeFromEdit, message: 'Server error' } : {}
      const errorDataForTheCurrentReservation = getErrorDetails(state)
      const errorData = isEmpty(errorDataForTheCurrentReservation)
        ? errorDataWhileEdit
        : errorDataForTheCurrentReservation

      return {
        seqNum,
        sailRefId,
        customWeight,
        formerWeight,
        areTicketsBeingEditingNow,
        reservationId,
        reservationToken,
        customWeightFromForm,
        errorData,
        currentVehicles,
        vehiclesAfterPayedReservation,
      }
    },
    {
      ...actions,
      setCustomVehicleWeight,
      modifyReservation,
    }
  ),
  withRouter
)

export default enhance(CustomVehicleWeight)
