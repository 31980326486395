import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import { isLoggedInSelector } from '../../services/auth/reducers/selectors'

const ProtectedRoute = (props) =>
  props.protected && !props.isLoggedIn ? (
    <Route render={() => <Redirect to={`/${props.locale}/auth/login`} />} />
  ) : (
    <Route {...props} />
  )

ProtectedRoute.propTypes = {
  locale: PropTypes.string.isRequired,
  protected: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
}

ProtectedRoute.defaultProps = {
  protected: false,
  isLoggedIn: false,
}

const mapStateToProps = (state) => ({
  isLoggedIn: isLoggedInSelector(state),
  locale: state.locale,
})

export default connect(mapStateToProps, {})(ProtectedRoute)
