import React from 'react'
import PropTypes from 'prop-types'

import { UIText } from 'bora-material-ui'

import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'

const xsStyles = {
  size18: { size: '18px' },
}

class GeneralLabel extends React.PureComponent {
  render() {
    const { muiTheme } = this.context
    const { color, label, mui } = this.props

    return (
      <UIText
        font={muiTheme.secondFontFamily}
        letterSpacing={getStyle('-0.005em', '1px')}
        textTransform={getStyle('none', 'uppercase')}
        auto
        ellipsis
        size={muiTheme.fontSizeBigger}
        color={color}
        xs={xsStyles.size18}
        {...muiTheme.ids.generalLabelsStyle}
        {...mui}
      >
        {label}
      </UIText>
    )
  }
}

GeneralLabel.contextTypes = {
  muiTheme: PropTypes.object,
}

GeneralLabel.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
}

GeneralLabel.defaultProps = {
  color: 'inherit',
}

export default GeneralLabel
