import React from 'react'
import { UILayout } from 'bora-material-ui'

import { VehiclesForm } from '../../../Booking/forms/VehiclesForm'

const ModalEditCarInfo = () => (
  <UILayout
    data-test="modal-edit-cars-content"
    style={{ borderBottom: '1px solid #bfc3c7' }}
    padding="0 0 20px 0"
    width="430px"
    maxWidth="100%"
  >
    <VehiclesForm editMode />
  </UILayout>
)

export default ModalEditCarInfo
