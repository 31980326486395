import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { UILayout, UIText } from 'bora-material-ui'

import { Warning } from '../../../Common'

import messages from '../../../../consts/messages'

const ReservationError = (
  <Fragment>
    <UIText margin="0px 0px 10px 0px" size="16px" color="#000000" translate={messages.bookingProcessError} />
    <UIText margin="0px 0px 10px 0px" size="16px" color="#000000" translate={messages.reservationNotExist} />
    <UIText margin="0px" size="16px" color="#000000" translate={messages.solutionStart} />
  </Fragment>
)

export const PaymentResultsError = ({ reason = '' }, { muiTheme }) => (
  <Fragment>
    <UILayout center xs={0} height="84px" {...muiTheme.ids.mainInsideError} />
    <Warning errorMessage={reason} errorComponent={ReservationError} />
  </Fragment>
)

PaymentResultsError.contextTypes = {
  muiTheme: PropTypes.object,
}
