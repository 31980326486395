/* eslint-disable complexity */
import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, compose, not, or, and, equals, length, pathOr } from 'ramda'
import { UILayout } from 'bora-material-ui'
import { TicketTypeRow } from './'
import { DropDownField } from '../../../../../formComponents'
import messages from '../../../../../consts/messages'
import SelectedTicketsMapper from './SelectedTicketsMapper'
import SingleItem from './SingleItem'
import { translate } from '../../../../Common/Translator'
import { THEME } from 'bora-material-ui/themes'
import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'

const notEmpty = compose(not, isEmpty)

const styles = {
  padding0: { padding: '0' },
  dropDownField: { padding: '0', width: '100%' },
}

const xsStyles = {
  categorySelection: { row: true, 'flex-center': true },
  dropDownFieldWrapper: { margin: '0 15px', padding: '0' },
  dropDownField: { padding: '0', maxWidth: '100%' },
}
export default class IncDecDropDown extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    changeItemQtty: PropTypes.func.isRequired,
    selectedTicketsList: PropTypes.any.isRequired,
    maxValues: PropTypes.object,
    categoriesAvailableForSelection: PropTypes.any.isRequired,
    routeAddonsAvailableForSelection: PropTypes.array,
    shouldShowAddon: PropTypes.bool,
    editMode: PropTypes.bool,
    disabled: PropTypes.bool,
    hideZeroPrice: PropTypes.bool,
    isAddon: PropTypes.bool,
    handleDriverFromReserveCase: PropTypes.func,
  }

  static defaultProps = {
    editMode: false,
    hideZeroPrice: false,
    maxValues: {},
    isAddon: false,
    shouldShowAddon: true,
    handleDriverFromReserveCase: () => {},
    routeAddonsAvailableForSelection: [],
  }

  static contextTypes = {
    muiTheme: PropTypes.any,
  }

  handleAddItem = (value, isAddon = false) => {
    const {
      driverFromReservesConfiguration,
      isReservationForDriverFromReserve,
      setReservationFromReserve,
      changeItemQtty,
      handleDriverFromReserveCase,
    } = this.props

    const { driverForLocalVehicleFromAttribute, driverConfirmationShouldBeShown, localCompanyVehicleHasBeenAdded } =
      driverFromReservesConfiguration
    if (
      driverConfirmationShouldBeShown &&
      driverForLocalVehicleFromAttribute &&
      driverForLocalVehicleFromAttribute.code === value.code
    ) {
      if (!isReservationForDriverFromReserve) {
        setReservationFromReserve(true)
      }
      if (!localCompanyVehicleHasBeenAdded) {
        handleDriverFromReserveCase()
        return
      }
    }
    changeItemQtty({
      ...value,
      isAddon,
      count: 1,
      type: 'dropdown',
    })
  }

  render() {
    const { muiTheme = {} } = this.context
    const {
      selectedTicketsList,
      selectedAddonTickets,
      categoriesAvailableForSelection,
      routeAddonsAvailableForSelection,
      shouldShowAddon,
      changeItemQtty,
      maxValues,
      editMode,
      ticketsCount,
      fetchingModify,
      priceReady,
      hideZeroPrice,
      isAddon,
    } = this.props

    const categoriesAvailableForSelectionHasOnlyOneItem = equals(length(categoriesAvailableForSelection), 1)
    const categoriesAvailableForSelectionAreEmpty = equals(length(categoriesAvailableForSelection), 0)
    const selectedTicketsListHasOnlyOneItem = equals(length(selectedTicketsList), 1)
    const selectedTicketsIsNotResidents = or(
      equals(length(categoriesAvailableForSelection), 0),
      and(
        categoriesAvailableForSelectionHasOnlyOneItem,
        categoriesAvailableForSelection[0] && equals(categoriesAvailableForSelection[0].subType, 'DECK')
      )
    )
    let showSingleItem = or(
      categoriesAvailableForSelectionHasOnlyOneItem &&
        categoriesAvailableForSelection[0].subType &&
        categoriesAvailableForSelection[0].subType !== 'DECK' &&
        categoriesAvailableForSelection[0].subType !== 'CABIN',
      and(
        and(selectedTicketsIsNotResidents, categoriesAvailableForSelectionAreEmpty),
        selectedTicketsListHasOnlyOneItem
      )
    )
    if (equals(pathOr('', ['selectedTicketsList', 0, 'code'])(this.props), 'LOCAL_TRAVELLER')) {
      showSingleItem = false
    }
    if (
      isAddon &&
      routeAddonsAvailableForSelection.length === 1 &&
      !routeAddonsAvailableForSelection[0].mandatoryParameter
    ) {
      showSingleItem = true
    }
    // eslint-disable-next-line react/prop-types
    const itemTemplate = ({ value }) => {
      const { title, translationId, price, currency } = value
      return (
        <TicketTypeRow
          hideZeroPrice={hideZeroPrice}
          title={title || translate(translationId)}
          price={price}
          currencyCode={currency}
          fromDropDown={true}
        />
      )
    }

    const selectionTemplate = () => (
      <TicketTypeRow
        hideZeroPrice={hideZeroPrice}
        title={this.props.title}
        icon={
          muiTheme.name === THEME.SAAREMAA && this.props.icon === 'iconPassenger' ? '' : getStyle('', this.props.icon)
        }
        price={null}
        isSection
        editMode={editMode}
      />
    )

    if (!shouldShowAddon) {
      return null
    }

    if (showSingleItem) {
      let singleItem = or(selectedTicketsList[0], categoriesAvailableForSelection[0])
      if (isAddon && routeAddonsAvailableForSelection[0]) {
        singleItem = { ...routeAddonsAvailableForSelection[0] }
      }

      const getMaxValue = (value) => {
        if (typeof value === 'number') {
          return value
        }

        return 1000
      }

      const maxValue = getMaxValue(maxValues[singleItem.inventoryClass])

      return (
        <SingleItem
          disabled={fetchingModify && !editMode}
          icon={this.props.icon}
          isAddon={isAddon}
          changeItemQtty={changeItemQtty}
          ticket={singleItem}
          maxValue={maxValue}
          editMode={editMode}
        />
      )
    }

    const disabledSelection = (!priceReady && !editMode) || (isAddon && notEmpty(selectedAddonTickets))
    let itemsArr = []
    if (!disabledSelection) {
      itemsArr = isAddon ? routeAddonsAvailableForSelection : categoriesAvailableForSelection
    }

    const liinilaevadInputStyles = {
      boxShadow: 'none',
      background: '#E2EEF8',
      borderRadius: '12px',
      paddingTop: '5px',
    }

    return (
      <UILayout data-test="passengers" column center padding="20px 0 0 0" {...muiTheme.ids.IncDecDropDownWrapper}>
        <UILayout
          data-test="category-selection"
          margin="0 0 10px 0"
          sml={xsStyles.categorySelection}
          opacity={disabledSelection ? 0.4 : 1}
          {...(muiTheme.ids ? muiTheme.ids.categorySelection : {})}
        >
          <UILayout sml={xsStyles.dropDownFieldWrapper} {...(muiTheme.ids ? muiTheme.ids.DropDownFieldWrapper : {})}>
            <DropDownField
              onlySelect
              itemsArr={itemsArr}
              inputContainerProps={styles.padding0}
              styles={styles.dropDownField}
              inputStyles={getStyle(liinilaevadInputStyles, {
                ...(muiTheme.ids ? muiTheme.ids.SelectedTicketTypeRowWrapper : {}),
                ...(muiTheme.ids ? muiTheme.ids.IncDecDropDown : {}),
              })}
              label={translate(messages.passengerTicket.id)}
              select={(value) => this.handleAddItem(value, isAddon)}
              itemTemplate={itemTemplate}
              selectionTemplate={selectionTemplate}
              sml={xsStyles.dropDownField}
              disabled={isAddon && notEmpty(selectedAddonTickets)}
            />
          </UILayout>
        </UILayout>

        <SelectedTicketsMapper
          disabled={(fetchingModify && !editMode) || isAddon}
          tickets={isAddon ? selectedAddonTickets : selectedTicketsList}
          changeItemQtty={changeItemQtty}
          maxValues={maxValues}
          display-if={notEmpty(selectedTicketsList) || notEmpty(selectedAddonTickets)}
          editMode={editMode}
          ticketsCount={ticketsCount}
        />
      </UILayout>
    )
  }
}
