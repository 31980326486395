import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout, UIIcon } from 'bora-material-ui'

import messages from '../../../../consts/messages'

const RouteInfo = () => (
  <UILayout column padding="0px 0px 10px 0">
    <UILayout column>
      <UIText size="16px" color="#000" align="left" translate={messages.departureArrivalDates} />
      <UIText size="16px" color="#000" align="left">
        2017
      </UIText>
      <UIText size="34px" color="#000" align="left" width="auto" margin="10px 0px 20px 0px">
        Mon, Apr 24, 12:40
      </UIText>
    </UILayout>

    <UILayout column style={{ position: 'relative' }}>
      <UILayout
        bgColor="#fff"
        border="solid 1px #e5e5e4"
        padding="0 21px 0 30px"
        height="40px"
        margin="0 0 10px 0"
        center
        j-flex-space-between
      >
        <UIText color="#000" size="18px">
          Tallinn
        </UIText>
        <UIText color="#000" size="18px">
          12:40
        </UIText>
      </UILayout>

      <UILayout
        bgColor="#fff"
        border="solid 1px #e5e5e4"
        height="40px"
        padding="0 21px 0 30px"
        margin="0 0 10px 0"
        center
        j-flex-space-between
      >
        <UIText color="#000" size="18px">
          Stockholm
        </UIText>
        <UIText color="#000" size="18px">
          13:40
        </UIText>
      </UILayout>

      <UILayout column style={{ position: 'absolute', top: '18px', left: '16px' }}>
        <UILayout width="5px" height="4px" borderRadius="50%" bgColor="#26aa0a">
          &nbsp;
        </UILayout>
        <UILayout width="1px" height="48px" bgColor="#e5e5e4" margin="0 0 0 1.5px">
          &nbsp;
        </UILayout>
        <UILayout width="5px" height="4px" borderRadius="50%" bgColor="#f33001">
          &nbsp;
        </UILayout>
      </UILayout>

      <UILayout padding="10px 0px 0px 0px">
        <UIIcon type="iconFood" width="48px" height="48px" />
        <UIIcon type="iconTransfer" width="48px" height="48px" />
        <UIIcon type="iconWifi" width="48px" height="48px" />
      </UILayout>
    </UILayout>
  </UILayout>
)

RouteInfo.propTypes = {}

RouteInfo.contextTypes = {
  intl: PropTypes.any,
}

RouteInfo.defaultProps = {}

export default RouteInfo
