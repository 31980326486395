import React, { Fragment } from 'react'
import { UIBottomSlider } from 'bora-material-ui'
import PropTypes from 'prop-types'
import messages from '../../../consts/messages'
import { connect } from 'react-redux'
import { Panel } from '../../../components/Panel'
import { getBookingConditionsForRoute, getCurrentReservation } from '../../../services/reservation/selectors'
import { showModal } from '../../../actions'
import { finishEditReservation } from '../../../services/reservation/actions/reservation'
import { getReservation } from '../../Ticket/EditTicket/editTicketSelectors'
import { isLaaksaareSail } from '../../../utils/laaksaare'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const DifferentLicensePlatesOnRoundTrip = ({ showModal, reservation, isLaakSail, finishEditReservation }, { intl }) => {
  const handlePrevClick = () => {
    showModal('')
    window.scrollTo(0, 0)
  }

  const getDifferenceToPay = (isLaak = false) => {
    if (isLaak) return 0

    if (!reservation) {
      return 0
    }

    const {
      paymentInfo: { toPayAmount = 0, toRefundAmount = 0 },
    } = reservation

    return toPayAmount - toRefundAmount
  }

  const handleNextClick = () => {
    showModal('')
    finishEditReservation({
      reservation,
      reservationToken: reservation.token,
      reservationId: reservation.reservationId,
      differenceToPay: getDifferenceToPay(isLaakSail),
    })
  }

  return (
    <Fragment>
      <Panel
        style={getStyle({
          color: '#0000A0',
          fontWeight: 700,
          maxWidth: 500,
          padding: '24px 24px 0 24px',
          fontSize: 18,
        })}
      >
        {intl.formatMessage(messages.vehicleDifferentLicensePlatesConfirm)}
      </Panel>
      <UIBottomSlider
        isPopup={true}
        isNextAvailable={true}
        nextAction={handleNextClick}
        prevAction={handlePrevClick}
        prevTitle={intl.formatMessage(messages.vehicleDifferentLicensePlatesConfirmNo)}
        nextTitle={intl.formatMessage(messages.vehicleDifferentLicensePlatesConfirmYes)}
        editMode
      />
    </Fragment>
  )
}

DifferentLicensePlatesOnRoundTrip.contextTypes = {
  intl: PropTypes.object,
}

DifferentLicensePlatesOnRoundTrip.propTypes = {
  finishEditReservation: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
}
const mapDispatchToProps = { finishEditReservation, showModal }
export default connect(
  (state) => ({
    currentReservation: getCurrentReservation(state),
    reservation: getReservation(state),
    isLaakSail: getReservation(state)
      ? isLaaksaareSail(getReservation(state).sailPackages) && !getBookingConditionsForRoute(state)
      : false,
  }),
  mapDispatchToProps
)(DifferentLicensePlatesOnRoundTrip)
