import React from 'react'
import styles from './VehicleNotAddedWarning.module.scss'
import PropTypes from 'prop-types'
import { translate } from '../../Common/Translator'
import messages from '../../../consts/messages'

export const VehicleNotAddedWarning = ({ formsAreEmpty = true, shouldNotShowWarning = true }) => {
  if (formsAreEmpty || shouldNotShowWarning) return null

  return (
    <div className={styles.wrapper}>
      <strong>{translate(messages.editTicketCheckVehicleAdded)}</strong>
    </div>
  )
}

VehicleNotAddedWarning.propTypes = {
  formsAreEmpty: PropTypes.bool.isRequired,
  shouldNotShowWarning: PropTypes.bool.isRequired,
}

export default VehicleNotAddedWarning
