import { createAction } from 'redux-actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getCompanyId, getSelectedCompanyMember } from '../CompanyMembers'
import { deactivateCompanyMember as deactivateCompanyMemberService } from '../../../services/user/api/company'
import { goToPage } from '../../../actions'

const deactivateCompanyMember = createAction('DEACTIVATE_COMPANY_MEMBER:DEACTIVATE_COMPANY_MEMBER')
const deactivateCompanyMemberSuccess = createAction('DEACTIVATE_COMPANY_MEMBER:DEACTIVATE_COMPANY_MEMBER_SUCCESS')

function* deactivateCompanyMemberHandler() {
  const companyId = yield select(getCompanyId)
  const companyMember = yield select(getSelectedCompanyMember)

  try {
    yield call(deactivateCompanyMemberService, companyId, companyMember.customerId)
    yield put(deactivateCompanyMemberSuccess())
    yield put(goToPage('/profile/users'))
  } catch (error) {
    console.error('Cannot deactivate company member', error)
  }
}

function* watchDeactivateCompanyMember() {
  yield takeEvery(deactivateCompanyMember, deactivateCompanyMemberHandler)
}

export { deactivateCompanyMember, deactivateCompanyMemberSuccess, watchDeactivateCompanyMember }
