import { call, put, takeEvery } from 'redux-saga/effects'
import { createAction } from 'redux-actions'
import { goToPage } from '../../../actions'
import { createCorporateAccountService } from '../../../services/user/api/company'

const createCorporateAccount = createAction('CREATE_CORPORATE_ACCOUNT')
const createCorporateAccountFailed = createAction('CREATE_CORPORATE_ACCOUNT_FAILED')
const createCorporateAccountSuccess = createAction('CREATE_CORPORATE_ACCOUNT_SUCCESS')

function* createCorporateAccountHandler({ payload: formValues }) {
  try {
    const response = yield call(createCorporateAccountService, formValues)
    yield put(createCorporateAccountSuccess(response))
  } catch (error) {
    yield put(createCorporateAccountFailed())
  }
}

function* createCorporateAccountFailedHandler() {
  yield put(goToPage('/'))
}

function* createCorporateAccountSuccessHandler() {
  yield put(goToPage('/'))
}

function* watchCreateCorporateAccount() {
  yield takeEvery(createCorporateAccount, createCorporateAccountHandler)
}

function* watchCreateCorporateAccountSuccess() {
  yield takeEvery(createCorporateAccountSuccess, createCorporateAccountSuccessHandler)
}

function* watchCreateCorporateAccountFailed() {
  yield takeEvery(createCorporateAccountFailed, createCorporateAccountFailedHandler)
}

export {
  createCorporateAccount,
  watchCreateCorporateAccount,
  watchCreateCorporateAccountSuccess,
  watchCreateCorporateAccountFailed,
}
