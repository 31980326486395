import { compose, head, prop } from 'ramda'

import messages from '../../../../consts/messages'

const takeFirstValue = compose(prop('value'), head)

export const WIDTH_BUTTONS = [
  { title: 'less than 2m', translation: messages.carWidthS, value: 190 },
  { title: 'from 2m to 2.5m', translation: messages.carWidthM, value: 230 },
  { title: 'more than 2.5m', translation: messages.carWidthL, value: 251 },
]

export const WIDTH_BUTTONS_SVI = [
  { title: 'less than 2m', translation: messages.carWidthS, value: 190 },
  { title: 'from 2m to 2.5m', translation: messages.carWidthMSVI, value: 230 },
  { title: 'more than 2.5m', translation: messages.carWidthLSVI, value: 261 },
]

export const HEIGHT_BUTTONS = [
  { title: 'less than 4m', translation: messages.carHeightS, value: 300 },
  { title: 'more than 4m', translation: messages.carHeightL, value: 500 },
]

export const WEIGHT_BUTTONS = [
  { title: ' < 1t', value: 9000 },
  { title: '10t', value: 10000 },
  { title: '20t', value: 20000 },
  { title: '30t', value: 30000 },
  { title: '40t', value: 40000 },
  { title: '50t', value: 50000 },
]

export const WEIGHT_RANGE = {
  MIN: { title: '2t', value: 2000 },
  MAX: { title: '70t', value: 70000 },
}

export const TOTAL_LENGTH_BUTTONS = [
  { title: '5m', value: 500 },
  { title: '10m', value: 1000 },
  { title: '20m', value: 2000 },
  { title: '30m', value: 3000 },
  { title: '40m', value: 4000 },
]

export const LENGTH_RANGE = {
  MIN: { title: '5m', value: 500 },
  MAX: { title: '30m', value: 3000 },
}

export default {
  widthInCm: takeFirstValue(WIDTH_BUTTONS),
  heightInCm: takeFirstValue(HEIGHT_BUTTONS),
  lengthInCm: takeFirstValue(TOTAL_LENGTH_BUTTONS),
  weightInKg: WEIGHT_RANGE.MIN.value,
}
