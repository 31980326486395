import React from 'react'
import PropTypes from 'prop-types'
import { path, nth, compose, defaultTo, findIndex } from 'ramda'
import { withRouter } from 'react-router-dom'

import { UIStepper } from 'bora-material-ui'

const getPathByPath = path(['props', 'path'])
const getPathByIndex = (index) => compose(getPathByPath, nth(index))

export const childrenPathProps = (children) => [].concat(children).map(getPathByPath)
export const hasChildrenWithPathByIndex = (children, index) => compose(getPathByIndex(index), defaultTo([]))(children)

export const BuyProgressRouter = (props, { muiTheme }) => {
  const { activeStep, children, match, history, error } = props

  const click = (ind) => {
    if (hasChildrenWithPathByIndex(children, ind)) {
      const pathToGo = getPathByIndex(ind)(children)
      history.push(pathToGo)
    }
  }
  const paths = childrenPathProps(children)
  const activePathIndex = findIndex((path) => match.url.includes(path))(paths)
  const activeStepValue = activePathIndex === -1 ? activeStep : activePathIndex

  return (
    <UIStepper
      activeStep={activeStepValue}
      minStep={0}
      click={click}
      strokeColor="#D8D8D8"
      error={error}
      {...muiTheme.ids.BuyProgressRouter}
    >
      {children}
    </UIStepper>
  )
}

BuyProgressRouter.contextTypes = {
  muiTheme: PropTypes.object,
  history: PropTypes.object,
}

BuyProgressRouter.propTypes = {
  activeStep: PropTypes.number.isRequired,
  children: PropTypes.any.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  error: PropTypes.any,
}

BuyProgressRouter.defaultProps = {
  error: false,
}

export default withRouter(BuyProgressRouter)
