import { combineActions, handleActions } from 'redux-actions'
import {
  activateCompanyMember,
  clearCompanyMemberReservations,
  clearSelectedCompanyMember,
  deactivateCompanyMember,
  fetchCompanyMemberReservations,
  fetchCompanyMemberReservationsFail,
  fetchCompanyMemberReservationsSuccess,
  inviteCompanyMember,
  removeCompanyMember,
  selectCompanyMember,
  updateCompanyMembers,
} from './companyMembersSaga'

const initialState = {
  companyId: undefined,
  companyName: undefined,
  companyMembers: [],
  selectedCompanyMember: undefined,
  reservations: {
    loading: false,
    items: [],
  },
}

const updateSelectedCompanyMember = combineActions(
  selectCompanyMember,
  activateCompanyMember,
  deactivateCompanyMember,
  inviteCompanyMember,
  removeCompanyMember
)

const companyMembersReducer = handleActions(
  {
    [updateCompanyMembers.toString()](state, action) {
      const { companyId, companyName, companyMembers } = action.payload

      return {
        ...state,
        companyId,
        companyName,
        companyMembers,
      }
    },

    [updateSelectedCompanyMember.toString()](state, action) {
      const { payload: selectedCompanyMember } = action

      return {
        ...state,
        selectedCompanyMember,
      }
    },

    [clearSelectedCompanyMember.toString()](state) {
      return {
        ...state,
        selectedCompanyMember: initialState.selectedCompanyMember,
      }
    },

    [fetchCompanyMemberReservations.toString()](state) {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          loading: true,
        },
      }
    },

    [fetchCompanyMemberReservationsSuccess.toString()](state, action) {
      const { payload: reservations } = action

      return {
        ...state,
        reservations: {
          loading: false,
          items: reservations,
        },
      }
    },

    [fetchCompanyMemberReservationsFail.toString()](state) {
      return {
        ...state,
        reservations: {
          ...state.reservations,
          loading: false,
        },
      }
    },

    [clearCompanyMemberReservations.toString()](state) {
      return {
        ...state,
        reservations: initialState.reservations,
      }
    },
  },
  initialState
)

export { companyMembersReducer }
