import React, { PureComponent } from 'react'
import { CompanyUserSelector } from '../../Profile/components/Users'
import { CompanyMemberCard } from './components/CompanyMemberCard'
import { CompanyMemberCardInvite } from './components/CompanyMemberCardInvite'
import s from './CompanyMembersView.module.scss'

class CompanyMembersView extends PureComponent {
  render() {
    const { companyId, companyMembers, inviteCompanyMember, viewCompanyMemberTickets } = this.props

    return (
      <div className={s.root}>
        <CompanyUserSelector
          companies={this.getCreditCompanies()}
          companyId={companyId}
          creditInfo={this.getCreditInfo()}
          onCompanySelect={this.handleCompanySelect}
        />

        <div className={s.list} display-if={this.isCompanySelected()}>
          {companyMembers.map((companyMember) => (
            <CompanyMemberCard
              companyMember={companyMember}
              onRemove={this.createRemoveHandler(companyMember)}
              onResendInvite={this.props.resendInviteCompanyMember}
              onViewTickets={viewCompanyMemberTickets}
              key={companyMember.id}
              onCardAction={this.handleCardAction(companyMember)}
            />
          ))}
          <CompanyMemberCardInvite inviteCompanyMember={inviteCompanyMember} />
        </div>
      </div>
    )
  }

  handleCardAction(companyMember) {
    const { activateCompanyMember, deactivateCompanyMember, editCompanyMember } = this.props

    if (companyMember.isCurrentUser) {
      return () => editCompanyMember()
    }

    if (companyMember.enabled) {
      return () => deactivateCompanyMember(companyMember)
    }

    return () => activateCompanyMember(companyMember)
  }

  createRemoveHandler = (companyMember) => () => {
    const { companyId, removeCompanyMember, cancelInviteCompanyMember } = this.props
    const { invited, token } = companyMember

    if (invited) {
      cancelInviteCompanyMember({ companyId, token })
    } else {
      removeCompanyMember(companyMember)
    }
  }

  handleCompanySelect = ({ companyId } = {}) => {
    const { fetchCompanyMembers, onCompanySelect } = this.props

    fetchCompanyMembers(companyId)
    onCompanySelect(companyId)
  }

  getCreditCompanies() {
    const { companies } = this.props

    // Beware of companies with zero limit, it can be credit!
    return companies.filter((c) => typeof c.monthlyCreditLimit !== 'undefined')
  }

  getCreditInfo() {
    const { userCompany } = this.props
    const empty = {
      balance: '',
      limit: '',
    }

    if (!userCompany) {
      return empty
    }

    const {
      monthlyCreditLimitBalanceFormatted,
      monthlyCreditLimitFormatted,
      monthlyCreditLimitBalance,
      monthlyCreditLimit,
    } = userCompany

    if (!(monthlyCreditLimitBalanceFormatted && monthlyCreditLimitFormatted)) {
      return empty
    }

    return {
      balanceFormatted: monthlyCreditLimitBalanceFormatted,
      limitFormatted: monthlyCreditLimitFormatted,
      balance: monthlyCreditLimitBalance,
      limit: monthlyCreditLimit,
    }
  }

  isCompanySelected() {
    const { userCompany } = this.props

    return Boolean(userCompany)
  }
}

export { CompanyMembersView }
