import { reservation } from '../../services'
import { showModal, extendReservation } from '../../actions'
import { sendCreditCardPayment } from '../../services/payment/actions/pay'

export const actions = {
  showModal,
  extendReservation,
  sendCreditCardPayment,
}

const { getEditReservation } = reservation.selectors

export const selectors = {
  getEditReservation,
}
