import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { UIButton, UIIcon } from 'bora-material-ui'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Text = styled.div`
  color: #79909b;
  font-family: GinesoNormDem, Fjalla One, sans-serif;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  text-transform: uppercase;
`

const buttonStyle = {
  height: '50px',
  type: 'circle',
  width: '50px',
}

const iconStyle = {
  height: '16px',
  width: '22px',
}

class ActionButton extends PureComponent {
  static defaultProps = {
    buttonType: 'button',
    disabled: false,
  }

  render() {
    const { backgroundColor, border, buttonType, disabled, iconFill, iconType, onClick: handleClick, text } = this.props

    return (
      <Wrapper>
        <ButtonWrapper>
          <UIButton
            background={backgroundColor}
            border={border}
            buttonType={buttonType}
            click={handleClick}
            disabled={disabled}
            fill={iconFill}
            {...buttonStyle}
          >
            <UIIcon type={iconType} {...iconStyle} />
          </UIButton>
        </ButtonWrapper>

        <Text>{text}</Text>
      </Wrapper>
    )
  }
}

export { ActionButton }
