import {
  groupBy,
  reduce,
  path,
  compose,
  pathOr,
  find,
  propOr,
  propEq,
  map,
  filter,
  defaultTo,
  objOf,
  head,
  prop,
  or,
} from 'ramda'
import { createSelector } from 'reselect'
import { getFormValues } from 'redux-form'

import { notEmpty } from '../../utils/ramda-extend'
import INITIAL_CUSTOM_VEHICLE_PARAMS from './forms/VehiclesForm/customVehicleParams'

import {
  toggleDirection,
  goToPage,
  addReturnRoute,
  backToPreviousPage,
  goToRefund,
  goToEditReservation,
  setViewMode,
} from '../../actions'
import { routes as routesServices, schedule, inventory, payment, pricing, reservation, auth } from '../../services'
import {
  getSailDates,
  setSailDates,
  setSecondSailDate,
  setFirstSailDate,
  getSailDate,
  changeVehicleData,
  getEditableTrip,
  startEditTrip,
  endEditTrip,
  removeTripPackage,
  getCustomerRole,
  selectCustomerRole,
  resetCustomerRole,
  dropReverseSailPackage,
  getLocale,
  getSelectedSailPackages,
  getFirstSelectedSailPackage,
  getSelectedSailPackageCodeFromProps,
} from '../../services/user-selections'
import { userDetailsSelector, getCompaniesSelector } from '../../services/user/selectors'
import { fetchUserCustomerInfo } from '../../services/customer/actions'
import { selectedSailBySailPackageCodeWhileEdit } from '../../services/schedule/reducers'
import { customerSelector } from '../../services/customer/selectors'

const { routes, sailPackages } = routesServices

const { getToken, isLoggedInSelector, getChallengeID } = auth.selectors
const { getAllSailPackages, getAllSailPackagesList } = sailPackages.selectors

const getRouteLegFromSailPackage = compose(
  head,
  map((routeLeg) => ({ title: routeLeg })),
  map(prop('routeLeg')),
  prop('sailRefs')
)

const { prepareForPayment, pay, creditCardPaymentComplete } = payment.actions
const { selectNextRoute } = routes.actions
const { fetchAvailableDates, clearSailSelection, setSelectedSail } = schedule.actions
const {
  confirm,
  warnNoPassengersInReservation,
  reservation: {
    getReservation,
    manageUrlReservation,
    refund,
    startEditReservation,
    cancelEditReservation,
    finishEditReservation,
    setRefundReservation,
  },
} = reservation.actions

export const actions = {
  goToEditReservation,
  goToRefund,
  backToPreviousPage,
  dropReverseSailPackage,
  addReturnRoute,
  clearSailSelection,
  setSelectedSail,
  toggleDirection,
  fetchAvailableDates,
  selectNextRoute,
  prepareForPayment,
  creditCardPaymentComplete,
  pay,
  ...pricing.actions,
  ...inventory.actions,
  confirm,
  warnNoPassengersInReservation,
  setSailDates,
  setSecondSailDate,
  setFirstSailDate,
  startEditTrip,
  endEditTrip,
  changeVehicleData,
  manageUrlReservation,
  getReservation,
  removeTripPackage,
  goToPage,
  selectCustomerRole,
  resetCustomerRole,
  fetchUserCustomerInfo,
  refund,
  startEditReservation,
  cancelEditReservation,
  finishEditReservation,
  setRefundReservation,
  setViewMode,
}

const { getInventoryState } = inventory.selectors
const {
  getReservation: getReservationSelector,
  getCurrentReservation,
  getOriginalReservation,
  isFetching: getReservationFetching,
  getPayedReservation,
  getTotalPrice,
  getCurrency,
  vehiclesForConfirm,
  getFirstVehicleFromReservation,
  getFirstTrailerFromReservation,
  getFirstVehicleItem,
  getFirstTrailerItem,
  filteredGuestsForItems,
  findIdNums,
  getReservationIdFromUrl,
  getRefundReservation,
  getEditReservation,
  getEditReservationMode,
  paymentMethodsSelector,
  getItems,
  getCurrentReservationId,
  areTherePassengersInReservation,
} = reservation.selectors

const {
  getCategoriesListWithPrices,
  getPrices,
  getVehiclePrices,
  getSortedVehicleCategoriesListWithPrices,
  areVehiclesAvailable,
  areBicyclesAvailable,
  areAddonBicyclesAvailable,
  areAddonsAvailable,
  getAddonCategoriesListWithPrices,
  getRouteAddonsParameters,
  getTrailerCategoriesListWithPrices,
  areTrailersAvailable,
  areVehiclesActive,
  areTrailersActive,
  ticketsIncDecSelectorParameters,
  getLocalTravellers,
  getResidentPriceCategoryTitles,
} = pricing.selectors
const { getRouteTerms, getNextRoutes, getRouteCoefficientsMap, getRouteNoRefundIfCancelled } = routes.selectors
const {
  sailInventories,
  selectScheduleState,
  getSailsAvailableDates,
  availableInventoriesClassesBySailPackageCodeAndSailRefId,
  vesselFeaturesBySailPackageCodeAndSailRefId,
  selectedSailBySailPackageCode,
  sailInventoriesBySailPackageCode,
  selectedSails,
  selectedSailsWhileEdit,
  selectedSailsDefault,
  getCancelledSailRefsList,
  getSelectedSailRefIdList,
  getSelectedSailCodeByRefId,
  getAvailableReplacementLegs,
} = schedule.selectors

const getTicketsIsFetching = createSelector(
  (state) => state.schedule.ticketsIsFetching,
  (ticketsIsFetching) => ticketsIsFetching
)

const inventoryClasses = groupBy(({ inventoryClass, subType }) => {
  if (subType === 'PASSENGER') return 'Passenger'
  if (subType === 'VEHICLE' && inventoryClass === 'CAR') return 'Car'
  if (subType === 'VEHICLE' && inventoryClass === 'CAR_DECK') return 'Car'
  if (subType === 'VEHICLE' && inventoryClass === 'BICYCLE') return 'Bike'
  return inventoryClass
})

const getReserves = (inventoryClass) => {
  if ((inventoryClass.subType === 'PASSENGER' || inventoryClass.subType === 'VEHICLE') && inventoryClass.reserves) {
    return { ...inventoryClass.reserves[0], show: true }
  }
  return { show: false }
}

const ticketTransform = (sailInventory) => {
  const inventories = pathOr([], ['inventory', 'inventories'])(sailInventory)
  const availableInventoryClasses = inventoryClasses(inventories.availableInventoryClasses || [])

  const tickets = Object.keys(availableInventoryClasses).map((type) => {
    const ticketsForAvailableInventoryClass = availableInventoryClasses[type].reduce(
      (ticket, inventoryClass) => ({
        type,
        availabilityMax: ticket.availabilityMax + inventoryClass.total,
        availability: ticket.availability + inventoryClass.amount,
        reserves: getReserves(inventoryClass),
      }),
      {
        type: '',
        availabilityMax: 0,
        availability: 0,
      }
    )

    return ticketsForAvailableInventoryClass
  })

  return {
    availabilityTickets: tickets,
    sail: {
      ...sailInventory.sail,
      vesselType: inventories.vesselType,
      vesselFeatures: inventories.vesselFeatures,
    },
    vessel: inventories.vessel,
    inventoryEnabled: pathOr(false, ['inventory', 'inventories', 'enabled'])(sailInventory),
  }
}

const addOutOfStockFlag =
  (inventoryEnabled) =>
  ({ availability, reserves, ...rest }) => ({
    ...rest,
    reserves,
    availability: inventoryEnabled ? availability : 0,
    outOfStock: reserves.show
      ? !inventoryEnabled || (availability === 0 && reserves.soldOut)
      : !inventoryEnabled || availability === 0,
  })

const extendWithOutOfStock = (sailInventory) => ({
  ...sailInventory,
  availabilityTickets: sailInventory.availabilityTickets.map(addOutOfStockFlag(sailInventory.inventoryEnabled)),
})

const availableForSales = (sailInventory) =>
  path(['inventory', 'inventories', 'availableInventoryClasses'], sailInventory)
/* and(
  path(['inventory', 'inventories', 'availableInventoryClasses'], sailInventory),
  path(['sail', 'status'], sailInventory) === 'OPEN' || path(['sail', 'status'], sailInventory) === 'CLOSED' || path(['sail', 'status'], sailInventory) === 'CANCELLED'
) */

export const transformSailInventoriesToTickets = compose(
  map(extendWithOutOfStock),
  map(ticketTransform),
  filter(availableForSales),
  defaultTo([])
)

export const getTrips = (reservation = {}, prices = {}, sailPackages = {}) => {
  const { items: reservationItems = [], sailPackages: reservationSailPackages = [] } = reservation || {}

  const setPriceCategoryTitle = (sailRefId) => (item) => {
    const priceCode = propOr('', 'priceCategory', item)
    const pricesForInventoryItem = item.type === 'PASSENGER' ? 'prices' : 'carDeckPrices'
    const title = pathOr('', [sailRefId, pricesForInventoryItem, priceCode, 'priceCategory', 'title'], prices)
    const currency = pathOr('', [sailRefId, pricesForInventoryItem, priceCode, 'currency'], prices)

    return { ...item, title, currency }
  }

  const trips =
    (items) =>
    (accum, sailPackage = {}) => {
      const sailRef = pathOr({}, ['sailRefs', [0]], sailPackage)

      const getSailPackageItems = compose(
        map(setPriceCategoryTitle(sailRef.sailRefId)),
        filter(propEq('sailPackageSeqN', sailPackage.seqN))
      )

      let leg = { title: '' }

      if (notEmpty(sailPackages)) {
        const legs = pathOr('', [sailPackage.code, 'route', 'legs'], sailPackages)
        leg = find(propEq('code', sailRef.routeLeg), legs)
      } else {
        leg = getRouteLegFromSailPackage(sailPackage)
      }

      const sailPackageItems = getSailPackageItems(items)

      const trip = { ...sailPackage, leg, items: sailPackageItems }

      trip.guests = filteredGuestsForItems(reservation.guests)(trip.items)

      accum.push(trip)

      return accum
    }

  const combineTrips = reduce(trips(reservationItems), [])

  return combineTrips(reservationSailPackages)
}

const tripsFromReservation = (state) => (reservation) =>
  createSelector([() => reservation, getPrices, getAllSailPackages], getTrips)(state)

const getPaymentError = createSelector(
  (state) => state.payment.status,
  (state) => state.payment.error,
  (status, error) => status === 'error' && error.message
)

const customVehicleParams = compose(defaultTo({}), getFormValues('vehiclesForm'))
const getCustomVehicleParams = createSelector(
  customVehicleParams,
  pathOr(INITIAL_CUSTOM_VEHICLE_PARAMS, ['customVehicleParams'])
)

const getInitialVehiclesFormValues = createSelector(getCustomVehicleParams, objOf('customVehicleParams'))

const getSelectedSailPackageIndexToEdit = createSelector(propOr(0, 'selectedSailPackageIndexToEdit'), (index) =>
  Number.isInteger(index) ? index : 0
)

const getSelectedPackageCode = () =>
  createSelector(getSelectedSailPackages, getSelectedSailPackageIndexToEdit, (packages = [], index) =>
    propOr('', 'code', packages[index])
  )

const getSelectedSale = createSelector(
  getEditableTrip,
  getSelectedPackageCode(),
  selectedSailBySailPackageCode,
  (trip, code, sales) => {
    const sailRefId = path(['sailRefs', [0], 'sailRefId'], trip)
    return sailRefId ? { sailRefId } : sales(code) || {}
  }
)

const getSelectedSaleWhileEdit = createSelector(
  getEditableTrip,
  getSelectedPackageCode(),
  selectedSailBySailPackageCodeWhileEdit,
  (trip, code, sales) => {
    const sailRefId = path(['sailRefs', [0], 'sailRefId'], trip)
    return sailRefId ? { sailRefId } : sales(code) || {}
  }
)

const getSelectedSailRefId = () => createSelector(getSelectedSale, prop('sailRefId'))
export const getSelectedSailRefIdWhileEdit = () => createSelector(getSelectedSaleWhileEdit, prop('sailRefId'))

const getGroupedAvailableInvClasses = ((getSelectedSailRefId, getAvailableInvClasses) => (state) => {
  const code = getSelectedPackageCode()(state)
  const sailRefId = or(selectedSails(state)[code], '')

  const availableInvClasses = getAvailableInvClasses(state, { code, sailRefId })
  return inventoryClasses(availableInvClasses)
})(getSelectedSailRefId(), availableInventoriesClassesBySailPackageCodeAndSailRefId())

export const selectors = {
  getEditReservationMode,
  getEditReservation,
  getToken,
  getChallengeID,
  getRouteTerms,
  getRouteNoRefundIfCancelled,
  getRouteCoefficientsMap,
  getReservationSelector,
  getCurrentReservationId,
  getPayedReservation,
  tripsFromReservation,
  selectedSails,
  selectedSailsWhileEdit,
  selectedSailsDefault,
  getCancelledSailRefsList,
  sailInventories,
  getPrices,
  getVehiclePrices,
  getInventoryState,
  getTicketsIsFetching,
  selectedSailBySailPackageCode,
  selectScheduleState,
  getSailsAvailableDates,
  getCategoriesListWithPrices,
  getSortedVehicleCategoriesListWithPrices,
  areBicyclesAvailable,
  areAddonBicyclesAvailable,
  areAddonsAvailable,
  getAddonCategoriesListWithPrices,
  getRouteAddonsParameters,
  getTrailerCategoriesListWithPrices,
  areTrailersAvailable,
  getNextRoutes,
  getSelectedSailPackages,
  getAllSailPackages,
  getAllSailPackagesList,
  getFirstSelectedSailPackage,
  getSailDates,
  getSailDate,
  getPaymentError,
  getEditableTrip,
  areVehiclesAvailable,
  areVehiclesActive,
  areTrailersActive,
  ticketsIncDecSelectorParameters,
  getCurrentReservation,
  getOriginalReservation,
  getInitialVehiclesFormValues,
  vehiclesForConfirm,
  getFirstVehicleFromReservation,
  getFirstTrailerFromReservation,
  getFirstVehicleItem,
  getFirstTrailerItem,
  getSelectedPackageCode,
  getSelectedSailRefId,
  getLocalTravellers,
  findIdNums,
  getReservationIdFromUrl,
  getCustomerRole,
  paymentMethodsSelector,
  availableInventoriesClassesBySailPackageCodeAndSailRefId,
  vesselFeaturesBySailPackageCodeAndSailRefId,
  getSelectedSailPackageCodeFromProps,
  sailInventoriesBySailPackageCode,
  getSelectedSailRefIdList,
  getSelectedSailCodeByRefId,
  getAvailableReplacementLegs,
  getGroupedAvailableInvClasses,
  userDetailsSelector,
  getCompaniesSelector,
  getTotalPrice,
  getReservationFetching,
  getCurrency,
  getLocale,
  getRefundReservation,
  isLoggedInSelector,
  getItems,
  getResidentPriceCategoryTitles,
  areTherePassengersInReservation,
  customerSelector,
}
