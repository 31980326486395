import React, { Fragment } from 'react'
import Cleave from 'cleave.js/react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { UIBottomSlider } from 'bora-material-ui'
import PropTypes from 'prop-types'
import messages from '../../../consts/messages'
import { connect } from 'react-redux'
import { compose, any, isEmpty, values } from 'ramda'
import { actions } from '../provider'

const anyValueIsEmpty = compose(any(isEmpty), values)

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 300px;
  font-family: Roboto, monospace;

  ${breakpoint('xs')`
    padding-bottom: 30px;
    padding-top: 30px;
  `}
`

const BottomRow = styled.div`
  padding: 5px 0;
  display: flex;
  justify-content: space-between;
`

const commonStyles = {
  width: '100%',
  fontSize: '18px',
  textTransform: 'capitalize',
  boxSizing: 'border-box',
  margin: '5px 0',
  padding: '1px 14px 0px 10px',
  height: '33px',
}

const WideInput = styled.input(commonStyles)
const WideCleave = styled(Cleave)(commonStyles)
const NarrowCleave = styled(Cleave)`
  font-size: 18px;
  width: 45%;
  padding: 1px 14px 0px 10px;
  box-sizing: border-box;
  height: 33px;
`

export class CreditCardForm extends React.Component {
  static contextTypes = {
    intl: PropTypes.object,
  }

  state = {
    name: '',
    cardNumber: '',
    expireDate: '',
    cvv: '',
    cardType: '',
  }

  shouldDisableNext = () => anyValueIsEmpty(this.state)

  onProceed = () => {
    const { sendCreditCardPayment } = this.props
    const { name, cardNumber, expireDate, cvv } = this.state

    if (anyValueIsEmpty({ name, cardNumber, expireDate, cvv })) {
      return
    }

    sendCreditCardPayment({ name, cardNumber, expireDate, cvv })
  }

  onChange = (event) => {
    const { name, value } = event.target

    this.setState((state) => ({
      ...state,
      [name]: value,
    }))
  }

  onCreditCardTypeChanged = (type) => {
    this.setState({ cardType: type })
  }

  render() {
    const { intl } = this.context
    const { showModal } = this.props

    const isNextAvailable = !this.shouldDisableNext()
    return (
      <Fragment>
        <FormWrapper>
          <WideInput
            placeholder={intl.formatMessage(messages.creditCardPopUpPlaceholderName)}
            name="name"
            onChange={this.onChange}
          />
          <WideCleave
            placeholder={intl.formatMessage(messages.creditCardPopUpPlaceholderCardNumber)}
            options={{ creditCard: true, onCreditCardTypeChanged: this.onCreditCardTypeChanged }}
            name="cardNumber"
            onChange={this.onChange}
          />
          <BottomRow>
            <NarrowCleave
              placeholder="MM/YY"
              options={{ date: true, datePattern: ['m', 'y'] }}
              onChange={this.onChange}
              name="expireDate"
            />
            <NarrowCleave
              placeholder={intl.formatMessage(messages.creditCardPopUpPlaceholderCvv)}
              options={{ blocks: [4], uppercase: true }}
              onChange={this.onChange}
              name="cvv"
            />
          </BottomRow>
        </FormWrapper>
        <UIBottomSlider
          isNextAvailable={isNextAvailable}
          nextAction={this.onProceed}
          prevAction={() => showModal('')}
          prevTitle={intl.formatMessage(messages.cancel)}
          nextTitle={intl.formatMessage(messages.proceedPayment)}
          editMode
        />
      </Fragment>
    )
  }
}

export default connect(null, actions)(CreditCardForm)
