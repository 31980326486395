import { VEHICLE_TYPE_VEHICLE } from '../../../../consts/reservation'
import { selectors } from '../../provider'
import { getVehicleInputType, setVehicleInputType } from '../../../../services/user-selections'
import createFormContainer from './createFormContainer'

const vehicle = {
  iconType: 'iconCar',
  vehicleType: VEHICLE_TYPE_VEHICLE,
  areVehiclesActive: selectors.areVehiclesActive,
  getInitialFormValues: selectors.getInitialVehiclesFormValues,
  getInputType: getVehicleInputType,
  setInputType: setVehicleInputType,
}

export default createFormContainer(vehicle)
