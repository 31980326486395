import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Loadable from 'react-loadable'
import { equals, has, pathOr, toString, pipe, find } from 'ramda'
import { connect } from 'react-redux'

import { UILayout, UIIcon } from 'bora-material-ui'

const LoadableTicket = Loadable({
  loader: () => import('./Ticket.js'),
  loading: () => null,
})

const hasRefundOfferId = has('refundOfferId')

const styles = {
  tripThere: {
    ticketContainer: {
      borderBottom: '0px',
    },
  },
  xs: {
    bgColorBlack: { bgColor: 'black' },
  },
}

const isCancelledString = equals('CANCELLED')

const isTripCancelled = (trip = {}) => {
  const { status = '' } = trip
  if (isCancelledString(status)) return true
  const sailStatus = pathOr('', ['sailRefs', 0, 'status'])(trip)
  return isCancelledString(sailStatus)
}

class PrintedTicket extends React.PureComponent {
  render() {
    const { trips, sailErrors, reservation, isCancelled, outOfBookingBoundary } = this.props

    const [tripThere, tripBack] = trips
    const [sailThereRefund, sailBackRefund] = sailErrors
    const error = sailThereRefund || sailBackRefund

    const firstTicketCancelled = isCancelled || isTripCancelled(tripThere) || hasRefundOfferId(tripThere || {})
    const secondTicketCancelled = isCancelled || isTripCancelled(tripBack) || hasRefundOfferId(tripBack || {})

    const codeThere = pathOr('', ['sailPackages', 0, 'code'])(reservation)
    const codeBack = pathOr('', ['sailPackages', 1, 'code'])(reservation)
    const idThere = toString(pathOr('', ['sailPackages', 0, 'sailRefs', 0, 'sailRefId'])(reservation))
    const idBack = toString(pathOr('', ['sailPackages', 1, 'sailRefs', 0, 'sailRefId'])(reservation))

    const vesselFeaturesThere = pipe(
      pathOr([], [codeThere]),
      find((trip) => equals(pathOr(null, ['sailRefId'])(trip), idThere)),
      pathOr({}, ['inventories', 'vesselFeatures'])
    )(this.props.inventories)

    const vesselFeaturesBack = pipe(
      pathOr([], [codeBack]),
      find((trip) => equals(pathOr(null, ['sailRefId'])(trip), idBack)),
      pathOr({}, ['inventories', 'vesselFeatures'])
    )(this.props.inventories)

    return (
      <Fragment>
        <UILayout position="relative" justify="center">
          <LoadableTicket
            error={sailThereRefund}
            trip={tripThere}
            display-if={tripThere}
            reservation={reservation}
            style={tripBack && !sailThereRefund && styles.tripThere}
            isCancelled={firstTicketCancelled}
            outOfBookingBoundary={outOfBookingBoundary}
            vesselFeatures={vesselFeaturesThere}
          />

          <UILayout center bottom="-15px" position="absolute" display-if={tripBack}>
            <UILayout height="1px" row bgColor="transparent" sml={styles.xs.bgColorBlack}>
              {' '}
            </UILayout>
            <UILayout zIndex={error ? 0 : 2} bgColor={error ? 'transparent' : 'white'} width="85px" padding="0px 8px">
              <UIIcon type="iconReturnRoute" fill="black" width="50px" height="30px" />
            </UILayout>
            <UILayout height="1px" row bgColor="transparent" sml={styles.xs.bgColorBlack}>
              {' '}
            </UILayout>
          </UILayout>
        </UILayout>

        <LoadableTicket
          error={sailBackRefund}
          hideOwner
          trip={tripBack}
          display-if={tripBack}
          reservation={reservation}
          isCancelled={secondTicketCancelled}
          vesselFeatures={vesselFeaturesBack}
        />
      </Fragment>
    )
  }
}

PrintedTicket.defaultProps = {
  sailErrors: [],
}

PrintedTicket.propTypes = {
  sailErrors: PropTypes.array,
  trips: PropTypes.array.isRequired,
  reservation: PropTypes.object.isRequired,
  outOfBookingBoundary: PropTypes.bool,
}

export default connect((state) => ({
  inventories: state.schedule.inventories,
}))(PrintedTicket)
