import React from 'react'
import { UILayout, UIIcon, UIText } from 'bora-material-ui'
import messages from '../../../../../consts/messages'

const styles = {
  checkBoxStyle: { margin: 'auto' },
  toggleCaption: { cursor: 'pointer' },
}

class VehicleAdditionParams extends React.PureComponent {
  state = {
    toggle: true,
  }

  toggleShow = () => this.setState((state) => ({ toggle: !state.toggle }))

  render() {
    const { toggle } = this.state
    return (
      <UILayout column>
        <UILayout display-if={!toggle} column>
          <UILayout onClick={this.toggleShow} style={styles.toggleCaption} width="auto">
            <UILayout width="auto" style={styles.arrow}>
              <UIIcon fill="#1fbcd2" height="24px" width="24px" type="iconChevron" />
            </UILayout>
            <UIText size="18px" color="#79909b" translate={messages.handicappedMoreOptions} />
          </UILayout>
        </UILayout>
        <UILayout display-if={toggle} column>
          <UILayout onClick={this.toggleShow} style={styles.toggleCaption} width="auto">
            <UILayout width="auto" style={styles.arrow}>
              <UIIcon fill="#1fbcd2" height="24px" width="24px" type="iconChevron" style={styles.chevronDown} />
            </UILayout>
            <UIText size="18px" color="#79909b" translate={messages.handicappedLessOptions} />
          </UILayout>
        </UILayout>
        <UILayout display-if={toggle} column>
          {this.props.children}
        </UILayout>
      </UILayout>
    )
  }
}

export default VehicleAdditionParams
