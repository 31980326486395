import React from 'react'
import { connect } from 'react-redux'
import { change, FieldArray, reduxForm, getFormSyncErrors } from 'redux-form'
import { bindActionCreators } from 'redux'
import PassengerEditForm from '../../../../Booking/forms/CustomerInfo/PassengerEditForm'
import { injectIntl } from 'react-intl'
import { length, compose } from 'ramda'
import { birthDatesOptions, birthYearsOptions } from '../../../../Booking/forms/CustomerInfo/Passengers-Date.util'
import { FormFields, getCitizenshipIndexByCode } from '../../../../Booking/forms/CustomerInfo/passengers-info.util'

const findGuestType = (seqN, items) => {
  let type = ''
  items.forEach((item) => {
    if (item.ownerSeqNs.indexOf(seqN) > -1) {
      type = item.priceCategoryTranslation
    }
  })
  return type
}

const getBirthday = (dateISO) => {
  if (!dateISO) {
    return null
  }

  const parsedDateArr = dateISO.split('-')
  const [parsedYear, parsedMonth, parsedDate] = parsedDateArr

  const selectedYear = birthYearsOptions.findIndex((option) => Number(option.title) === Number(parsedYear))
  const selectedMonth = parsedMonth - 1
  const selectedDate = birthDatesOptions.findIndex((option) => Number(option.title) === Number(parsedDate))

  return {
    date: selectedDate === -1 ? 0 : selectedDate,
    month: selectedMonth === -1 ? 0 : selectedMonth,
    year: selectedYear === -1 ? 0 : selectedYear,
  }
}

const mapInitialValues = ({ guests = [], items = [] }, seqN, citizenships) => {
  if (!seqN) {
    return []
  }
  const dataToEdit = []
  guests.forEach((guest) => {
    if (guest.seqN === seqN) {
      dataToEdit.push({
        firstName: guest.firstName,
        lastName: guest.lastName,
        citizenship: getCitizenshipIndexByCode(guest.citizenship)(citizenships),
        birthday: getBirthday(guest.birthday),
        gender: guest.gender || 'M',
        optionalInfo: guest.comments || '',
        personalIdentificationNumber: guest.personalIdentificationNumber,
        seqN: guest.seqN,
        type: findGuestType(guest.seqN, items),
      })
    }
  })
  return dataToEdit
}

const validate = ({ passengers = [] }) => {
  const errors = {
    passengers: [{}],
  }

  if (length(passengers) > 0) {
    const [passenger = {}] = passengers
    const { firstName, lastName, citizenship } = passenger

    if (!firstName || firstName === '') {
      errors.passengers[0].firstName = 'Required'
    }
    if (!lastName || lastName === '') {
      errors.passengers[0].lastName = 'Required'
    }
    if (citizenship === -1) {
      errors.passengers[0].citizenship = 'Citizenship Required'
    }
    if (errors.passengers) {
      return Object.keys(errors.passengers[0]).length === 0 ? {} : errors
    }
  }
  return {}
}

class Form extends React.Component {
  render() {
    const { fields, syncErrors } = this.props
    return (
      <div>
        {fields.map((passenger, index) => (
          <PassengerEditForm
            fields={fields}
            passenger={passenger}
            index={index}
            syncErrors={syncErrors}
            key={index}
            FormFields={FormFields}
          />
        ))}
      </div>
    )
  }
}

const ModalPassengerEditForm = (props) => (
  <FieldArray name="passengers" component={Form} syncErrors={props.formSyncErrors} />
)

const mapDispatchToProps = (dispatch) => bindActionCreators({ changeReduxFormField: change }, dispatch)

export default compose(
  injectIntl,
  connect((state) => {
    const reservation = state.reservation.current
    return {
      formSyncErrors: getFormSyncErrors('editPassenger')(state) || {},
      reservation: state.reservation.current || {},
      initialValues: {
        passengers: reservation
          ? mapInitialValues(reservation, state.passengerToEdit, (state.user && state.user.citizenships) || [])
          : [],
      },
    }
  }, mapDispatchToProps),
  reduxForm({
    form: 'editPassenger',
    validate,
    destroyOnUnmount: true,
    enableReinitialize: true,
  })
)(ModalPassengerEditForm)
