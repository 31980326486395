import React, { Fragment } from 'react'
import { UIBottomSlider } from 'bora-material-ui'
import PropTypes from 'prop-types'
import messages from '../../../consts/messages'
import { connect } from 'react-redux'
import { actions } from '../provider'
import { Panel } from '../../../components/Panel'
import { getCurrentReservation } from '../../../services/reservation/selectors'
import { confirm } from '../../../services/reservation/actions'
import { goToPage } from '../../../actions'
import { getEditableTrip } from '../../../services/user-selections'
import { change } from 'redux-form'
import { createToggleHandler } from '../../Booking/forms/TicketsForm/TicketsForm'
import { selectors } from '../../Booking/provider'
import { getStyle } from '../../../utils/liinilaevadThemeUtils'

const DriverForReserveOnly = ({ showModal, changeReduxFormField, areVehiclesActive }, { intl }) => {
  const handleToggleVehicle = createToggleHandler('vehicle', areVehiclesActive, changeReduxFormField)

  const handlePrevClick = () => {
    showModal('')
  }

  const handleNextClick = () => {
    if (!areVehiclesActive) {
      handleToggleVehicle()
    }
    showModal('')
  }

  return (
    <Fragment>
      <Panel
        style={getStyle({
          color: '#0000A0',
          fontWeight: 700,
          maxWidth: 500,
          padding: '24px 24px 0 24px',
          fontSize: 18,
        })}
      >
        {intl.formatMessage(messages.driverForReserveOnlyConfirm)}
      </Panel>
      <UIBottomSlider
        isPopup={true}
        isNextAvailable={true}
        nextAction={handleNextClick}
        prevAction={handlePrevClick}
        prevTitle={intl.formatMessage(messages.cancel)}
        nextTitle={intl.formatMessage(messages.ok)}
        editMode
      />
    </Fragment>
  )
}

DriverForReserveOnly.contextTypes = {
  intl: PropTypes.object,
}

DriverForReserveOnly.propTypes = {
  currentReservation: PropTypes.object.isRequired,
  editableTrip: PropTypes.any.isRequired,
  confirm: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    currentReservation: getCurrentReservation(state),
    editableTrip: getEditableTrip(state),
    areVehiclesActive: selectors.areVehiclesActive(state),
  }),
  { ...actions, confirm, goToPage, changeReduxFormField: change }
)(DriverForReserveOnly)
