import { createAction } from 'redux-actions'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import {
  cancelInvitationUserToCompany,
  fetchCompanyMembers as fetchCompanyMembersService,
  fetchCompanyMemberReservations as fetchCompanyMemberReservationsService,
  resendInvitationUserToCompany as resendInvitationUserToCompanyService,
  fetchMembersByAllManagersCompanies,
  fetchReservationsByAllManagersCompanies,
  fetchReservationsByCustomerInAllManagersCompanies,
  fetchReservationsByCompany,
} from '../../../services/user/api/company'
import { selections } from '../../../services'
import { mergeInvitationsAndMembers } from './companyMembersHelpers'
import { getManagersSelectedCompany, userDetailsSelector } from '../../../services/user/selectors'
import { goToPage } from '../../../actions'
import * as userActions from '../../../services/user/actions'
import * as activateModuleActions from '../ActivateCompanyMember/activateCompanyMemberSaga'
import * as deactivateModuleActions from '../DeactivateCompanyMember/deactivateCompanyMemberSaga'
import * as removeModuleActions from '../RemoveCompanyMember/RemoveCompanyMemberSaga'

const ALL = 'ALL'

const activateCompanyMember = createAction('COMPANY_MEMBERS_CARD:ACTIVATE')
const deactivateCompanyMember = createAction('COMPANY_MEMBERS_CARD:DEACTIVATE')
const removeCompanyMember = createAction('COMPANY_MEMBERS_CARD:REMOVE')
const editCompanyMember = createAction('COMPANY_MEMBERS_CARD:EDIT')
const viewCompanyMemberTickets = createAction('COMPANY_MEMBERS_CARD:VIEW_TICKETS')
const fetchCompanyMembers = createAction('COMPANY_MEMBERS:FETCH_COMPANY_MEMBERS')
const inviteCompanyMember = createAction('COMPANY_MEMBERS_CARD:INVITE')
const resendInviteCompanyMember = createAction('COMPANY_MEMBERS_CARD:RESEND_INVITE')
const resendInviteCompanyMemberSuccess = createAction('COMPANY_MEMBERS_CARD:RESEND_INVITE_SUCCESS')
const resendInviteCompanyMemberFail = createAction('COMPANY_MEMBERS_CARD:RESEND_INVITE_FAIL')
const cancelInviteCompanyMember = createAction('COMPANY_MEMBERS_CARD:CANCEL_INVITE')
const cancelInviteCompanyMemberSuccess = createAction('COMPANY_MEMBERS_CARD:CANCEL_INVITE_SUCCESS')
const cancelInviteCompanyMemberFail = createAction('COMPANY_MEMBERS_CARD:CANCEL_INVITE_FAIL')
const selectCompanyMember = createAction('COMPANY_MEMBERS:SELECT_COMPANY_MEMBER')
const clearSelectedCompanyMember = createAction('COMPANY_MEMBERS:CLEAR_SELECTED_COMPANY_MEMBER')
const updateCompanyMembers = createAction('COMPANY_MEMBERS:UPDATE_COMPANY_MEMBERS')
const fetchCompanyMemberReservations = createAction('COMPANY_MEMBERS:FETCH_RESERVATIONS')
const fetchCompanyMemberReservationsSuccess = createAction('COMPANY_MEMBERS:FETCH_RESERVATIONS_SUCCESS')
const fetchCompanyMemberReservationsFail = createAction('COMPANY_MEMBERS:FETCH_RESERVATIONS_FAIL')
const clearCompanyMemberReservations = createAction('COMPANY_MEMBERS:CLEAR_RESERVATIONS')

function* fetchCompanyMembersHandler(action) {
  const selectedCompany = yield select(getManagersSelectedCompany)
  const { payload: companyId = selectedCompany.companyId } = action

  try {
    const manager = yield select(userDetailsSelector)
    let response
    let companyName
    let companyMembers

    if (companyId === ALL) {
      response = yield call(fetchMembersByAllManagersCompanies, companyId)
      companyName = ALL
      companyMembers = response
    } else {
      response = yield call(fetchCompanyMembersService, companyId)
      companyName = response.company.legalName
      const { invitations, members } = response.company
      companyMembers = mergeInvitationsAndMembers(invitations, members, manager.customerId)
    }

    yield put(
      updateCompanyMembers({
        companyId,
        companyName,
        companyMembers,
      })
    )
  } catch (error) {
    console.error('Cannot fetch company members', error)
  }
}

function* fetchCompanyMemberReservationsHandler(action) {
  const { companyId, customerId } = action.payload

  try {
    let response

    if (companyId === ALL && customerId === ALL) {
      response = yield call(fetchReservationsByAllManagersCompanies)
    } else if (companyId === ALL && customerId !== ALL) {
      response = yield call(fetchReservationsByCustomerInAllManagersCompanies, customerId)
    } else if (companyId !== ALL && customerId === ALL) {
      response = yield call(fetchReservationsByCompany, companyId)
    } else if (companyId !== ALL && customerId !== ALL) {
      response = yield call(fetchCompanyMemberReservationsService, { companyId, customerId })
    }

    if (!response) {
      throw new Error('No company members reservations in response')
    }
    const reservations = response.content

    yield put(fetchCompanyMemberReservationsSuccess(reservations))
  } catch (error) {
    yield put(fetchCompanyMemberReservationsFail(error))
  }
}

function* cancelInviteCompanyMemberHandler({ payload: { companyId, token } }) {
  try {
    yield call(cancelInvitationUserToCompany, { companyId, token })
    yield put(cancelInviteCompanyMemberSuccess())
  } catch (error) {
    yield put(cancelInviteCompanyMemberFail(error))
  }
}

function* resendInviteCompanyMemberHandler(action) {
  try {
    const { token } = action.payload
    const selectedCompany = yield select(getManagersSelectedCompany)
    const { companyId } = selectedCompany

    yield call(resendInvitationUserToCompanyService, { companyId, token })
    yield put(resendInviteCompanyMemberSuccess())
  } catch (error) {
    yield put(resendInviteCompanyMemberFail(error))
  }
}

function* activateCompanyMemberHandler() {
  yield put(goToPage('/profile/users/activate'))
}

function* deactivateCompanyMemberHandler() {
  yield put(goToPage('/profile/users/deactivate'))
}

function* removeCompanyMemberHandler() {
  yield put(goToPage('/profile/users/remove'))
}

function* editCompanyMemberHandler() {
  yield put(goToPage('/profile/settings'))
}

function* inviteCompanyMemberHandler() {
  yield put(goToPage('/profile/users/invite'))
}

function* viewCompanyMemberTicketsHandler(action) {
  yield put(selections.setCommonUser(action.payload))
  yield put(selectCompanyMember(action.payload))
  yield put(goToPage('/profile/users/tickets'))
}

function* watchActivateCompanyMember() {
  yield takeEvery(activateCompanyMember, activateCompanyMemberHandler)
}

function* watchActivateCompanyMemberSuccess() {
  yield takeEvery(activateModuleActions.activateCompanyMemberSuccess, fetchCompanyMembersHandler)
}

function* watchDeactivateCompanyMember() {
  yield takeEvery(deactivateCompanyMember, deactivateCompanyMemberHandler)
}

function* watchDeactivateCompanyMemberSuccess() {
  yield takeEvery(deactivateModuleActions.deactivateCompanyMemberSuccess, fetchCompanyMembersHandler)
}

function* watchRemoveCompanyMember() {
  yield takeEvery(removeCompanyMember, removeCompanyMemberHandler)
}

function* watchRemoveCompanyMemberSuccess() {
  yield takeEvery(removeModuleActions.removeCompanyMemberSuccess, fetchCompanyMembersHandler)
}

function* watchInviteCompanyMember() {
  yield takeEvery(inviteCompanyMember, inviteCompanyMemberHandler)
}

function* watchInviteCompanyMemberSuccess() {
  yield takeEvery(userActions.inviteUserToCompanySuccess, fetchCompanyMembersHandler)
}

function* watchResendInviteCompanyMember() {
  yield takeEvery(resendInviteCompanyMember, resendInviteCompanyMemberHandler)
}

function* watchCancelInviteCompanyMember() {
  yield takeEvery(cancelInviteCompanyMember, cancelInviteCompanyMemberHandler)
}

function* watchCancelInviteCompanyMemberSuccess() {
  yield takeEvery(cancelInviteCompanyMemberSuccess, fetchCompanyMembersHandler)
}

function* watchEditCompanyMember() {
  yield takeEvery(editCompanyMember, editCompanyMemberHandler)
}

function* watchFetchCompanyMembers() {
  yield takeEvery(fetchCompanyMembers, fetchCompanyMembersHandler)
}

function* watchFetchCompanyMemberReservations() {
  yield takeEvery(fetchCompanyMemberReservations, fetchCompanyMemberReservationsHandler)
}

function* watchViewCompanyMemberTickets() {
  yield takeEvery(viewCompanyMemberTickets, viewCompanyMemberTicketsHandler)
}

export {
  activateCompanyMember,
  deactivateCompanyMember,
  editCompanyMember,
  fetchCompanyMembers,
  inviteCompanyMember,
  resendInviteCompanyMember,
  cancelInviteCompanyMember,
  removeCompanyMember,
  selectCompanyMember,
  clearSelectedCompanyMember,
  updateCompanyMembers,
  viewCompanyMemberTickets,
  fetchCompanyMemberReservations,
  fetchCompanyMemberReservationsSuccess,
  fetchCompanyMemberReservationsFail,
  clearCompanyMemberReservations,
  watchActivateCompanyMember,
  watchActivateCompanyMemberSuccess,
  watchDeactivateCompanyMember,
  watchDeactivateCompanyMemberSuccess,
  watchRemoveCompanyMember,
  watchRemoveCompanyMemberSuccess,
  watchInviteCompanyMember,
  watchInviteCompanyMemberSuccess,
  watchResendInviteCompanyMember,
  watchCancelInviteCompanyMember,
  watchCancelInviteCompanyMemberSuccess,
  watchEditCompanyMember,
  watchFetchCompanyMembers,
  watchFetchCompanyMemberReservations,
  watchViewCompanyMemberTickets,
}
