import { connect } from 'react-redux'
import { pathOr, propOr, or, pickBy } from 'ramda'

import { selectors } from '../../../../../src/modules/Booking/provider'

import { setFirstSailDate, setSecondSailDate } from '../../../../services/user-selections'
import { setEditedReservationData } from '../../../../services/reservation/actions/reservation.js'

import { showModal } from '../../../../actions'
import { modifyReservation, changeSailPackageByLeg } from '../../../Ticket/EditTicket/editTicketSagas'

import ModalEditDateTime from './ModalEditDateTime'
import { selectedSailBySailPackageCodeWhileEdit, selectIsRequestingDates } from '../../../../services/schedule/reducers'
import { getInventoryClassItemAmounts, inventoryCannotFitAmounts } from '../../../../services/inventory/utils'

function mapStateToProps(state) {
  const { selectedSailPackageIndexToEdit } = state
  const packageCode = pathOr('', ['userSelections', 'sailPackages', [selectedSailPackageIndexToEdit], 'code'], state)
  const selectedSail = or(selectedSailBySailPackageCodeWhileEdit(state)(packageCode), { sailPackage: packageCode })

  const reservation = selectors.getEditReservation(state)
  const { reservationId, items, token } = reservation
  const {
    seqN,
    code: sailPackage,
    sailRefs = [],
  } = pathOr({}, ['sailPackages', [selectedSailPackageIndexToEdit]])(reservation)
  const sailRefIds = sailRefs.map((sailRef) => sailRef.sailRefId)
  const departureDates = sailRefs.map((sailRef) => sailRef.departureDate)
  const oldSailPackage = { sailPackage, seqN, sailRefIds, departureDates }
  const isRequestingDates = selectIsRequestingDates(state)

  const amounts = getInventoryClassItemAmounts(items)
  const amountsForIndex = pickBy((val, key) => key.startsWith(`${selectedSailPackageIndexToEdit}:`), amounts)

  const getAvailableInvClasses = selectors.availableInventoriesClassesBySailPackageCodeAndSailRefId()
  const inventory = getAvailableInvClasses(state, {
    code: selectedSail.sailPackage,
    sailRefId: selectedSail.sailRefId,
  })

  const capacityError = inventoryCannotFitAmounts(inventory, amountsForIndex, selectedSailPackageIndexToEdit)

  return {
    reservationId,
    reservationToken: token,
    selectedSailPackageIndexToEdit,
    selectedSail,
    oldSailPackage,
    sailDates: selectors.getSailDates(state),
    availableReplacementLegs: selectors.getAvailableReplacementLegs(state),
    modalError: pathOr('', ['editTicket', 'modalError'])(state),
    isRequestingDates,
    capacityError,
    inventoryTaken: amountsForIndex,
    isFetching: state.fetching,
  }
}

const mapDispatchToProps = {
  changeSailPackageByLeg,
  modifyReservation,
  setFirstSailDate,
  setSecondSailDate,
  showModal,
  setEditedReservationData,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const {
    selectedSailPackageIndexToEdit,
    sailDates,
    oldSailPackage,
    reservationId,
    reservationToken,
    availableReplacementLegs,
    ...props
  } = stateProps
  const [initialDepartureDate] = oldSailPackage.departureDates

  const { selectedSail } = stateProps
  const { sailPackage: code, sailRefId } = selectedSail
  const selectedReplacementLeg = availableReplacementLegs.findIndex((sail) => sail.code === code)
  const sailPackage = { code }
  const isNextAvailable = Boolean(sailRefId)

  const selectDepartureDate = (date) => {
    const { setSecondSailDate, setFirstSailDate } = dispatchProps
    const selectDate = selectedSailPackageIndexToEdit ? setSecondSailDate : setFirstSailDate
    selectDate(date)
  }

  return {
    ...props,
    ...ownProps,
    sailPackage,
    isNextAvailable,
    selectedReplacementLeg,
    availableReplacementLegs,
    sailDate: sailDates[selectedSailPackageIndexToEdit],

    selectLeg(leg) {
      dispatchProps.changeSailPackageByLeg({ leg, selectedSailPackageIndexToEdit })
    },

    selectDepartureDate,

    applyChanges() {
      const { date, time } = propOr({}, 'departure')(selectedSail)
      const { routeLeg, sailRefId } = selectedSail

      const updateSailPackagesModification = {
        __type: 'updateSailPackages',
        ...oldSailPackage,
        updateSailPackage: {
          departureDate: date,
          departureTime: time,
          routeLeg,
          sailRefId,
        },
      }

      dispatchProps.modifyReservation({
        reservationToken,
        modifications: [updateSailPackagesModification],
      })
    },

    closeModal() {
      selectDepartureDate(initialDepartureDate)
      dispatchProps.showModal('')
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ModalEditDateTime)
