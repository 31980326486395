import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'

import { setupTranslator } from './Translator'
import { changeLocale } from '../../services/user-selections/actions'
import { defaultLocale } from '../../utils/locale'
import { pathOr } from 'ramda'

const LocaleProvider = (props) => {
  const { supported = [], locale = 'defaultMessages' } = props
  const currentLocale =
    supported.indexOf(locale) !== -1
      ? locale
      : supported.indexOf(locale.split('-')[0]) !== -1
      ? locale.split('-')[0]
      : defaultLocale
  const supportedLocalesData = [].concat(
    ...[en].concat(supported.map((localeCode) => require(`react-intl/locale-data/${localeCode}.js`)))
  )
  addLocaleData(supportedLocalesData)
  const messages = require(`../../locales/lang/${currentLocale}.json`)

  const setLocaleAccordingToFormat = (formatMask, currentLocale) => {
    if (!formatMask) {
      return currentLocale
    }
    const formatParts = formatMask.trim().split(' ')
    if (!formatParts[0].includes('#')) {
      // for currency sign in the beginning
      return 'en'
    } else if (currentLocale === 'en') {
      // for currency sign in the end
      return 'et'
    }
    return currentLocale
  }

  return (
    <IntlProvider
      defaultLocale="et"
      key={currentLocale}
      messages={messages}
      {...{
        ...props,
        locale: setLocaleAccordingToFormat(
          pathOr('', ['brandProps', 'backendConfig', 'formats', 'money'])(window),
          props.locale
        ),
      }}
    >
      <TranslationProvider>{props.children}</TranslationProvider>
    </IntlProvider>
  )
}

const TranslationProvider = (props, context) => {
  setupTranslator(context)
  return props.children
}

TranslationProvider.contextTypes = {
  intl: PropTypes.any,
}

export default connect((state) => ({ locale: state.locale }), { changeLocale })(LocaleProvider)
