import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { reduxForm, change, getFormValues } from 'redux-form'
import { propOr, mergeAll, compose, pathOr } from 'ramda'

import { getSelectedSailRefIdWhileEdit, selectors } from '../../provider'
import { getReservation } from '../../../Ticket/EditTicket/editTicketSelectors'
import { getSelectedRoute } from '../../../../services/user-selections'
import { getAlternativeResidentsPriceCategory } from '../../../../services/routes/routes/reducers'
import { getBookingConditionsForRoute } from '../../../../services/reservation/selectors'

export const getTheSailRefIdFromReservationByIndex = (index, defaultValue) =>
  pathOr(defaultValue, ['sailPackages', index, 'sailRefs', 0, 'sailRefId'])

const getPersonalId = (item, reservationGuests) => {
  const personalIDs = []
  item.ownerSeqNs.forEach((ownerSeqN) => {
    const guest = reservationGuests.find((guest) => guest.seqN === ownerSeqN)
    if (guest) {
      if (item.personalIdentificationNumbers) {
        personalIDs.push(...item.personalIdentificationNumbers, guest.personalIdentificationNumber)
      }
      personalIDs.push(guest.personalIdentificationNumber)
    }
  })
  return personalIDs
}
const isSoldOut = (inv) =>
  inv.every(({ amount }) => {
    return amount < 1
  }) &&
  inv.every(({ reserves }) => {
    return reserves && reserves.every(({ amount }) => amount < 1)
  })

const findCar = propOr([], 'Car')
const findBike = propOr([], 'Bike')
const findAddonBike = propOr([], 'BICYCLE')
const isCarSoldOut = (inv) => isSoldOut(findCar(inv))

const mapStateToProps = (state, { sailIndexForEdit = 0 }) => {
  const editMode = state.modal === 'tickets'

  let initialEditModeValues = {}

  if (editMode) {
    const editedReservation = getReservation(state)
    let reservationItems = propOr([], 'items')(editedReservation)
    const reservationGuests = propOr([], 'guests')(editedReservation)
    const vehiclesOnly = reservationItems.filter((item) => item.type === 'VEHICLE' && item.inventoryClass !== 'BICYCLE')
    const sailRefIdFromSelectedSails = getSelectedSailRefIdWhileEdit()(state) || selectors.getSelectedSailRefId()(state)
    const sailRefId = getTheSailRefIdFromReservationByIndex(
      sailIndexForEdit,
      sailRefIdFromSelectedSails
    )(editedReservation)

    reservationItems = reservationItems.filter((item) => sailRefId in item.sailRefIdToPricePerSail)
    const items = reservationItems
      .filter(({ promotion }) => !promotion)
      .filter(({ internal }) => !internal)
      .map((item) => ({
        [item.priceCategory]: {
          code: item.priceCategory,
          priceCategory: item.priceCategory,
          price: item.pricePerUnit,
          personalIdentificationNumbers: getPersonalId(item, reservationGuests),
          // type: item.type,
          inventoryClass: item.inventoryClass,
          count: item.quantity,
          subType: item.inventoryClass,
          resident: item.resident,
          ownerSeqNs: item.ownerSeqNs,
          priceCategorySubType: item.priceCategorySubType,
          // title: "title"
        },
      }))

    const newItems = mergeAll(items)

    if (!vehiclesOnly.length) {
      initialEditModeValues = { ...newItems, vehicleIsActive: false }
    } else {
      initialEditModeValues = { ...newItems }
    }

    const vehicleIndex = reservationItems.findIndex((item) => item.priceCategorySubType === 'VEHICLE')
    if (vehicleIndex !== -1) {
      initialEditModeValues = { ...initialEditModeValues, vehicleIsActive: true }
    }

    const trailerIndex = reservationItems.findIndex((item) => item.priceCategorySubType === 'TRAILER')
    if (trailerIndex !== -1) {
      initialEditModeValues = { ...initialEditModeValues, trailerIsActive: true }
    }
  }

  // const initialValues = editMode ? initialEditModeValues : {}
  const initial = editMode ? { initialValues: initialEditModeValues } : {}
  const groupedAvailableInvClasses = selectors.getGroupedAvailableInvClasses(state)

  const selectedRoute = getSelectedRoute(state)
  const alternativeResidentPriceCategory = getAlternativeResidentsPriceCategory(
    selectedRoute,
    getBookingConditionsForRoute(state)
  )(state)

  const selectedSails = selectors.selectedSails(state)
  const selectedSailsWhileEdit = selectors.selectedSailsWhileEdit(state)
  const getVesselFeatures = selectors.vesselFeaturesBySailPackageCodeAndSailRefId()

  const sailHasChangedWhileEdit =
    Object.keys(selectedSailsWhileEdit).length > 0 &&
    Object.keys(selectedSailsWhileEdit).length === Object.keys(selectedSails).length
  const availableVesselFeatures = Object.keys(sailHasChangedWhileEdit ? selectedSailsWhileEdit : selectedSails).map(
    (key) => {
      return (
        {
          ...getVesselFeatures(state, {
            code: key,
            sailRefId: sailHasChangedWhileEdit ? selectedSailsWhileEdit[`${key}`] : selectedSails[`${key}`],
          }),
        }[0] || {}
      )
    }
  )

  const bicyclesAreNotInVesselFeature = availableVesselFeatures.some((vesselFeature) => !vesselFeature.hasBicycleSpots)
  const vehiclesAreNotInVesselFeature = availableVesselFeatures.some((vesselFeature) => !vesselFeature.hasCarDecks)
  const addonsAccumulated = selectors.getAddonCategoriesListWithPrices(state).map((addonPriceCategory) => {
    if (groupedAvailableInvClasses[addonPriceCategory.code]) {
      return {
        ...addonPriceCategory,
        inventory: { ...groupedAvailableInvClasses[addonPriceCategory.code][0] },
      }
    }
    return {
      ...addonPriceCategory,
      inventory: {
        amount: 0,
        inventoryClass: addonPriceCategory.code,
        soldOut: true,
        total: 0,
      },
    }
  })

  return {
    areVehiclesAvailable:
      selectors.areVehiclesAvailable(state) &&
      (editMode || !isCarSoldOut(groupedAvailableInvClasses)) &&
      (editMode || !vehiclesAreNotInVesselFeature),
    areBicyclesAvailable:
      selectors.areBicyclesAvailable(state) &&
      (editMode || !isSoldOut(findBike(groupedAvailableInvClasses))) &&
      (editMode || !bicyclesAreNotInVesselFeature),
    areAddonBicyclesAvailable:
      selectors.areAddonBicyclesAvailable(state) && (editMode || !isSoldOut(findAddonBike(groupedAvailableInvClasses))),
    areVehiclesActive: selectors.areVehiclesActive(state),
    areTrailersAvailable:
      selectors.areTrailersAvailable(state) &&
      (editMode || !isCarSoldOut(groupedAvailableInvClasses)) &&
      (editMode || !vehiclesAreNotInVesselFeature),
    areTrailersActive: selectors.areTrailersActive(state),
    vehiclesFormValues: getFormValues('ticketsForm')(state) || {},
    alternativeResidentPriceCategory,
    addonsAccumulated,
    ...initial,
  }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ changeReduxFormField: change }, dispatch)

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'ticketsForm',
    destroyOnUnmount: true,
    enableReinitialize: false,
  })
)
