import React from 'react'
import PropTypes from 'prop-types'

import { UIText, UILayout, UIIcon, UIButton, UIFormattedNumber } from 'bora-material-ui'

import UIElements from '../../../../consts/UIElements'
import messages from '../../../../consts/messages'

const ReservationPayment = () => (
  <UILayout column style={{ borderTop: '2px solid #e7e7e7' }} padding="25px 0 30px 0">
    <UIText margin="20px 0 10px 0" size="14px" color="#000" align="left" translate={messages.payment} />
    <UILayout center margin="10px 0px 0px 0px">
      <UIIcon type="mastercard" />
      <UIIcon type="visa" margin="0px 0px 0px 10px" />
    </UILayout>

    <UILayout margin="10px 0px 0px 0px" xs={{ column: true }}>
      <UIButton width="80%" xs={{ width: '100%' }} background={UIElements.BUTTONS_COLOR}>
        <UILayout column j-flex-center margin="0 27px" lineHeight="30px" height="100%" style={{ flexBasis: '0' }}>
          <UIText align="left" size="18px" opacity="0.5" translate={messages.payWithCards} />
          <UIText align="left" size="24px">
            <UILayout row center>
              <UIText translate={messages.total} />
              :&nbsp;
              <UIFormattedNumber value={1450} style="currency" currency="eur" />
              <UIIcon fill="white" type="selectTicketArrow" margin="0 0 0 20px" />
            </UILayout>
          </UIText>
        </UILayout>
      </UIButton>
      <UIButton width="20%" margin="0 0 0 10px" background="black" xs={{ width: '100%', margin: '10px 0px 0px 0px' }}>
        <UILayout column j-flex-center lineHeight="30px" height="100%" style={{ flexBasis: '0' }} xs={{ center: true }}>
          <UIIcon fill="white" type="applePay" margin="0 0 0 20px" xs={{ margin: '0' }} />
        </UILayout>
      </UIButton>
    </UILayout>
  </UILayout>
)

ReservationPayment.propTypes = {}

ReservationPayment.contextTypes = {
  intl: PropTypes.any,
}

ReservationPayment.defaultProps = {}

export default ReservationPayment
