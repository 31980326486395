import React from 'react'
import PropTypes from 'prop-types'

import TicketRow from '../TicketRow'

import { UIText, UILayout } from 'bora-material-ui'

import messages from '../../../../consts/messages'

const TicketsInfo = () => (
  <UILayout column>
    <UILayout style={{ borderTop: '2px solid #e7e7e7' }} padding="25px 0 30px 0" j-flex-space-between>
      <UILayout column width="auto" height="80px" j-flex-space-between>
        <UIText align="left" size="14px" color="#000" translate={messages.vessleName} />
        <UIText size="28px" color="#000" align="left">
          Mary Ann
        </UIText>
      </UILayout>
      <UILayout column width="auto" height="80px" j-flex-space-between>
        <UIText align="left" size="14px" color="#000" translate={messages.quantity} />
        <UILayout bgColor="#fff" width="48px" height="48px" borderRadius="2px" center j-flex-center>
          <UIText color="#000" size="28px">
            2
          </UIText>
        </UILayout>
      </UILayout>
      <UILayout column width="auto" height="80px" j-flex-space-between>
        <UIText align="left" size="14px" color="#000" translate={messages.total} />
        <UILayout bgColor="#09b1ff" width="100px" height="48px" borderRadius="2px" center j-flex-center>
          <UIText size="28px">4.45 €</UIText>
        </UILayout>
      </UILayout>
    </UILayout>
    <UILayout column style={{ borderTop: '2px solid #e7e7e7' }} padding="25px 0 30px 0" j-flex-space-between>
      <UILayout j-flex-space-between padding="0px 21px 20px 0">
        <UIText align="left" size="14px" color="#000" translate={messages.tickets} />
        <UIText align="left" size="14px" color="#000" translate={messages.type} />
        <UIText align="left" size="14px" color="#000" translate={messages.price} />
      </UILayout>
      <UILayout column>
        <TicketRow />
        <TicketRow />
      </UILayout>
    </UILayout>
  </UILayout>
)

TicketsInfo.propTypes = {}

TicketsInfo.contextTypes = {
  intl: PropTypes.any,
}

TicketsInfo.defaultProps = {}

export default TicketsInfo
