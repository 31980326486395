import React from 'react'
import PropTypes from 'prop-types'

import { UILayout } from 'bora-material-ui'

const xsStyles = { column: true, padding: '8px' }

const FormLayout = ({ children, additionalStyles, editMode }) => (
  <UILayout
    margin="10px 0px 0px 0px"
    padding={!editMode ? '28px 23px 10px 23px' : '8px'}
    bgColor="#e5e5e5"
    {...additionalStyles}
    xs={xsStyles}
  >
    {children}
  </UILayout>
)

FormLayout.propTypes = {
  children: PropTypes.any.isRequired,
  additionalStyles: PropTypes.any,
  editMode: PropTypes.bool,
}

FormLayout.defaultProps = {
  additionalStyles: '',
  editMode: false,
}

export default FormLayout
