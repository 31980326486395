import React from 'react'
import PropTypes from 'prop-types'
import { UIButton, UIFormattedNumber, UIIcon, UILayout, UIText } from 'bora-material-ui'
import { olderBrowser } from '../../../../../utils/browserUtils'
import { getStyle } from '../../../../../utils/liinilaevadThemeUtils'
import { getGavdosStyle } from '../../../../../utils/gavdosStyleUtils'

export const xsStyles = {
  typeWrapper: {
    row: true,
    center: true,
    position: 'relative',
    margin: '0',
    borderRadius: '0 10px 10px 0',
    boxShadow: '0 10px 10px 0 rgba(0,0,0,0.05), 0 0 10px 0 rgba(0,0,0,0.20)',
    padding: '10px',
  },
  title: { size: '16px' },
  price: { padding: '5px 10px 0 10px', size: '16px' },
  buttons: { width: 'auto' },
  iconClose: { width: 'auto', position: 'absolute', top: '-10px', right: '-5px' },
  removeButton: { width: '25px', height: '25px' },
}

export const SelectedTicketTypeRow = (
  { title = '', price = 0, currencyCode = 'eur', editMode, clearAmount, isLocalTraveller },
  { muiTheme = {} }
) => (
  <UILayout
    center
    j-flex-space-between
    bgColor={getStyle('#BBE7FF', 'white')}
    borderRadius={getStyle('0px', '5px')}
    border={getStyle('1px solid #3083FF')}
    margin="0 20px 0 0"
    minHeight="45px"
    padding="10px"
    boxShadow="0 24px 24px 0 rgba(0, 0, 0, 0.06), 0 0 24px 0 rgba(0, 0, 0, 0.22)"
    xs={xsStyles.typeWrapper}
    data-test="selected-ticket-type-row"
    className={editMode ? 'selected-ticket__row-edit-mode' : 'selected-ticket__row'}
    {...muiTheme.ids.SelectedTicketTypeRowWrapper}
  >
    <UILayout
      overflow="hidden"
      width={olderBrowser ? '85%' : '100%'}
      shrink="1"
      xs={{ column: false, row: true, width: '100%', ...muiTheme.ids.xsSelectedTicketTypeRow }}
      xsm={{ ...muiTheme.ids.xsSelectedTicketTypeRow }}
      {...muiTheme.ids.SelectedTicketTypeRow}
    >
      <UIText
        size={editMode ? '16px' : '18px'}
        color={getGavdosStyle('#4b7cd0', getStyle(muiTheme.palette.darkPrimaryColor, '#0eb1bc'))}
        font={getStyle(muiTheme.secondFontFamily, muiTheme.fontFamily)}
        align="left"
        whiteSpace="normal"
        overflow="hidden"
        ellipsis
        xs={xsStyles.title}
        {...muiTheme.ids.SelectedTicketTypeName}
      >
        {title}
      </UIText>
    </UILayout>
    <UILayout shrink="10" center j-flex-end xs={xsStyles.buttons}>
      <UIText
        size="18px"
        color={getGavdosStyle('#4b7cd0', getStyle(muiTheme.palette.darkPrimaryColor, '#0f939c'))}
        font={getStyle(muiTheme.secondFontFamily, muiTheme.fontFamily)}
        align="left"
        padding="0 10px"
        xs={xsStyles.price}
        {...muiTheme.ids.SelectedTicketTypeNumber}
      >
        <UIFormattedNumber
          display-if={!isLocalTraveller || Boolean(price)}
          style="currency"
          currency={currencyCode}
          value={price}
        />
      </UIText>

      <UILayout xs={xsStyles.iconClose}>
        <UIButton
          display-if={clearAmount}
          click={clearAmount}
          type="circle"
          background="#d0021b"
          width="30px"
          height="30px"
          xs={xsStyles.removeButton}
        >
          <UIIcon type="iconClose" />
        </UIButton>
      </UILayout>
    </UILayout>
  </UILayout>
)

SelectedTicketTypeRow.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  clearAmount: PropTypes.func.isRequired,
  currencyCode: PropTypes.string,
  editMode: PropTypes.bool,
  isLocalTraveller: PropTypes.bool,
}

SelectedTicketTypeRow.contextTypes = {
  muiTheme: PropTypes.any,
}

SelectedTicketTypeRow.defaultProps = {
  currencyCode: 'eur',
  editMode: false,
  isLocalTraveller: false,
}
