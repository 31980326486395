import React from 'react'

import { UILayout } from 'bora-material-ui'
import { TrailerForm } from '../../../Booking/forms/VehiclesForm'

const ModalEditTrailer = () => (
  <UILayout
    data-test="modal-edit-trailer-content"
    style={{ borderBottom: '1px solid #bfc3c7' }}
    padding="0 0 20px 0"
    width="430px"
  >
    <TrailerForm editMode />
  </UILayout>
)

export default ModalEditTrailer
