import { type, contains, is } from 'ramda'

let instance = null

class Translator {
  constructor() {
    if (!instance) {
      instance = this // eslint-disable-line
    }
    return instance
  }

  setup(translationSourceObj) {
    const { intl } = translationSourceObj
    if (intl) {
      this.translationFunc = intl.formatMessage
    }
  }

  translate(idOrObj, values) {
    const { isLaak = false, isRin = false } = values || {}
    let postFix = ''

    if (isLaak) postFix = '-laak'
    else if (isRin) postFix = '-rin'

    const availableTypes = ['Object', 'String']
    const paramType = type(idOrObj)
    const isValidType = contains(paramType, availableTypes)
    if (!this.translationFunc || !isValidType) {
      return null
    }
    const { id } = idOrObj
    const translationObj =
      is(Object, idOrObj) && id ? { ...idOrObj, id: `${id}${postFix}` } : { id: idOrObj.toString() }
    return this.translationFunc(translationObj, values)
  }
}

export const setupTranslator = (translationSourceObj) => {
  const translator = new Translator()
  translator.setup(translationSourceObj)
}

export const translate = (translationId, values) => {
  const translator = new Translator()
  return translator.translate(translationId, values)
}
